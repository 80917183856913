import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled.h3.attrs(({ isError }) => ({ isError }))`
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    font-family: var(--title-font);
    color: ${(props) => props.isError ? "var(--error-color)" : "var(--title-color)"};
`;



/**
 * The Settings Title
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SettingsTitle(props) {
    const { icon, title, isError } = props;


    // Do the Render
    return <Container isError={isError}>
        <Icon icon={icon} />
        {NLS.get(title)}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SettingsTitle.propTypes = {
    icon    : PropTypes.string.isRequired,
    title   : PropTypes.string.isRequired,
    isError : PropTypes.bool,
};

export default SettingsTitle;

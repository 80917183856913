import React                from "react";
import PropTypes            from "prop-types";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Health Type Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function HealthTypeFilter(props) {
    const { open, filters, onClose, onSubmit } = props;


    // The Initial Data
    const initialData = {
        alfabetaID   : "",
        alfabetaName : "",
        benvidaName  : "",
    };

    // The Filter State
    const {
        loading, data, handleChange, handleSubmit,
    } = useFilter("healthType", open, initialData, filters, onSubmit);


    // Do the Render
    return <FilterDialog
        open={open}
        title="HEALTH_TYPES_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="number"
            name="alfabetaID"
            label="GENERAL_CODE_ALFABETA"
            value={data.alfabetaID}
            onChange={handleChange}
        />
        <InputField
            name="alfabetaName"
            label="GENERAL_NAME_ALFABETA"
            value={data.alfabetaName}
            onChange={handleChange}
        />
        <InputField
            name="benvidaName"
            label="GENERAL_NAME_BENVIDA"
            value={data.benvidaName}
            onChange={handleChange}
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
HealthTypeFilter.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    filters  : PropTypes.object,
};

export default HealthTypeFilter;

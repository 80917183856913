import React                from "react";
import Styled               from "styled-components";
import Navigate             from "Dashboard/Core/Navigate";

// Components
import DailyHeader          from "./Daily/DailyHeader";
import DailyContent         from "./Daily/DailyContent";
import CommercialHeader     from "./Commercial/CommercialHeader";
import CommercialContent    from "./Commercial/CommercialContent";
import OperationsHeader     from "./Operations/OperationsHeader";
import OperationsContent    from "./Operations/OperationsContent";
import CatalogHeader        from "./Catalog/CatalogHeader";
import CatalogContent       from "./Catalog/CatalogContent";
import MapHeader            from "./Map/MapHeader";
import MapContent           from "./Map/MapContent";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



// Styles
const Tabs = Styled(TabList)`
    margin-left: var(--main-padding);
`;



/**
 * The Dashboard Admin Page
 * @returns {React.ReactElement}
 */
function DashboardAdminPage() {
    const { dashboardTab } = Navigate.useParams();
    const navigate = Navigate.useGoto();


    // Load the Data
    React.useEffect(() => {
        if (!dashboardTab) {
            navigate("DASHBOARD", "DAILY");
        }
    }, [ dashboardTab ]);

    // Handles the Tab
    const handleTab = (url) => {
        navigate("DASHBOARD", url);
    };


    // Variables
    const isDaily      = Navigate.isUrl("DAILY",      dashboardTab);
    const isCommercial = Navigate.isUrl("COMMERCIAL", dashboardTab);
    const isOperations = Navigate.isUrl("OPERATIONS", dashboardTab);
    const isCatalog    = Navigate.isUrl("CATALOG",    dashboardTab);
    const isMap       =  Navigate.isUrl("MAP",        dashboardTab);


    // Do the Render
    return <Main>
        <Header message="DASHBOARD_NAME" icon="home">
            <DailyHeader isHidden={!isDaily} />
            <CommercialHeader isHidden={!isCommercial} />
            <OperationsHeader isHidden={!isOperations} />
            <CatalogHeader isHidden={!isCatalog} />
            <MapHeader isHidden={!isMap} />
        </Header>

        <Tabs selected={dashboardTab} onClick={handleTab}>
            <TabItem
                message="DASHBOARD_DAILY"
                url="DAILY"
            />
            <TabItem
                message="DASHBOARD_COMMERCIAL"
                url="COMMERCIAL"
            />
            <TabItem
                message="DASHBOARD_OPERATIONS"
                url="OPERATIONS"
            />
            <TabItem
                message="DASHBOARD_CATALOG"
                url="CATALOG"
            />
            <TabItem
                message="GENERAL_MAP"
                url="MAP"
            />
        </Tabs>

        <Content>
            <DailyContent isHidden={!isDaily} />
            <CommercialContent isHidden={!isCommercial} />
            <OperationsContent isHidden={!isOperations} />
            <CatalogContent isHidden={!isCatalog} />
            <MapContent isHidden={!isMap} />
        </Content>
    </Main>;
}

export default DashboardAdminPage;

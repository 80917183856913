import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import useList              from "Dashboard/Hooks/List";
import Utils                from "Dashboard/Utils/Utils";

// Components
import CoverageTypeEdit     from "./CoverageTypeEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import ColorCircle          from "Dashboard/Components/Common/ColorCircle";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Coverage Type List
 * @returns {React.ReactElement}
 */
function CoverageTypeList() {
    const { fetch, load } = useList("coverageType");

    const data = Store.useState("coverageType");
    const { loading, canEdit, list, total, sort } = data;

    const { deleteElem } = Store.useAction("coverageType");


    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "coverageTypeID", elemID, "name");

    return <Main>
        <Header message="COVERAGE_TYPES_NAME" icon="coverage-type">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="COVERAGE_TYPES_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="name"             message="GENERAL_NAME"                isTitle isFlex smallSpace />
                    <TableHeader field="coverageTypeCode" message="GENERAL_CODE"                />
                    <TableHeader field="reqCoverage"      message="COVERAGE_TYPES_REQ_COVERAGE" maxWidth="140" align="center" />
                    <TableHeader field="position"         message="GENERAL_POSITION"            maxWidth="80" align="center" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.coverageTypeID} elemID={elem.coverageTypeID}>
                        <TableCell>
                            <ColorCircle color={elem.color} />
                            {elem.name}
                        </TableCell>
                        <TableCell message={elem.coverageTypeCode} />
                        <TableCell message={elem.reqCoverageText}  />
                        <TableCell message={elem.position}         />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="COVERAGE_TYPES_EDIT_TITLE"   />
                    <TableAction action="DELETE" message="COVERAGE_TYPES_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <CoverageTypeEdit
            open={action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="COVERAGE_TYPES_DELETE_TITLE"
            message="COVERAGE_TYPES_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

export default CoverageTypeList;

import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import PageContainer        from "Dashboard/Components/Page/PageContainer";
import PageColumns          from "Dashboard/Components/Page/PageColumns";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Pharmacy View
 * @returns {React.ReactElement}
 */
function PharmacyView() {
    const { pharmacyID } = Navigate.useParams();

    const { charging, elem } = Store.useState("pharmacy");
    const { fetchElem } = Store.useAction("pharmacy");


    // Load the Data
    React.useEffect(() => {
        if (pharmacyID) {
            fetchElem(pharmacyID);
        }
    }, [ pharmacyID ]);


    // Do the Render
    return <Main>
        <Header message="GENERAL_INFO" icon="pharmacy" />
        <Content isLoading={charging}>
            <PageContainer>
                <PageColumns amount="1">
                    <ViewField
                        label="GENERAL_NAME"
                        value={elem.name}
                    />
                    <ViewField
                        label="GENERAL_IMAGE_URL"
                        value={elem.image}
                    />
                    <ViewField
                        label="GENERAL_COLOR"
                        value={elem.color}
                    />

                    <ViewField
                        label="PHARMACIES_HAS_WEB_CHAT"
                        value={elem.hasWebChatText}
                    />
                    <ViewField
                        isHidden={!elem.hasWebChat}
                        label="PHARMACIES_WEB_CHAT_ID"
                        helperText="PHARMACIES_WEB_CHAT_ID_HELP"
                        value={elem.webChatID}
                    />

                    <ViewField
                        label="PHARMACIES_HIDE_IN_LIST"
                        value={elem.hideInPharmacyListText}
                    />
                    <ViewField
                        label="PHARMACIES_FOR_TESTING"
                        value={elem.forTestingText}
                    />
                    <ViewField
                        label="PHARMACIES_ORDER_TESTING"
                        value={elem.orderTestingText}
                    />
                </PageColumns>
            </PageContainer>
        </Content>
    </Main>;
}

export default PharmacyView;

import React                from "react";
import PropTypes            from "prop-types";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Client View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ClientView(props) {
    const { open, elemID, onClose } = props;


    // Load the Data
    const { loading, elem } = useDialog("client", open, elemID);


    // Do the Render
    return <ViewDialog
        open={open}
        icon="client"
        title="CLIENTS_VIEW_TITLE"
        onClose={onClose}
        isLoading={loading}
        withSpacing
    >
        <Columns>
            <ViewField
                label="GENERAL_FIRST_NAME"
                value={elem.firstName}
            />
            <ViewField
                label="GENERAL_LAST_NAME"
                value={elem.lastName}
            />

            <ViewField
                label="GENERAL_EMAIL"
                value={elem.email}
            />
            <ViewField
                label="GENERAL_PHONE"
                value={elem.phone}
            />

            <ViewField
                label="CLIENTS_DNI"
                value={elem.dni}
            />
            <ViewField
                label="CLIENTS_BIRTH_DATE"
                value={elem.birthText}
            />

            <ViewField
                label="CLIENTS_SEX"
                value={elem.sex}
            />
            <ViewField
                label="GENERAL_COMMUNE"
                value={elem.commune}
            />
            <ViewField
                className="columns-double"
                label="GENERAL_LOCATION"
                value={elem.location}
            />
            <ViewField
                label="GENERAL_ADDRESS_ADDITIONAL"
                value={elem.addressAdditional}
            />
            <ViewField
                label="GENERAL_CITY"
                value={elem.city}
            />
            <ViewField
                label="GENERAL_LATITUDE"
                value={elem.latitude}
            />
            <ViewField
                label="GENERAL_LONGITUDE"
                value={elem.longitude}
            />
        </Columns>
    </ViewDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ClientView.propTypes = {
    open    : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    elemID  : PropTypes.number,
};

export default ClientView;

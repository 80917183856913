import React                from "react";
import Styled               from "styled-components";

// Components
import LoginPage            from "Components/Auth/LoginPage";
import RecoverPassPage      from "Components/Auth/RecoverPassPage";
import CodePage             from "Components/Auth/CodePage";
import ResetPassPage        from "Components/Auth/ResetPassPage";
import ChangePassPage       from "Components/Auth/ChangePassPage";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import GuestRoute           from "Dashboard/Components/Route/GuestRoute";



// Styles
const Container = Styled.main`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(to right, #f4f4f4 54%, var(--primary-color) 54%);
    overflow: hidden;
`;
const Section = Styled.section`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: var(--full-height);
    z-index: 1;
`;

const Version = Styled.div`
    position: absolute;
    bottom: 8px;
    right: 8px;
    font-size: 10px;
    opacity: 0.5;
`;



/**
 * The Auth Page
 * @returns {React.ReactElement}
 */
function Auth() {
    return <Container>
        <Section>
            <Router>
                <GuestRoute
                    url="LOGIN"
                    component={LoginPage}
                    exact
                />
                <GuestRoute
                    url="PASS_RECOVER"
                    component={RecoverPassPage}
                    exact
                />
                <GuestRoute
                    url="PASS_CODE"
                    component={CodePage}
                    exact
                />
                <GuestRoute
                    url="PASS_RESET_CODE"
                    component={ResetPassPage}
                    exact
                />
                <GuestRoute
                    url="PASS_CHANGE"
                    component={ChangePassPage}
                    exact
                />
            </Router>
        </Section>
        <Version>{process.env.REACT_APP_VERSION}</Version>
    </Container>;
}

export default Auth;

import React                from "react";
import { Line }             from "react-chartjs-2";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";



// Styles
const Container = Styled.div`
    flex-grow: 2;
    box-sizing: border-box;
    height: var(--dashboard-report);
    padding: var(--main-gap);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
`;



/**
 * The Daily Chart Line
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DailyChartLine(props) {
    const { currentDate } = props;

    const {
        dayLabels, holidays,
        thisOrders, lastOrders, thisEffective, lastEffective,
    } = Store.useState("dashboardDaily");


    // Creates a new Date at the given day
    const createDate = (day) => {
        return DateTime.create(currentDate.time).changeDay(day);
    };


    // Do the Render
    const date      = DateTime.create(currentDate.time);
    const thisMonth = date.getMonthName();
    const lastMonth = date.toMonthStart().moveMonth(-1).getMonthName();

    return <Container>
        <Line
            options={{
                maintainAspectRatio : false,
                interaction : {
                    mode      : "index",
                    intersect : false,
                },
                animation : {
                    duration : 0,
                },
                scales : {
                    x : {
                        stacked : true,
                        ticks   : {
                            callback(label) {
                                const date = createDate(Number(label) + 1);
                                if (Commons.isHoliday(holidays, date.day)) {
                                    return `F ${date.day}`;
                                }
                                if (date.isWeekend) {
                                    return `${date.getDayName(1)} ${date.day}`;
                                }
                                return date.day;
                            },
                            color(context) {
                                const label     = String(context.tick.label);
                                const isWeekend = label.startsWith("S") || label.startsWith("D");
                                const isHoliday = label.startsWith("F");
                                if (isHoliday) {
                                    return "green";
                                }
                                if (isWeekend) {
                                    return "red";
                                }
                                return "black";
                            },
                        },
                    },
                    y : Commons.getScaleOptions(false, false, false),
                },
                plugins : {
                    legend  : Commons.getLegendOptions("top"),
                    tooltip : Commons.getTooltipOptions(false, {
                        title(context) {
                            const date    = createDate(Number(context[0].label));
                            const dayName = date.getDayName();
                            const holiday = Utils.getValue(holidays, "day", date.day, "name");
                            if (holiday) {
                                return `${dayName} ${date.day} (${holiday})`;
                            }
                            return `${dayName} ${date.day}`;
                        },
                    }),
                },
            }}
            data={{
                labels   : dayLabels,
                datasets : [
                    {
                        // @ts-ignore
                        order            : 0,
                        yAxisID          : "y",
                        label            : NLS.format("DASHBOARD_CHART_ORDERS", thisMonth),
                        data             : thisOrders,
                        borderColor      : "rgb(255, 134, 128)",
                        backgroundColor  : "rgb(255, 134, 128)",
                        pointBorderWidth : 4,
                        borderWidth      : 2,
                        tension          : 0.1,
                        borderDash       : [],
                    },
                    {
                        // @ts-ignore
                        order            : 1,
                        yAxisID          : "y",
                        label            : NLS.format("DASHBOARD_CHART_EFFECTIVE", thisMonth),
                        data             : thisEffective,
                        borderColor      : "rgb(181, 158, 236)",
                        backgroundColor  : "rgb(181, 158, 236)",
                        pointBorderWidth : 4,
                        borderWidth      : 2,
                        tension          : 0.1,
                        borderDash       : [],
                    },

                    {
                        // @ts-ignore
                        order            : 2,
                        yAxisID          : "y",
                        label            : NLS.format("DASHBOARD_CHART_ORDERS", lastMonth),
                        data             : lastOrders,
                        borderColor      : "rgba(255, 134, 128, 0.5)",
                        backgroundColor  : "rgba(255, 134, 128, 0.5)",
                        pointBorderWidth : 2,
                        borderWidth      : 1,
                        tension          : 0.1,
                        borderDash       : [ 10, 5 ],
                    },
                    {
                        // @ts-ignore
                        order            : 3,
                        yAxisID          : "y",
                        label            : NLS.format("DASHBOARD_CHART_EFFECTIVE", lastMonth),
                        data             : lastEffective,
                        borderColor      : "rgb(181, 158, 236, 0.5)",
                        backgroundColor  : "rgb(181, 158, 236, 0.5)",
                        pointBorderWidth : 2,
                        borderWidth      : 1,
                        tension          : 0.1,
                        borderDash       : [ 10, 5 ],
                    },
                ],
            }}
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DailyChartLine.propTypes = {
    currentDate : PropTypes.object.isRequired,
};

export default DailyChartLine;

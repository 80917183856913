// The App Params
const params = {
    "CODE"               : "code",
    "DASHBOARD_TAB"      : "dashboardTab",
    "PHARMACY"           : "pharmacyID",
    "SUBSIDIARY"         : "subsidiaryID",
    "ORDER_TAB"          : "orderTab",
    "ORDER_ID"           : "orderID",
    "PRODUCT_ID"         : "productID",
    "MEDICINE_ID"        : "medicineID",
    "CHANGE_PRODUCT_TAB" : "changeProductTab",
    "CHANGE_CATALOG_TAB" : "changeCatalogTab",
    "DOCUMENT_SLUG"      : "documentSlug",
    "LOG"                : "logID",
};

export default params;

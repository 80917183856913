import React                from "react";
import PropTypes            from "prop-types";



/**
 * The Map Heatmap
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MapHeatmap(props) {
    const { isHidden, map, list, radius, blueColor, purpleColor } = props;

    const [ heatmap, setHeatmap ] = React.useState(null);
    const [ points,  setPoints  ] = React.useState(null);


    // Returns the Points
    const getPoints = () => {
        return list.filter(({ position : { lat, lng } }) => lat !== 0 && lng !== 0).map(({ position : { lat, lng } }) => {
            return new window.google.maps.LatLng(lat, lng);
        });
    };

    // Create the Heatmap
    React.useEffect(() => {
        if (map && !heatmap) {
            const newPoints  = new window.google.maps.MVCArray(getPoints());
            const newHeatmap = new window.google.maps.visualization.HeatmapLayer({
                map,
                maxIntensity : 10,
                data         : newPoints,
                radius       : radius,
                gradient     : blueColor ? [
                    "rgba(0, 255, 255, 0)",
                    "rgba(0, 255, 255, 1)",
                    "rgba(0, 191, 255, 1)",
                    "rgba(0, 127, 255, 1)",
                    "rgba(0, 63, 255, 1)",
                    "rgba(0, 0, 255, 1)",
                    "rgba(0, 0, 223, 1)",
                    "rgba(0, 0, 191, 1)",
                    "rgba(0, 0, 159, 1)",
                    "rgba(0, 0, 127, 1)",
                    "rgba(63, 0, 91, 1)",
                    "rgba(127, 0, 63, 1)",
                    "rgba(191, 0, 31, 1)",
                    "rgba(255, 0, 0, 1)",
                ] : (purpleColor ? [
                    "rgba(204, 203, 245, 0)",
                    "rgba(204, 203, 245, 1)",
                    "rgba(198, 197, 237, 1)",
                    "rgba(198, 188, 253, 1)",
                    "rgba(176, 167, 235, 1)",
                    "rgba(168, 152, 249, 1)",
                    "rgba(125, 132, 224, 1)",
                    "rgba(101, 100, 222, 1)",
                    "rgba(62, 67, 181, 1)",
                    "rgba(63, 0, 91, 1)",
                    "rgba(127, 0, 63, 1)",
                    "rgba(191, 0, 31, 1)",
                    "rgba(255, 0, 0, 1)",
                ] : null),
            });

            if (isHidden) {
                newHeatmap.setMap(null);
            }

            setHeatmap(newHeatmap);
            setPoints(newPoints);
        }
    }, [ map, heatmap ]);


    // Update the Heatmap
    React.useEffect(() => {
        if (points) {
            points.clear();
            for (const point of getPoints()) {
                points.push(point);
            }
        }
    }, [ list.length ]);


    // Shows or Hides the Heatmap
    React.useEffect(() => {
        if (!isHidden && heatmap) {
            heatmap.setMap(map);
        } else if (isHidden && heatmap) {
            heatmap.setMap(null);
        }
    }, [ isHidden ]);


    // Updates the Circle Radius
    React.useEffect(() => {
        if (heatmap) {
            heatmap.set("radius", Number(radius));
        }
    }, [ heatmap, radius ]);


    // Do the Render
    return <React.Fragment />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MapHeatmap.propTypes = {
    isHidden    : PropTypes.bool.isRequired,
    map         : PropTypes.object.isRequired,
    list        : PropTypes.array.isRequired,
    radius      : PropTypes.number.isRequired,
    blueColor   : PropTypes.bool,
    purpleColor : PropTypes.bool,
};

export default MapHeatmap;

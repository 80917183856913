const strings = {

    TITLE                                   : "Benvida",
    LANGUAGES                               : { "es" : "Español", "en" : "English" },



    // General
    GENERAL_ACCEPT                          : "Aceptar",
    GENERAL_ACCESS                          : "Rol",
    GENERAL_ACTIONS                         : "Acciones",
    GENERAL_ADD                             : "Agregar",
    GENERAL_ADD_BAR_CODE                    : "Agregar código de barras",
    GENERAL_ADD_FIELD                       : "Agregar valor",
    GENERAL_ADD_PERIOD                      : "Agregar periodo",
    GENERAL_ADDRESS_ADDITIONAL              : "Información adicional",
    GENERAL_ALFABETA                        : "Alfabeta",
    GENERAL_ALL                             : "Todos",
    GENERAL_ALL_FEM                         : "Todas",
    GENERAL_AMOUNT                          : "Monto",
    GENERAL_AND                             : "y",
    GENERAL_BAR_CODE                        : "Código de barras",
    GENERAL_BAR_CODES                       : "Códigos de barras",
    GENERAL_BENVIDA                         : "Benvida",
    GENERAL_CANCEL                          : "Cancelar",
    GENERAL_CELLPHONE                       : "Celular",
    GENERAL_CHANGES                         : "Cambios",
    GENERAL_CHARACTERS                      : "Caracteres: {0}",
    GENERAL_CHARACTERS_MAX                  : "Caracteres: {0}/{1}",
    GENERAL_CLOSE                           : "Cerrar",
    GENERAL_CREATE                          : "Crear",
    GENERAL_CREATED                         : "Creado el",
    GENERAL_CITY                            : "Ciudad",
    GENERAL_CODE                            : "Código",
    GENERAL_CODE_ALFABETA                   : "Código Alfabeta",
    GENERAL_CODE_BENVIDA                    : "Código Benvida",
    GENERAL_COLOR                           : "Color",
    GENERAL_COMMUNE                         : "Comuna",
    GENERAL_CONTENT                         : "Contenido",
    GENERAL_CREATED_AT                      : "Creado el",
    GENERAL_CREATED_AT_FEM                  : "Creada el",
    GENERAL_DATE                            : "Fecha",
    GENERAL_DAY                             : "Día",
    GENERAL_DAYS                            : "Días",
    GENERAL_DELETE                          : "Eliminar",
    GENERAL_DESCRIPTION                     : "Descripción",
    GENERAL_DOWNLOAD                        : "Descargar",
    GENERAL_EDIT                            : "Editar",
    GENERAL_EMAIL                           : "Email",
    GENERAL_EXCLUDE_FILTER                  : "Excluir farmacias y sucursales",
    GENERAL_EXPIRES_AT                      : "Vence el",
    GENERAL_EXPORT                          : "Exportar",
    GENERAL_FAIL                            : "Fallido",
    GENERAL_FILTER                          : "Filtrar",
    GENERAL_FIRST_NAME                      : "Nombre",
    GENERAL_FOR_CELIAC                      : "Apto para celíacos",
    GENERAL_FROM                            : "Desde",
    GENERAL_FROM_DATE                       : "Fecha desde",
    GENERAL_HELP                            : "Ayuda",
    GENERAL_HOUR                            : "Hora",
    GENERAL_ID                              : "ID",
    GENERAL_IMAGE                           : "Imagen",
    GENERAL_IMAGE_URL                       : "Url de la imagen",
    GENERAL_INFO                            : "Información",
    GENERAL_INSTALLMENTS                    : "Cuotas",
    GENERAL_IS_ACTIVE                       : "Está activo",
    GENERAL_IS_ACTIVE_FEM                   : "Está activa",
    GENERAL_IS_EXPIRED                      : "Está vencido",
    GENERAL_IS_EXPIRED_FEM                  : "Está vencida",
    GENERAL_LAST_NAME                       : "Apellido",
    GENERAL_LATITUDE                        : "Latitud",
    GENERAL_LOADING                         : "Cargando",
    GENERAL_LOADING_DOTS                    : "Cargando ...",
    GENERAL_LOCATION                        : "Dirección",
    GENERAL_LONGITUDE                       : "Longitud",
    GENERAL_LOGIN_AS                        : "Ingresar como usuario",
    GENERAL_LOGIN_TIME                      : "Último ingreso",
    GENERAL_LOGOUT                          : "Salir",
    GENERAL_MAP                             : "Mapa",
    GENERAL_MESSAGE                         : "Mensaje",
    GENERAL_NEXT                            : "Siguiente",
    GENERAL_MODIFIED_AT                     : "Modificado el",
    GENERAL_MONTH                           : "Mes",
    GENERAL_MP                              : "MP",
    GENERAL_NAME                            : "Nombre",
    GENERAL_NAME_ALFABETA                   : "Nombre Alfabeta",
    GENERAL_NAME_BENVIDA                    : "Nombre Benvida",
    GENERAL_NEW_PASSWORD                    : "Nueva contraseña",
    GENERAL_NO                              : "No",
    GENERAL_NONE                            : "Ninguna",
    GENERAL_NONE_RESULTS                    : "No se encontraron resultados",
    GENERAL_OBSERVATIONS                    : "Observaciones",
    GENERAL_ONE_ERROR                       : "Error",
    GENERAL_OTHERS                          : "Otros",
    GENERAL_ORIGIN                          : "Origen",
    GENERAL_PASSWORD                        : "Contraseña",
    GENERAL_PASSWORD_CONFIRM                : "Confirmar contraseña",
    GENERAL_PAGE                            : "Página {0} de {1}",
    GENERAL_PAGE_OF                         : "{0}-{1} de {2}",
    GENERAL_PENDING                         : "Pendiente",
    GENERAL_PERCENT                         : "Porcentaje",
    GENERAL_PERIOD_TITLE                    : "Periodo {0}",
    GENERAL_PDF                             : "PDF",
    GENERAL_PHONE                           : "Teléfono",
    GENERAL_PHONE_TIP                       : "Agregar el prefijo de 549 para Argentina.",
    GENERAL_PREV                            : "Anterior",
    GENERAL_PRICE                           : "Precio",
    GENERAL_PRICE_VALIDITY                  : "Precio válido hasta",
    GENERAL_PRINT                           : "Imprimir",
    GENERAL_PROSPECTO                       : "Prospecto",
    GENERAL_POSITION                        : "Posición",
    GENERAL_POTENCY                         : "Potencia",
    GENERAL_QUANTITY                        : "Cantidad",
    GENERAL_RADIUS                          : "Radio",
    GENERAL_REASON                          : "Motivo",
    GENERAL_REQ_RECIPE                      : "Requisito de receta",
    GENERAL_REQ_REFRIGERATION               : "Requiere refrigeración",
    GENERAL_REQ_PASS_CHANGE                 : "Requerir un cambio de contraseña luego de ingresar",
    GENERAL_REMOVE_FILTER                   : "Quitar filtro",
    GENERAL_RESULT                          : "Resultado",
    GENERAL_ROWS_PER_PAGE                   : "Filas a mostrar:",
    GENERAL_SAVE                            : "Guardar",
    GENERAL_SEARCH                          : "Buscar",
    GENERAL_SEARCHING                       : "Buscando ...",
    GENERAL_SELECT                          : "Seleccionar",
    GENERAL_SELECT_FILE                     : "Seleccionar archivo",
    GENERAL_SELECT_IMAGE                    : "Seleccionar imagen",
    GENERAL_SEND_EMAILS                     : "Enviar Emails",
    GENERAL_SEND_WHATSAPP                   : "Enviar WhatsApps",
    GENERAL_SEND_TO                         : "Enviar a",
    GENERAL_SENT                            : "Enviado",
    GENERAL_SENT_TO                         : "Enviado el",
    GENERAL_SETTINGS                        : "Configuración",
    GENERAL_SHOW_INACTIVE                   : "Mostrar inactivos",
    GENERAL_SHOW_TO_USER                    : "Mostrar al farmacéutico",
    GENERAL_SOCIAL_REASON                   : "Razón social",
    GENERAL_SUCCESS                         : "Exitoso",
    GENERAL_SUBJECT                         : "Asunto",
    GENERAL_SUBTOTAL                        : "Subtotal",
    GENERAL_START                           : "Inicio",
    GENERAL_STATUS                          : "Estado",
    GENERAL_TEXT                            : "Texto",
    GENERAL_TIME                            : "Tiempo",
    GENERAL_TO                              : "Hasta",
    GENERAL_TO_DATE                         : "Fecha hasta",
    GENERAL_TOTAL                           : "Total",
    GENERAL_TYPE                            : "Tipo",
    GENERAL_URL                             : "Url",
    GENERAL_USER                            : "Usuario",
    GENERAL_VIEW                            : "Ver",
    GENERAL_VIDEO                           : "Video",
    GENERAL_YEAR                            : "Año",
    GENERAL_YES                             : "Si",

    GENERAL_ERROR                           : "Ocurrió un error inesperado. Estamos trabajando en resolverlo.",
    GENERAL_ERROR_PATH                      : "Ruta incorrecta.",

    GENERAL_ERROR_ACCESS_EMPTY              : "Debe indicar un rol.",
    GENERAL_ERROR_ACCESS_INVALID            : "El rol indicado es incorrecto.",
    GENERAL_ERROR_BAR_CODES                 : "Los códigos de barras deben ser numéricos.",
    GENERAL_ERROR_CODE_ALFABETA_EMPTY       : "Debe indicar el código Alfabeta.",
    GENERAL_ERROR_CODE_ALFABETA_INVALID     : "El código Alfabeta debe contener solo números.",
    GENERAL_ERROR_CODE_ALFABETA_EXISTS      : "El código Alfabeta indicado no existe.",
    GENERAL_ERROR_CODE_ALFABETA_USED        : "El código Alfabeta indicado ya esta usado.",
    GENERAL_ERROR_CODE_BENVIDA_EMPTY        : "Debe indicar el código Benvida",
    GENERAL_ERROR_CODE_BENVIDA_INVALID      : "El código Benvida debe contener solo números",
    GENERAL_ERROR_CODE_BENVIDA_USED         : "El código Benvida indicado ya esta usado.",
    GENERAL_ERROR_DATE_INVALID              : "La fecha indicada es inválida.",
    GENERAL_ERROR_DATE_TIME_INVALID         : "La hora indicada es inválida.",
    GENERAL_ERROR_DATE_HOUR_EMPTY           : "Debe indicar una fecha y hora válida.",
    GENERAL_ERROR_DATE_NOW                  : "La fecha debe ser anterior al día actual.",
    GENERAL_ERROR_EDIT                      : "No tiene permiso para editar.",
    GENERAL_ERROR_EMAIL_EMPTY               : "Debe completar el email.",
    GENERAL_ERROR_EMAIL_INVALID             : "El email indicado es incorrecto.",
    GENERAL_ERROR_EMAIL_USED                : "El email indicado esta siendo usado por otra cuenta.",
    GENERAL_ERROR_FEE_TYPE_EMPTY            : "Debe completar el tipo de fee.",
    GENERAL_ERROR_FEE_TYPE_INVALID          : "El tipo de fee indicado es incorrecto.",
    GENERAL_ERROR_FEE_VALUE                 : "El valor del fee debe ser un valor numérico.",
    GENERAL_ERROR_FILE_EXCEL                : "El archivo indicado debe ser un Excel.",
    GENERAL_ERROR_FILE_IMAGE                : "El archivo indicado debe ser una imagen.",
    GENERAL_ERROR_FILE_IMPORT               : "Debe incluir el archivo a importar.",
    GENERAL_ERROR_FILE_READ                 : "No se pudo leer el archivo indicado.",
    GENERAL_ERROR_FILE_SIZE                 : "El archivo es demasiado grande.",
    GENERAL_ERROR_FIRST_NAME                : "Debe completar el nombre.",
    GENERAL_ERROR_LAST_NAME                 : "Debe completar el apellido.",
    GENERAL_ERROR_NAME_ALFABETA             : "Debe indicar el nombre Alfabeta.",
    GENERAL_ERROR_REQ_RECIPE                : "Debe indicar un requisito de receta válido.",
    GENERAL_ERROR_PASSWORD_EMPTY            : "Debe completar la contraseña.",
    GENERAL_ERROR_PASSWORD_INVALID          : "La contraseña debe ser entre 6 y 20 caracteres y contener al menos un número y una letra.",
    GENERAL_ERROR_PERIOD_DAYS_EMPTY         : "Debe indicar al menos un día.",
    GENERAL_ERROR_PERIOD_DAYS_INVALID       : "Uno de los días indicados es incorrecto.",
    GENERAL_ERROR_PERIOD_FROM_TIME          : "El tiempo desde indicado es incorrecto.",
    GENERAL_ERROR_PERIOD_TO_TIME            : "El tiempo hasta indicado es incorrecto.",
    GENERAL_ERROR_PERIOD_FROM_TO            : "El tiempo desde debe ser anterior al tiempo hasta.",
    GENERAL_ERROR_PHONE                     : "El teléfono indicado es incorrecto.",
    GENERAL_ERROR_POSITION                  : "La posición debe ser un número mayor a cero.",
    GENERAL_ERROR_POTENCY                   : "La potencia debe ser un número mayor a 0.",
    GENERAL_ERROR_PROSPECTO                 : "El prospecto debe ser un valor numérico.",
    GENERAL_ERROR_STATUS                    : "El estado indicado es incorrecto.",
    GENERAL_ERROR_UNITS                     : "Las unidades debe ser un número mayor a 0.",
    GENERAL_ERROR_URL                       : "La url debe comenzar con 'https://'.",



    // Selects
    GENERAL_YES_NO                          : {
        0 : "No",
        1 : "Si",
    },
    GENERAL_YES_BLANK                       : {
        0 : "",
        1 : "Si",
    },
    GENERAL_SET_YES_NO                      : {
        1 : "No",
        2 : "Si",
    },
    SELECT_MODULES                          : {
        "Medicine"            : "Medicamentos",
        "Laboratory"          : "Laboratorios",
        "MonoDrug"            : "Mono drogas",
        "DrugType"            : "Tipos de droga",
        "SaleType"            : "Tipos de venta",
        "HealthType"          : "Tipos de salud",
        "SizeType"            : "Tipos de tamaño",
        "ShapeType"           : "Tipos de formas",
        "PotencyType"         : "Tipos de potencia",
        "UnitType"            : "Tipos de unidad",
        "AdministrationRoute" : "Vías de administración",
        "PharmaAction"        : "Acciones farmacoterapéuticas",
    },
    SELECT_FIELDS                           : {
        "macroCategoryID"  : "Macro Categoría",
        "categoryID"       : "Categoría",
        "subCategoryID"    : "Sub Categoría",
        "laboratoryID"     : "Laboratorio",
        "saleTypeID"       : "Tipo de venta",
        "shapeTypeID"      : "Tipo de forma",
        "monoDrugID"       : "Mono droga",
        "pharmaActionID"   : "Acción farmacoterapéutica",
        "potencyTypeID"    : "Tipo de potencia",
        "spaceIDs"         : "Espacios",
        "alfabetaID"       : "Código Alfabeta",
        "name"             : "Nombre",
        "barCodes"         : "Códigos de barras",
        "potency"          : "Potencia",
        "units"            : "Unidades",
        "prospecto"        : "Prospecto",
        "forCeliac"        : "Para celíacos",
        "reqRefrigeration" : "Requiere refrigeración",
        "reqRecipe"        : "Requisito de receta",
        "price"            : "Precio",
        "validity"         : "Precio válido hasta",
        "isActive"         : "Está activo",
    },
    SELECT_SCHEDULE_TYPES                   : {
        "general"  : "Local",
        "retire"   : "Retiro",
        "delivery" : "Delivery",
    },
    SELECT_ORDER_TYPES                      : {
        "Effective" : "Efectivas",
        "Pending"   : "Pendientes",
        "Rejected"  : "Rechazadas",
        "Canceled"  : "Canceladas",
    },
    SELECT_ORDER_ACTIONS                    : {
        "StartChat"    : "<b>{0}</b> inició el chat a las <b>{2}</b>",
        "EndChat"      : "<b>{0}</b> finalizó el chat a las <b>{2}</b>",
        "AddRecipe"    : "<b>{0}</b> agregó una receta a las <b>{2}</b>",
        "EditRecipe"   : "<b>{0}</b> editó la receta {1} a las <b>{2}</b>",
        "AddCoverage"  : "<b>{0}</b> agregó la cobertura a las <b>{2}</b>",
        "EditCoverage" : "<b>{0}</b> editó la cobertura a las <b>{2}</b>",
    },
    SELECT_DISCOUNT_TYPES                   : {
        "none"    : "Ninguno",
        "percent" : "Porcentaje",
        "value"   : "Valor fijo",
    },
    SELECT_DISCOUNT_ORIGINS                 : {
        "coverage" : "Obra social",
        "pharmacy" : "Farmacia",
    },
    SELECT_RETIREMENT_TYPES                 : {
        "allow"   : "Sí, confirmo el envío a domicilio",
        "disable" : "No, sólo retiro en farmacia",
    },
    SELECT_REQ_RECIPES                      : {
        "none"      : "No requerida",
        "requested" : "Requerida no obligatoria",
        "required"  : "Requerida obligatoria",
    },
    SELECT_PRODUCT_ORIGINS                  : {
        "Import" : "Importado maestro",
        "Manual" : "Creado manualmente",
        "Change" : "Cambio Alfabeta",
    },
    SELECT_LINK_TYPES                       : {
        "Space" : "Espacio",
        "Url"   : "Url",
    },
    SELECT_EMAIL_RESULTS                    : {
        "Sent"            : "Email enviado",
        "NotProcessed"    : "Email sin procesar",
        "NoEmails"        : "Sin emails a enviar",
        "InactiveSend"    : "Envío inactivo",
        "WhiteListFilter" : "Filtrado de lista blanca",
        "InvalidEmail"    : "Email inválido",
        "ProviderError"   : "Error en el proveedor",
        "UnknownError"    : "Error desconocido",
    },
    SELECT_MODIFICATION_TYPES               : {
        "Create" : "Creado",
        "Edit"   : "Editado",
        "Delete" : "Eliminado",
    },
    SELECT_FIELD_TYPES                      : {
        "name"             : "Nombre",
        "laboratoryID"     : "Laboratorio",
        "saleTypeID"       : "Tipo de venta",
        "shapeTypeID"      : "Tipo de forma",
        "monoDrugID"       : "Mono droga",
        "pharmaActionID"   : "Acción farmacoterapéuticas",
        "potencyTypeID"    : "Tipo de potencia",
        "barCodes"         : "Código de barras",
        "potency"          : "Potencia",
        "units"            : "Unidades",
        "prospecto"        : "Prospecto",
        "forCeliac"        : "Para celíacos",
        "reqRefrigeration" : "Para refrigeración",
        "isActive"         : "Está activo",
    },
    SELECT_FEE_TYPES                   : {
        "percent" : "Porcentaje",
        "fixed"   : "Monto fijo",
    },
    SELECT_NPS_FILTERS                 : {
        "none"           : "No tiene",
        "recommendation" : "Puntaje",
        "improvements"   : "Puntaje y mejoras",
    },



    // Auth: Login
    LOGIN_TITLE                             : "Inicie sesión para continuar",
    LOGIN_RECOVER                           : "¿Olvidó su contraseña?",
    LOGIN_BUTTON                            : "Ingresar",
    LOGIN_TERMS_PRE                         : "Operar en Benvida implica aceptar los ",
    LOGIN_TERMS_LINK                        : "términos y condiciones",
    LOGIN_TERMS_POST                        : " en los que se ofrece el servicio.",
    LOGIN_ERROR_SPAM                        : "Ha intentado demasiadas veces.",
    LOGIN_ERROR_CREDENTIALS                 : "El email o la contraseña es incorrecto.",
    LOGIN_ERROR_DISABLED                    : "El ingreso esta deshabilitado.",

    // Auth: Recover Password
    RECOVER_TITLE                           : "Recuperar contraseña",
    RECOVER_HELP                            : "Use el siguiente formulario para recuperar su contraseña. Un mail será enviado a la dirección indicada con instrucciones acerca de como completar el proceso.",
    RECOVER_ACCOUNT                         : "Ingresar con su cuenta",
    RECOVER_BUTTON                          : "Enviar Email",
    RECOVER_SUCCESS                         : "Se ha enviado el email correctamente.",

    // Auth: Code
    CODE_TITLE                              : "Completar código",
    CODE_HELP                               : "Se ha enviado un mail a la dirección de mail indicada con un vínculo que debe hacer click para poder generar una nueva contraseña para su cuenta, o copiar y pegar el código a continuación.",
    CODE_ACCOUNT                            : "Ingresar con su cuenta",
    CODE_BUTTON                             : "Verificar código",
    CODE_ERROR_EMPTY                        : "Debe completar el código.",
    CODE_ERROR_INVALID                      : "El código indicado es incorrecto o se creó hace más de 3 horas. Debe solicitar otro.",
    CODE_SUCCESS                            : "El código se ha verificado correctamente.",

    // Auth: Reset Password
    RESET_TITLE                             : "Resetear contraseña",
    RESET_HELP                              : "Ingrese la contraseña que desea utilizar para acceder a su cuenta.",
    RESET_ACCOUNT                           : "Ingresar con su cuenta",
    RESET_BUTTON                            : "Resetear",
    RESET_ERROR_CODE                        : "Debe proveer un código.",
    RESET_ERROR_INVALID                     : "El código indicado es incorrecto o ha expirado.",
    RESET_ERROR_PASSWORD                    : "Debe completar la nueva contraseña.",
    RESET_ERROR_CONFIRM                     : "Debe confirmar la nueva contraseña.",
    RESET_ERROR_MATCH                       : "Las contraseñas no coinciden.",
    RESET_SUCCESS                           : "Su contraseña fue modificada correctamente.",

    // Auth: Change Password
    CHANGE_TITLE                            : "Cambiar contraseña",
    CHANGE_HELP                             : "Debe cambiar su contraseña por una nueva.",
    CHANGE_OLD_PASSWORD                     : "Contraseña actual",
    CHANGE_NEW_PASSWORD                     : "Nueva contraseña",
    CHANGE_BUTTON                           : "Cambiar contraseña",
    CHANGE_ERROR_INVALID                    : "El email indicado es incorrecto o no requiere cambios.",
    CHANGE_ERROR_OLD                        : "Debe completar su contraseña actual.",
    CHANGE_ERROR_NEW                        : "Debe completar la nueva contraseña.",
    CHANGE_ERROR_CONFIRM                    : "Debe confirmar la nueva contraseña.",
    CHANGE_ERROR_PASSWORD                   : "La contraseña actual es incorrecta.",
    CHANGE_ERROR_DIFFERENT                  : "La nueva contraseña debe ser diferente a la actual.",
    CHANGE_ERROR_MATCH                      : "Las contraseñas no coinciden.",
    CHANGE_SUCCESS                          : "Su contraseña fue modificada correctamente.",



    // Profile: Account
    ACCOUNT_NAME                            : "Cuenta",
    ACCOUNT_MINE                            : "Mi cuenta",
    ACCOUNT_CHANGE_AVATAR                   : "Cambiar foto",
    ACCOUNT_CONNECTIONS_TITLE               : "Dispositivos conectados",
    ACCOUNT_CONNECTIONS_BUTTON              : "Desconectar",
    ACCOUNT_DELETE_TITLE                    : "Eliminar mi cuenta",
    ACCOUNT_DELETE_TEXT                     : "¿Realmente desea eliminar su cuenta? Luego de eliminar su cuenta no podrá volver a entrar al sistema en ningún cliente y se borrarán sus datos.",
    ACCOUNT_DELETE_HELP                     : "Luego de eliminar la cuenta no podrá volver a entrar al sistema en ningún cliente y se borrarán sus datos.",
    ACCOUNT_ERROR_CONFIRM                   : "Debe confirmar su nueva contraseña.",
    ACCOUNT_ERROR_MATCH                     : "Las contraseñas son diferentes.",
    ACCOUNT_ERROR_AVATAR_EMPTY              : "Debe proporcionar una image.",
    ACCOUNT_ERROR_AVATAR_SIZE               : "La imagen es muy grande, intente con una más pequeña.",
    ACCOUNT_ERROR_AVATAR_TYPE               : "El archivo provisto no es una imagen.",
    ACCOUNT_SUCCESS_EDIT                    : "La cuenta fue editada correctamente.",
    ACCOUNT_SUCCESS_UPLOAD                  : "El avatar se subió correctamente.",



    // Dashboard
    DASHBOARD_NAME                          : "Dashboard",
    DASHBOARD_FILTER_TITLE                  : "Filtrar dashboard",
    DASHBOARD_DAILY                         : "Diario",
    DASHBOARD_COMMERCIAL                    : "Comercial",
    DASHBOARD_OPERATIONS                    : "Operaciones",
    DASHBOARD_CATALOG                       : "Catalogación",
    DASHBOARD_DAILY_BILLING_TITLE           : "Facturación por día",
    DASHBOARD_MONTHLY_BILLING_TITLE         : "Facturación por mes",
    DASHBOARD_DAILY_ORDERS_TITLE            : "Pedidos por día",
    DASHBOARD_MONTHLY_ORDERS_TITLE          : "Pedidos por mes",
    DASHBOARD_RETIREMENTS_TITLE             : "Share por tipo de entrega",
    DASHBOARD_RETIREMENTS_TOTAL_TITLE       : "Share por tipo de entrega: Pedidos totales",
    DASHBOARD_RETIREMENTS_EFFECTIVE_TITLE   : "Share por tipo de entrega: Pedidos efectivos",
    DASHBOARD_RETIREMENTS_SUBSIDIARY_TITLE  : "Share por sucursal: {0}",
    DASHBOARD_RETIREMENTS_PHARMACY_TITLE    : "Share por farmacia: {0}",
    DASHBOARD_SUBSIDIARY_TITLE              : "Share por sucursal",
    DASHBOARD_PHARMACY_TITLE                : "Share por farmacia",
    DASHBOARD_COVERAGE_TYPE_TITLE           : "Share por tipo de cobertura",
    DASHBOARD_PAYMENT_GATEWAY_TITLE         : "Share por pasarela de pago",
    DASHBOARD_PAYMENT_TYPE_TITLE            : "Share por tipo de pago",
    DASHBOARD_PAYMENT_METHOD_TITLE          : "Share por método de pago",
    DASHBOARD_BANK_TITLE                    : "Share por banco",
    DASHBOARD_CARD_TYPE_TITLE               : "Share por tipo de tarjeta",
    DASHBOARD_PRODUCT_SALES_TITLE           : "Top 10 de productos por facturación",
    DASHBOARD_PRODUCT_UNITS_TITLE           : "Top 10 de productos por unidades vendidas",
    DASHBOARD_MACRO_CATEGORY_TITLE          : "Top 10 de macro categorías",
    DASHBOARD_CATEGORY_TITLE                : "Top 10 de categorías",
    DASHBOARD_SUB_CATEGORY_TITLE            : "Top 10 de sub categorías",
    DASHBOARD_STATES_TITLE                  : "Órdenes: {0}",
    DASHBOARD_FIRST_MESSAGE                 : "Primer mensaje",
    DASHBOARD_REJECT_REASON_TITLE           : "Tasa de rechazo",
    DASHBOARD_CANCEL_REASON_TITLE           : "Tasa de cancelado",
    DASHBOARD_HEAT_MAP_CREATION             : "Mapa de calor de creación",
    DASHBOARD_HEAT_MAP_APPROVED             : "Mapa de calor de aprobación",
    DASHBOARD_HEAT_MAP_PAID                 : "Mapa de calor de pagado",
    DASHBOARD_HEAT_MAP_RETIRE               : "Mapa de calor de listo para retiro",
    DASHBOARD_HEAT_MAP_RETIRED              : "Mapa de calor de retirado",
    DASHBOARD_HEAT_MAP_DELIVER              : "Mapa de calor de listo para envío",
    DASHBOARD_HEAT_MAP_DELIVERED            : "Mapa de calor de enviado",
    DASHBOARD_HEAT_MAP_REJECTED             : "Mapa de calor de rechazado",
    DASHBOARD_HEAT_MAP_CANCELED             : "Mapa de calor de cancelado",
    DASHBOARD_NO_PHARMACY                   : "Sin farmacia",
    DASHBOARD_BILLING                       : "Facturación",
    DASHBOARD_GROSS_BILLING                 : "Facturación bruta",
    DASHBOARD_GROSS_BILLING_OF              : "Facturación de {0}",
    DASHBOARD_NET_BILLING                   : "Facturación neta",
    DASHBOARD_AVERAGE_TICKET                : "Ticket promedio",
    DASHBOARD_AVERAGE_TICKET_OF             : "Ticket promedio de {0}",
    DASHBOARD_GROSS_OBJECTIVE               : "Objetivo bruto actual",
    DASHBOARD_OBJECTIVE_DIFF                : "Diferencia contra objetivo",
    DASHBOARD_PENDING_ORDERS                : "Pedidos pendientes",
    DASHBOARD_TOTAL_ORDERS                  : "Pedidos totales",
    DASHBOARD_TOTAL_ORDERS_OF               : "Pedidos de {0}",
    DASHBOARD_EFFECTIVE_ORDERS              : "Pedidos efectivos",
    DASHBOARD_UNITS                         : "Unidades",
    DASHBOARD_PENDING_UNITS                 : "Unidades pendientes",
    DASHBOARD_TOTAL_UNITS                   : "Unidades totales",
    DASHBOARD_EFFECTIVE_UNITS               : "Unidades efectivas",
    DASHBOARD_BENVIDA_FEE_CHARGED           : "Comisión Benvida Wallets",
    DASHBOARD_BENVIDA_FEE_TO_CHARGE         : "Comisión Benvida a cobrar",
    DASHBOARD_LAST_MONTH                    : "Pasado",
    DASHBOARD_THIS_MONTH                    : "Actual",
    DASHBOARD_CHART_ORDERS                  : "Totales - {0}",
    DASHBOARD_CHART_EFFECTIVE               : "Efectivos - {0}",
    DASHBOARD_CHART_GROSS_BILLING           : "Fact. bruta - {0}",
    DASHBOARD_CHART_NET_BILLING             : "Fact. neta - {0}",
    DASHBOARD_SALES_DIFF                    : "Diferencia con pedidos",
    DASHBOARD_RETIREMENTS                   : "Retiros",
    DASHBOARD_DELIVERIES                    : "Envíos",
    DASHBOARD_ORDER_TYPES                   : "Tipos de órdenes",
    DASHBOARD_PRODUCTS_CREATED              : "Productos creados",
    DASHBOARD_PRODUCTS_EDITED               : "Productos editados",
    DASHBOARD_FIELDS_UPDATED                : "Campos actualizados",
    DASHBOARD_CATALOGS_PROCESSED            : "Catálogos procesados",

    // Dashboard: Process
    DASHBOARD_PROCESS_UPDATED_TITLE         : "Orden actualizada",
    DASHBOARD_PROCESS_FILE_TITLE            : "Ver contenido",
    DASHBOARD_PROCESS_VOUCHER_TITLE         : "Generar vale",
    DASHBOARD_PROCESS_VOUCHER_TEXT          : "Ingrese la fecha a partir de la cual el producto {0} estará disponible.",
    DASHBOARD_PROCESS_VOUCHER_DATE          : "Vale vence el: {0}",
    DASHBOARD_PROCESS_APPROVE_TITLE         : "Aprobar orden",
    DASHBOARD_PROCESS_APPROVE_TEXT          : "¿Realmente desea aprobar la orden indicada?",
    DASHBOARD_PROCESS_APPROVE_DELIVERY_TEXT : "¿Realmente desea aprobar la orden indicada?<br><br>El cliente seleccionó delivery, ¿confirmas esta opción?",
    DASHBOARD_PROCESS_REJECT_TITLE          : "Rechazar orden",
    DASHBOARD_PROCESS_REJECT_TEXT           : "¿Realmente desea rechazar la orden indicada? Por favor indique un motivo y opcionalmente un mensaje:",
    DASHBOARD_PROCESS_RETIRE_TITLE          : "Marcar orden lista para retiro",
    DASHBOARD_PROCESS_RETIRE_TEXT           : "¿Realmente desea marcar la orden indicada como lista para retiro?",
    DASHBOARD_PROCESS_RETIRED_TITLE         : "Marcar orden como retirada",
    DASHBOARD_PROCESS_RETIRED_TEXT          : "¿Realmente desea marcar la orden indicada como retirada?",
    DASHBOARD_PROCESS_CANCEL_TITLE          : "Cancelar orden",
    DASHBOARD_PROCESS_CANCEL_TEXT           : "¿Realmente desea cancelar la orden indicada? Por favor indique un motivo y opcionalmente un mensaje:",
    DASHBOARD_PROCESS_CANCEL_DATE           : "Fecha de cancelado",
    DASHBOARD_PROCESS_CANCEL_TIME           : "Hora de cancelado",
    DASHBOARD_PROCESS_NULLIFY_TITLE         : "Anular orden",
    DASHBOARD_PROCESS_NULLIFY_TEXT          : "¿Realmente desea anular la orden indicada? Recuerde descartar la receta.",
    DASHBOARD_PROCESS_PENDING               : "Pendientes",
    DASHBOARD_PROCESS_APPROVED              : "Aprobadas",
    DASHBOARD_PROCESS_READY                 : "Listas para retiro",
    DASHBOARD_PROCESS_REJECT                : "Rechazar",
    DASHBOARD_PROCESS_APPROVE               : "Aprobar",
    DASHBOARD_PROCESS_CONTINUE              : "Continuar",
    DASHBOARD_PROCESS_MARK_RETIRE           : "Marcar lista para retiro",
    DASHBOARD_PROCESS_MARK_RETIRED          : "Marcar como retirada",
    DASHBOARD_PROCESS_NULLIFY               : "Anular",
    DASHBOARD_PROCESS_NULLIFY_ERROR         : "La orden fue cancelada. Por favor descarte la receta y anule la orden.",
    DASHBOARD_PROCESS_REASON_TEXT           : "Descripción más detallada",
    DASHBOARD_PROCESS_REASON_CLIENT         : "Este mensaje lo vera el cliente",
    DASHBOARD_PROCESS_REGULAR_PRICE         : "Precio regular",
    DASHBOARD_PROCESS_FINAL_PRICE           : "Precio que abona",
    DASHBOARD_PROCESS_ALERT_SINGULAR        : "¡Hay una orden que requiere su atención!",
    DASHBOARD_PROCESS_ALERT_PLURAL          : "¡Hay {0} órdenes que requieren su atención!",
    DASHBOARD_PROCESS_OPEN_CHAT             : "Chat abierto",
    DASHBOARD_PROCESS_CLOSED_CHAT           : "Chat cerrado",
    DASHBOARD_PROCESS_NONE_MESSAGES         : "No hay mensajes disponibles.",
    DASHBOARD_PROCESS_START_CHAT            : "Inicia un chat con el cliente enviando un mensaje.",
    DASHBOARD_PROCESS_WRITE_TEXT            : "Escribe un mensaje",
    DASHBOARD_PROCESS_UPDATE_FILE           : "Actualizar archivo",
    DASHBOARD_PROCESS_ADD_RECEIPT           : "Sumar receta",
    DASHBOARD_PROCESS_REPLACE_RECEIPT       : "Reemplazar receta {0}",
    DASHBOARD_PROCESS_ADD_COVERAGE          : "Agregar credencial",
    DASHBOARD_PROCESS_REPLACE_COVERAGE      : "Reemplazar credencial",
    DASHBOARD_PROCESS_SUCCESS_APPROVE       : "La orden se aprobó correctamente y se avisó al cliente para que pueda confirmar todos los datos de la orden.",
    DASHBOARD_PROCESS_SUCCESS_APPROVE_ERROR : "<br><br>Tenga en cuenta que el delivery no se puede hacer por el motivo:<br>{0}",
    DASHBOARD_PROCESS_SUCCESS_REJECT        : "La orden se rechazó correctamente y se avisó al cliente. Una vez que el cliente actualize la orden, podrá volver a ver la orden en la columna Pendientes para revisar y aprobar la orden.",
    DASHBOARD_PROCESS_SUCCESS_RETIRED       : "La orden se marcó como retirada correctamente y se avisó al cliente.",
    DASHBOARD_PROCESS_SUCCESS_CANCEL        : "La orden se canceló correctamente y se avisó al cliente.",
    DASHBOARD_PROCESS_SUCCESS_NULLIFY       : "La orden se anuló correctamente.",



    // Business: Pharmacies
    PHARMACIES_NAME                         : "Farmacias",
    PHARMACIES_SINGULAR                     : "Farmacia",
    PHARMACIES_VIEW_TITLE                   : "Ver farmacia",
    PHARMACIES_CREATE_TITLE                 : "Crear farmacia",
    PHARMACIES_EDIT_TITLE                   : "Editar farmacia",
    PHARMACIES_CODE_TITLE                   : "Obtener código",
    PHARMACIES_CODE_TEST                    : "Página de prueba",
    PHARMACIES_DELETE_TITLE                 : "Eliminar farmacia",
    PHARMACIES_DELETE_TEXT                  : "¿Realmente desea eliminar la farmacia <b>{0}</b>?",
    PHARMACIES_HAS_WEB_CHAT                 : "Tiene chat propio",
    PHARMACIES_WEB_CHAT_ID                  : "ID del web chat de BotMaker",
    PHARMACIES_WEB_CHAT_ID_HELP             : "Se puede dejar en blanco para usar el chat genérico de Benvida o cargar un valor para que sea personalizado para la farmacia.",
    PHARMACIES_HIDE_IN_LIST                 : "Ocultar en listado de farmacias de BotMaker",
    PHARMACIES_FOR_TESTING                  : "Para pruebas internas",
    PHARMACIES_ORDER_TESTING                : "Pedido de prueba",
    PHARMACIES_NONE_AVAILABLE               : "No hay farmacias disponibles.",
    PHARMACIES_ERROR_EXISTS                 : "No existe la farmacia indicada.",
    PHARMACIES_SUCCESS_CREATE               : "La farmacia se creó correctamente.",
    PHARMACIES_SUCCESS_EDIT                 : "La farmacia se editó correctamente.",
    PHARMACIES_SUCCESS_DELETE               : "La farmacia se eliminó correctamente.",

    // Business: Subsidiaries
    SUBSIDIARIES_NAME                       : "Sucursales",
    SUBSIDIARIES_SINGULAR                   : "Sucursal",
    SUBSIDIARIES_FILTER_TITLE               : "Filtrar sucursales",
    SUBSIDIARIES_VIEW_TITLE                 : "Ver sucursal",
    SUBSIDIARIES_CREATE_TITLE               : "Crear sucursal",
    SUBSIDIARIES_EDIT_TITLE                 : "Editar sucursal",
    SUBSIDIARIES_DELETE_TITLE               : "Eliminar sucursal",
    SUBSIDIARIES_DELETE_TEXT                : "¿Realmente desea eliminar la sucursal <b>{0}</b>?",
    SUBSIDIARIES_VIEW_MAP                   : "Ver mapa",
    SUBSIDIARIES_CUIT                       : "CUIT",
    SUBSIDIARIES_ALLOWS_AFTER_HOURS         : "Permite pedidos fuera de horario",
    SUBSIDIARIES_NONE_AVAILABLE             : "No hay sucursales disponibles.",
    SUBSIDIARIES_ERROR_EXISTS               : "No existe la sucursal indicada.",
    SUBSIDIARIES_ERROR_PHARMACY             : "Debe indicar la farmacia de la sucursal.",
    SUBSIDIARIES_ERROR_NAME                 : "Debe indicar el nombre de la sucursal.",
    SUBSIDIARIES_ERROR_CODE_EMPTY           : "Debe indicar el código de la sucursal.",
    SUBSIDIARIES_ERROR_CODE_INVALID         : "El código debe contener solo letras y números.",
    SUBSIDIARIES_ERROR_CODE_EXISTS          : "Ya existe otra sucursal con ese código.",
    SUBSIDIARIES_ERROR_CUIT                 : "El CUIT indicado es inválido.",
    SUBSIDIARIES_SUCCESS_CREATE             : "La sucursal se creó correctamente.",
    SUBSIDIARIES_SUCCESS_EDIT               : "La sucursal se editó correctamente.",
    SUBSIDIARIES_SUCCESS_DELETE             : "La sucursal se eliminó correctamente.",

    // Business: Schedules
    SCHEDULES_NAME                          : "Horarios",
    SCHEDULES_SINGULAR                      : "Horario",
    SCHEDULES_CREATE_TITLE                  : "Crear horario",
    SCHEDULES_EDIT_TITLE                    : "Editar horario",
    SCHEDULES_COPY_TITLE                    : "Copiar horario",
    SCHEDULES_DELETE_TITLE                  : "Eliminar horario",
    SCHEDULES_DELETE_TEXT                   : "¿Realmente desea eliminar el horario <b>{0}</b>?",
    SCHEDULES_NONE_AVAILABLE                : "No hay horarios disponibles.",
    SCHEDULES_ERROR_EDIT                    : "No tiene permiso para editar el horario indicado.",
    SCHEDULES_ERROR_EXISTS                  : "No existe el horario indicado.",
    SCHEDULES_ERROR_TYPE_EMPTY              : "Debe indicar el tipo del horario.",
    SCHEDULES_ERROR_TYPE_INVALID            : "El tipo del horario indicado es incorrecto.",
    SCHEDULES_ERROR_TYPE_REPEAT             : "No se puede repetir el tipo de horario.",
    SCHEDULES_ERROR_DELETE                  : "No tiene permiso para eliminar el horario indicado.",
    SCHEDULES_SUCCESS_CREATE                : "El horario se creó correctamente.",
    SCHEDULES_SUCCESS_EDIT                  : "El horario se editó correctamente.",
    SCHEDULES_SUCCESS_DELETE                : "El horario se eliminó correctamente.",

    // Business: Users
    USERS_NAME                              : "Usuarios",
    USERS_SINGULAR                          : "Usuario",
    USERS_FILTER_TITLE                      : "Filtrar usuarios",
    USERS_CREATE_TITLE                      : "Crear usuario",
    USERS_EDIT_TITLE                        : "Editar usuario",
    USERS_DELETE_TITLE                      : "Eliminar usuario",
    USERS_DELETE_TEXT                       : "¿Realmente desea eliminar el usuario <b>{0}</b>?",
    USERS_SUBSIDIARIES_SINGULAR             : "1 sucursal",
    USERS_SUBSIDIARIES_PLURAL               : "{0} sucursales",
    USERS_WELCOME_EMAIL                     : "Enviar email de bienvenida",
    USERS_NONE_AVAILABLE                    : "No hay usuarios disponibles.",
    USERS_ERROR_EXISTS                      : "No existe el usuario indicado.",
    USERS_ERROR_PHARMACY                    : "Debe indicar la farmacia del usuario.",
    USERS_ERROR_SUBSIDIARY                  : "Debe indicar la sucursal del usuario.",
    USERS_ERROR_FIRST_NAME                  : "Debe indicar el nombre del usuario.",
    USERS_ERROR_LAST_NAME                   : "Debe indicar el apellido del usuario.",
    USERS_ERROR_EMAIL_EMPTY                 : "Debe indicar el email del usuario.",
    USERS_ERROR_EMAIL_USED                  : "Ya existe otra usuario con el email indicado.",
    USERS_SUCCESS_CREATE                    : "El usuario se creó correctamente.",
    USERS_SUCCESS_EDIT                      : "El usuario se editó correctamente.",
    USERS_SUCCESS_DELETE                    : "El usuario se eliminó correctamente.",

    // Business: Contacts
    CONTACTS_NAME                           : "Contactos",
    CONTACTS_SINGULAR                       : "Contacto",
    CONTACTS_FILTER_TITLE                   : "Filtrar contactos",
    CONTACTS_VIEW_TITLE                     : "Ver contacto",
    CONTACTS_CREATE_TITLE                   : "Crear contacto",
    CONTACTS_EDIT_TITLE                     : "Editar contacto",
    CONTACTS_DELETE_TITLE                   : "Eliminar contacto",
    CONTACTS_DELETE_TEXT                    : "¿Realmente desea eliminar el contacto <b>{0}</b>?",
    CONTACTS_SEND_ORDER_BY_EMAIL            : "Enviar orden por Email",
    CONTACTS_SEND_ORDER_BY_WHATSAPP         : "Enviar orden por WhatsApp",
    CONTACTS_NONE_AVAILABLE                 : "No hay contactos disponibles.",
    CONTACTS_ERROR_EXISTS                   : "No existe el contacto indicado.",
    CONTACTS_ERROR_PHARMACY_EMPTY           : "Debe indicar al menos una farmacia del contacto.",
    CONTACTS_ERROR_PHARMACY_EXISTS          : "Una de las farmacias indicadas no existe.",
    CONTACTS_ERROR_SUBSIDIARY               : "Una de las sucursales indicadas no existe.",
    CONTACTS_ERROR_FIRST_NAME               : "Debe indicar el nombre del contacto.",
    CONTACTS_ERROR_LAST_NAME                : "Debe indicar el apellido del contacto.",
    CONTACTS_SUCCESS_CREATE                 : "El contacto se creó correctamente.",
    CONTACTS_SUCCESS_EDIT                   : "El contacto se editó correctamente.",
    CONTACTS_SUCCESS_DELETE                 : "El contacto se eliminó correctamente.",



    // Orders
    ORDERS_NAME                             : "Órdenes",
    ORDERS_SINGULAR                         : "Orden",
    ORDERS_FILTER_TITLE                     : "Filtrar órdenes",
    ORDERS_VIEW_TITLE                       : "Ver orden",
    ORDERS_MANAGE_TITLE                     : "Gestionar orden",
    ORDERS_ACTIVATE_TITLE                   : "Activar orden",
    ORDERS_ACTIVATE_TEXT                    : "¿Realmente desea marcar la orden indicada como pendiente?",
    ORDERS_RETIRED_TITLE                    : "Forzar orden retirada",
    ORDERS_RETIRED_TEXT                     : "¿Realmente desea forzar la orden indicada a ser <b>Retirada</b> con los estados intermedios?",
    ORDERS_RETIRED_INFO                     : "Días y horarios",
    ORDERS_CANCEL_TITLE                     : "Forzar orden cancelada",
    ORDERS_CANCEL_TEXT                      : "¿Realmente desea forzar la orden indicada a ser <b>Cancelada</b>?",
    ORDERS_FILE_NAME                        : "Ordenes.xlsx",
    ORDERS_GOTO_CHAT                        : "Ir al chat",
    ORDERS_GOTO_SUBSIDIARY                  : "Ir a la sucursal",
    ORDERS_GOTO_PARENT                      : "Ir a la orden original",
    ORDERS_SALES                            : "Pedidos",
    ORDERS_EFFECTIVE                        : "Efectivas",
    ORDERS_PENDING                          : "Pendientes",
    ORDERS_REJECTED                         : "Rechazadas",
    ORDERS_CANCELED                         : "Canceladas",
    ORDERS_ITS_NAME                         : "Orden #{0}",
    ORDERS_ORDER_INFO                       : "Información de la orden",
    ORDERS_CLIENT_INFO                      : "Información del cliente",
    ORDERS_LOGISTIC_INFO                    : "Información del envío",
    ORDERS_PAYMENT_INFO                     : "Información del pago",
    ORDERS_STATES_TRACKING                  : "Tracking de estados",
    ORDERS_STATES_REASON                    : "<b>Motivo</b>: {0}",
    ORDERS_STATES_MESSAGE                   : "<b>Mensaje</b>: {0}",
    ORDERS_CHAT_TRANSCRIPTION               : "Transcripción del chat",
    ORDERS_NUMBER                           : "Número de orden",
    ORDERS_LAST_STATE_AT                    : "Último estado el",
    ORDERS_WITH_CHAT                        : "Tiene chat con el cliente",
    ORDERS_WITH_NPS                         : "Tiene puntaje",
    ORDERS_USE_INTER_STATES                 : "Usar estados intermedios",
    ORDERS_USE_CREATED_TIME                 : "Usar fecha de creación",
    ORDERS_COVERAGE                         : "Cobertura y credencial",
    ORDERS_COVERAGE_VIEW                    : "Ver archivo de credencial",
    ORDERS_COVERAGE_NAME                    : "Nombre de cobertura",
    ORDERS_COVERAGE_PLAN                    : "Plan de cobertura",
    ORDERS_COVERAGE_DNI                     : "DNI del afiliado",
    ORDERS_COVERAGE_CREDENTIAL              : "Nº afiliado / credencial",
    ORDERS_CLIENT_REQUEST                   : "Pedido del paciente",
    ORDERS_RECIPE                           : "Receta",
    ORDERS_RECIPE_OF                        : "Receta {0} de {1}",
    ORDERS_RECIPE_VIEW                      : "Ver archivo de receta {0}",
    ORDERS_TICKET_NUMBER                    : "Número de ticket",
    ORDERS_INVOICE_NUMBER                   : "Número de factura",
    ORDERS_NPS                              : "Puntaje y mejoras",
    ORDERS_RETIRE_ADDRESS                   : "Dirección de retiro",
    ORDERS_LOGISTIC_ADDRESS                 : "Dirección de envío",
    ORDERS_LOGISTIC_PRICE                   : "Envío",
    ORDERS_LOGISTIC_FEE                     : "Fee del envío",
    ORDERS_LOGISTIC_EXTERNAL_ID             : "ID del operador",
    ORDERS_LOGISTIC_ERROR                   : "Rechazo de delivery",
    ORDERS_TOTAL_TO_PAY                     : "Total a pagar",
    ORDERS_NEW_PRODUCT                      : "Producto nuevo",
    ORDERS_DISCOUNT                         : "Descuento",
    ORDERS_DISCOUNT_TYPE                    : "Tipo de descuento",
    ORDERS_DISCOUNT_VALUE                   : "Valor del descuento",
    ORDERS_DISCOUNT_ORIGIN                  : "Origen",
    ORDERS_VOUCHER_DATE                     : "Fecha del vale",
    ORDERS_PRODUCTS_SINGULAR                : "1 producto",
    ORDERS_PRODUCTS_PLURAL                  : "{0} productos",
    ORDERS_PAYMENT_LINK                     : "Link de pago",
    ORDERS_PAYMENT_EXTERNAL_ID              : "ID de MP (SOURCE_ID)",
    ORDERS_ACTIVATES_IN                     : "Se activa",
    ORDERS_NOTIFIES_IN                      : "Se notifica",
    ORDERS_EXPIRES_IN                       : "Se cancela",
    ORDERS_PRODUCTS_NOTES                   : "Productos / Notas",
    ORDERS_CLIENT_PRODUCTS                  : "Productos / Notas paciente",
    ORDERS_COMMISSION                       : "Comisión",
    ORDERS_COMMISSION_RECEIVED              : "Comisión recibida",
    ORDERS_COMMISSION_BENVIDA               : "Comisión Benvida",
    ORDERS_COMMISSION_MP                    : "Comisión MP",
    ORDERS_EMPTY_DATE_TIP                   : "Si se deja en blanco se usa la fecha del estado anterior.",
    ORDERS_APPROVED_DATE                    : "Fecha de aprobada",
    ORDERS_APPROVED_TIME                    : "Hora de aprobada",
    ORDERS_RETIRE_DATE                      : "Fecha de lista para retiro",
    ORDERS_RETIRE_TIME                      : "Hora de lista para retiro",
    ORDERS_RETIRED_DATE                     : "Fecha de retirado",
    ORDERS_RETIRED_TIME                     : "Hora de retirado",
    ORDERS_NONE_AVAILABLE                   : "No hay ordenes disponibles.",
    ORDERS_ERROR_EXISTS                     : "No existe la orden indicada.",
    ORDERS_ERROR_USING                      : "<b>{0}</b> está gestionando la orden.",
    ORDERS_ERROR_PERMISSION                 : "No tiene permiso para ver la orden indicada.",
    ORDERS_ERROR_CANT_CHAT                  : "No puede enviar mensajes al cliente.",
    ORDERS_ERROR_CANT_EDIT                  : "No puede editar una orden que no esta pendiente.",
    ORDERS_ERROR_CANT_EDIT_COVERAGE         : "No puede editar el archivo de la cobertura.",
    ORDERS_ERROR_CANT_ACTIVATE              : "No puede activar una orden que no esta fuera de horario.",
    ORDERS_ERROR_CANT_APPROVE               : "No puede aprobar una orden que no esta pendiente.",
    ORDERS_ERROR_CANT_APPROVE_VOUCHER       : "La orden tiene un producto con voucher que requiere un pago online que no esta disponible.",
    ORDERS_ERROR_CANT_REJECT                : "No puede rechazar una orden que no esta pendiente.",
    ORDERS_ERROR_CANT_RETIRE                : "No puede marcar la orden como lista para retiro.",
    ORDERS_ERROR_CANT_RETIRED               : "No puede marcar la orden como retirada.",
    ORDERS_ERROR_CANT_CANCEL                : "No puede marcar la orden como cancelada.",
    ORDERS_ERROR_CANT_NULLIFY               : "No puede marcar la orden como anulada.",
    ORDERS_ERROR_DATE_LAST_STATE            : "La fecha debe ser posterior a la fecha del último estado.",
    ORDERS_ERROR_MESSAGE                    : "Debe indicar el mensaje a enviar.",
    ORDERS_ERROR_COVERAGE_TYPE              : "Debe indicar el tipo de cobertura.",
    ORDERS_ERROR_COVERAGE_NAME              : "Debe indicar el nombre de cobertura.",
    ORDERS_ERROR_COVERAGE_PLAN              : "Debe indicar el plan de cobertura.",
    ORDERS_ERROR_COVERAGE_DNI               : "Debe indicar el DNI del afiliado.",
    ORDERS_ERROR_COVERAGE_CREDENTIAL        : "Debe indicar el nº afiliado / credencial.",
    ORDERS_ERROR_PRODUCTS_EMPTY             : "Debe indicar al menos un producto.",
    ORDERS_ERROR_PRODUCT_EMPTY              : "Debe indicar el producto.",
    ORDERS_ERROR_PRODUCT_REPEAT             : "No debe repetir los productos.",
    ORDERS_ERROR_PRODUCT_QUANTITY           : "La cantidad del producto deber ser mayor a 0.",
    ORDERS_ERROR_PRODUCT_PRICE              : "El precio del producto deber ser mayor a 0.",
    ORDERS_ERROR_DISCOUNT_TYPE              : "El tipo de descuento indicado es inválido.",
    ORDERS_ERROR_DISCOUNT_PERCENT           : "El porcentaje de descuento debe ser un número entre 1 y 100.",
    ORDERS_ERROR_DISCOUNT_VALUE             : "El valor de descuento debe ser un número mayor a 0.",
    ORDERS_ERROR_DISCOUNT_ORIGIN_EMPTY      : "Debe indicar el origen del descuento.",
    ORDERS_ERROR_DISCOUNT_ORIGIN_INVALID    : "El origen del descuento es inválido.",
    ORDERS_ERROR_VOUCHER_DATE_INVALID       : "La fecha del vale es inválida.",
    ORDERS_ERROR_VOUCHER_DATE_FUTURE        : "La fecha del vale debe ser futura.",
    ORDERS_ERROR_REASON                     : "Debe indicar la descripción del motivo.",
    ORDERS_SUCCESS_EDIT                     : "La orden se editó correctamente.",
    ORDERS_SUCCESS_ACTIVATE                 : "La orden se activó correctamente.",
    ORDERS_SUCCESS_RETIRE                   : "La orden se marcó como lista para retiro correctamente.",
    ORDERS_SUCCESS_RETIRED                  : "La orden se marcó como retirada correctamente.",
    ORDERS_SUCCESS_CANCEL                   : "La orden se canceló correctamente.",

    // Orders: Clients
    CLIENTS_NAME                            : "Clientes",
    CLIENTS_SINGULAR                        : "Cliente",
    CLIENTS_FILTER_TITLE                    : "Filtrar clientes",
    CLIENTS_VIEW_TITLE                      : "Ver cliente",
    CLIENTS_CREATE_TITLE                    : "Crear cliente",
    CLIENTS_EDIT_TITLE                      : "Editar cliente",
    CLIENTS_SYNC_TITLE                      : "Sincronizar con WoowUp",
    CLIENTS_SYNC_TEXT                       : "¿Realmente desea sincronizar el cliente <b>{0}</b> con WoowUp?",
    CLIENTS_DELETE_TITLE                    : "Eliminar cliente",
    CLIENTS_DELETE_TEXT                     : "¿Realmente desea eliminar el cliente <b>{0}</b>?",
    CLIENTS_FILE_NAME                       : "Clientes.xlsx",
    CLIENTS_DNI                             : "DNI",
    CLIENTS_BIRTH                           : "Nacimiento",
    CLIENTS_BIRTH_DATE                      : "Fecha de nacimiento",
    CLIENTS_AGE                             : "Edad",
    CLIENTS_SEX                             : "Sexo",
    CLIENTS_CONFIRMED_TERMS                 : "Confirmó términos y condiciones",
    CLIENTS_NONE_AVAILABLE                  : "No hay clientes disponibles.",
    CLIENTS_ERROR_EXISTS                    : "No existe el cliente indicado.",
    CLIENTS_ERROR_DNI                       : "El DNI del cliente es incorrecto.",
    CLIENTS_ERROR_BIRTH_DATE                : "La fecha de nacimiento es inválida.",
    CLIENTS_SUCCESS_CREATE                  : "El cliente se creó correctamente.",
    CLIENTS_SUCCESS_EDIT                    : "El cliente se editó correctamente.",
    CLIENTS_SUCCESS_SYNC                    : "El cliente se sincronizó correctamente.",
    CLIENTS_SUCCESS_DELETE                  : "El cliente se eliminó correctamente.",



    // Catalog: Products
    PRODUCTS_NAME                           : "Productos",
    PRODUCTS_SINGULAR                       : "Producto",
    PRODUCTS_TITLE                          : "Producto {0}",
    PRODUCTS_FILTER_TITLE                   : "Filtrar productos",
    PRODUCTS_VIEW_TITLE                     : "Ver producto",
    PRODUCTS_ADD_PRODUCT                    : "Agregar producto",
    PRODUCTS_CREATE_TITLE                   : "Crear producto",
    PRODUCTS_EDIT_TITLE                     : "Editar producto",
    PRODUCTS_MULTI_EDIT_TITLE               : "Edición multiple de productos",
    PRODUCTS_DELETE_TITLE                   : "Eliminar producto",
    PRODUCTS_DELETE_TEXT                    : "¿Realmente desea eliminar el producto <b>{0}</b>?",
    PRODUCTS_FILE_NAME                      : "Productos.xlsx",
    PRODUCTS_GENERAL                        : "Información del producto",
    PRODUCTS_QUANTITY_PER_CONTAINER         : "Cantidad por contenedor",
    PRODUCTS_SKU                            : "SKU",
    PRODUCTS_UPDATES                        : "Actualizaciones",
    PRODUCTS_FIELD                          : "Campo",
    PRODUCTS_OLD_VALUE                      : "Valor anterior",
    PRODUCTS_NEW_VALUE                      : "Valor nuevo",
    PRODUCTS_NONE_AVAILABLE                 : "No hay productos disponibles.",
    PRODUCTS_ERROR_EXISTS                   : "No existe el producto indicado.",
    PRODUCTS_ERROR_NAME                     : "Debe completar el nombre del producto.",
    PRODUCTS_SUCCESS_CREATE                 : "El producto se creó correctamente.",
    PRODUCTS_SUCCESS_EDIT                   : "El producto se editó correctamente.",
    PRODUCTS_SUCCESS_EDIT_MULTI             : "Los productos se editaron correctamente.",
    PRODUCTS_SUCCESS_DELETE                 : "El producto se eliminó correctamente.",

    // Catalog: Macro Categories
    MACRO_CATEGORIES_NAME                   : "Macro categorías",
    MACRO_CATEGORIES_SINGULAR               : "Macro categoría",
    MACRO_CATEGORIES_FILTER_TITLE           : "Filtrar macro categorías",
    MACRO_CATEGORIES_CREATE_TITLE           : "Crear macro categoría",
    MACRO_CATEGORIES_EDIT_TITLE             : "Editar macro categoría",
    MACRO_CATEGORIES_DELETE_TITLE           : "Eliminar macro categoría",
    MACRO_CATEGORIES_DELETE_TEXT            : "¿Realmente desea eliminar la macro categoría <b>{0}</b>?",
    MACRO_CATEGORIES_NONE_AVAILABLE         : "No hay macro categorías disponibles.",
    MACRO_CATEGORIES_ERROR_EXISTS           : "No existe la macro categoría indicada.",
    MACRO_CATEGORIES_ERROR_EMPTY            : "Debe completar la macro categoría.",
    MACRO_CATEGORIES_ERROR_NAME             : "Debe completar el nombre de la macro categoría.",
    MACRO_CATEGORIES_SUCCESS_CREATE         : "La macro categoría se creó correctamente.",
    MACRO_CATEGORIES_SUCCESS_EDIT           : "La macro categoría se editó correctamente.",
    MACRO_CATEGORIES_SUCCESS_DELETE         : "La macro categoría se eliminó correctamente.",

    // Catalog: Categories
    CATEGORIES_NAME                         : "Categorías",
    CATEGORIES_SINGULAR                     : "Categoría",
    CATEGORIES_FILTER_TITLE                 : "Filtrar categorías",
    CATEGORIES_CREATE_TITLE                 : "Crear categoría",
    CATEGORIES_EDIT_TITLE                   : "Editar categoría",
    CATEGORIES_DELETE_TITLE                 : "Eliminar categoría",
    CATEGORIES_DELETE_TEXT                  : "¿Realmente desea eliminar la categoría <b>{0}</b>?",
    CATEGORIES_NONE_AVAILABLE               : "No hay categorías disponibles.",
    CATEGORIES_ERROR_EXISTS                 : "No existe la categoría indicada.",
    CATEGORIES_ERROR_EMPTY                  : "Debe completar la categoría.",
    CATEGORIES_ERROR_NAME                   : "Debe completar el nombre de la categoría.",
    CATEGORIES_SUCCESS_CREATE               : "La categoría se creó correctamente.",
    CATEGORIES_SUCCESS_EDIT                 : "La categoría se editó correctamente.",
    CATEGORIES_SUCCESS_DELETE               : "La categoría se eliminó correctamente.",

    // Catalog: Sub Categories
    SUB_CATEGORIES_NAME                     : "Sub categorías",
    SUB_CATEGORIES_SINGULAR                 : "Sub categoría",
    SUB_CATEGORIES_FILTER_TITLE             : "Filtrar sub categorías",
    SUB_CATEGORIES_CREATE_TITLE             : "Crear sub categoría",
    SUB_CATEGORIES_EDIT_TITLE               : "Editar sub categoría",
    SUB_CATEGORIES_DELETE_TITLE             : "Eliminar sub categoría",
    SUB_CATEGORIES_DELETE_TEXT              : "¿Realmente desea eliminar la sub categoría <b>{0}</b>?",
    SUB_CATEGORIES_NONE_AVAILABLE           : "No hay sub categorías disponibles.",
    SUB_CATEGORIES_ERROR_EXISTS             : "No existe la sub categoría indicada.",
    SUB_CATEGORIES_ERROR_EMPTY              : "Debe completar la sub categoría.",
    SUB_CATEGORIES_ERROR_NAME               : "Debe completar el nombre de la sub categoría.",
    SUB_CATEGORIES_SUCCESS_CREATE           : "La sub categoría se creó correctamente.",
    SUB_CATEGORIES_SUCCESS_EDIT             : "La sub categoría se editó correctamente.",
    SUB_CATEGORIES_SUCCESS_DELETE           : "La sub categoría se eliminó correctamente.",

    // Catalog: Spaces
    SPACES_NAME                             : "Espacios",
    SPACES_SINGULAR                         : "Espacio",
    SPACES_CREATE_TITLE                     : "Crear espacio",
    SPACES_EDIT_TITLE                       : "Editar espacio",
    SPACES_EXPORT_TITLE                     : "Exportar productos",
    SPACES_EXPORT_TEXT                      : "¿Realmente desea exportar los productos del espacio <b>{0}</b>?",
    SPACES_IMPORT_TITLE                     : "Importar productos",
    SPACES_IMPORT_TEXT                      : "Importe todos los productos al espacio <b>{0}</b> con un orden desde un archivo.",
    SPACES_IMPORT_FILE                      : "• El formato del archivo debe ser Excel (.xlsx).<br>• Los datos deben estar en la primer hoja del excel.<br>• La primer fila debe contener el encabezado de las columnas y luego tener una fila por producto.<br>• La primer fila debe contener el código de Benvida y la segunda columna el nombre.<br>• Intente no usar estilos.",
    SPACES_IMPORT_VALID                     : "Se encontró un total de <b>{0}</b> productos y todos son válidos.",
    SPACES_IMPORT_INVALID                   : "Se encontró un total de <b>{0}</b> productos donde hay <b>{1}</b> que tienen un posible error:",
    SPACES_IMPORT_CONTINUE                  : "¿Desea continuar con la importación?",
    SPACES_DELETE_TITLE                     : "Eliminar espacio",
    SPACES_DELETE_TEXT                      : "¿Realmente desea eliminar el espacio <b>{0}</b>?",
    SPACES_FILE_NAME                        : "Productos espacio.xlsx",
    SPACES_ALL_PRODUCTS                     : "Para todos los productos",
    SPACES_NONE_AVAILABLE                   : "No hay espacios disponibles.",
    SPACES_ERROR_EXISTS                     : "No existe el espacio indicado.",
    SPACES_ERROR_NAME                       : "Debe completar el nombre del espacio.",
    SPACES_ERROR_IMAGE                      : "Debe completar la imagen del espacio.",
    SPACES_ERROR_STATUS                     : "Debe completar el estado del espacio.",
    SPACES_SUCCESS_CREATE                   : "El espacio se creó correctamente.",
    SPACES_SUCCESS_EDIT                     : "El espacio se editó correctamente.",
    SPACES_SUCCESS_IMPORT                   : "Los productos se importaron correctamente.",
    SPACES_SUCCESS_DELETE                   : "El espacio se eliminó correctamente.",

    // Catalog: Banners
    BANNERS_NAME                            : "Banners",
    BANNERS_SINGULAR                        : "Banner",
    BANNERS_CREATE_TITLE                    : "Crear banner",
    BANNERS_EDIT_TITLE                      : "Editar banner",
    BANNERS_DELETE_TITLE                    : "Eliminar banner",
    BANNERS_DELETE_TEXT                     : "¿Realmente desea eliminar el banner <b>{0}</b>?",
    BANNERS_LINK_TYPE                       : "Tipo de vínculo",
    BANNERS_NONE_AVAILABLE                  : "No hay banners disponibles.",
    BANNERS_ERROR_EXISTS                    : "No existe el banner indicado.",
    BANNERS_ERROR_NAME                      : "Debe completar el nombre del banner.",
    BANNERS_ERROR_IMAGE                     : "Debe completar la imagen del banner.",
    BANNERS_ERROR_LINK_TYPE_EMPTY           : "Debe completar el tipo de vínculo del banner.",
    BANNERS_ERROR_LINK_TYPE_INVALID         : "El tipo de vínculo del banner es inválido.",
    BANNERS_ERROR_SPACE                     : "Debe completar el espacio del banner.",
    BANNERS_ERROR_URL                       : "Debe completar la URL del banner.",
    BANNERS_ERROR_STATUS                    : "Debe completar el estado del banner.",
    BANNERS_SUCCESS_CREATE                  : "El banner se creó correctamente.",
    BANNERS_SUCCESS_EDIT                    : "El banner se editó correctamente.",
    BANNERS_SUCCESS_DELETE                  : "El banner se eliminó correctamente.",



    // Medicine
    MEDICINES_NAME                          : "Medicamentos",
    MEDICINES_SINGULAR                      : "Medicamento",
    MEDICINES_FILTER_TITLE                  : "Filtrar medicamentos",
    MEDICINES_VIEW_TITLE                    : "Ver medicamento",
    MEDICINES_CREATE_TITLE                  : "Crear medicamento",
    MEDICINES_EDIT_TITLE                    : "Editar medicamento",
    MEDICINES_FILE_NAME                     : "Medicamentos.xlsx",
    MEDICINES_GENERAL                       : "Información del medicamento",
    MEDICINES_GTIN_CODE                     : "Código de GTIN",
    MEDICINES_GTIN_CODES                    : "Códigos de GTIN",
    MEDICINES_ADD_GTIN_CODE                 : "Agregar código de GTIN",
    MEDICINES_TROQUEL                       : "Troquel",
    MEDICINES_SNOMED                        : "Codificación SNOMED",
    MEDICINES_PRESENTATION                  : "Presentación",
    MEDICINES_UNITS                         : "Unidades",
    MEDICINES_PAMI_PERCENT                  : "Porcentaje PAMI ambulatorio",
    MEDICINES_PAMI_PRICE                    : "Precio venta PAMI",
    MEDICINES_IOMA_PRICE                    : "Monto fijo IOMA",
    MEDICINES_IOMA_NORMATIZADO              : "Uso normatizado IOMA",
    MEDICINES_FOR_SIFAR                     : "Cobertura SIFAR",
    MEDICINES_HAS_TAX                       : "Tiene IVA",
    MEDICINES_HAS_GRAVAMEN                  : "Tiene gravamen",
    MEDICINES_IS_IMPORTED                   : "Es importado",
    MEDICINES_TO_DELETE                     : "Para eliminar",
    MEDICINES_DRUGS                         : "Drogas",
    MEDICINES_DRUGS_TITLE                   : "Droga {0}",
    MEDICINES_ADD_DRUG                      : "Agregar droga",
    MEDICINES_NONE_AVAILABLE                : "No hay medicamentos disponibles.",
    MEDICINES_ERROR_EXISTS                  : "No existe el medicamento indicado.",
    MEDICINES_ERROR_NAME                    : "Debe completar el nombre del medicamento.",
    MEDICINES_ERROR_GTIN_CODES              : "Los códigos GTIN deben ser numéricos.",
    MEDICINES_ERROR_TROQUEL                 : "El troquel debe ser numérico.",
    MEDICINES_ERROR_SNOMED                  : "La codificación SNOMED debe ser numérica.",
    MEDICINES_ERROR_PRICE                   : "Debe indicar un precio válido.",
    MEDICINES_ERROR_VALIDITY                : "Debe indicar una fecha de validación válida.",
    MEDICINES_ERROR_PAMI_PERCENT            : "El porcentaje PAMI debe ser un valor entre 0 y 100.",
    MEDICINES_ERROR_PAMI_PRICE              : "El precio PAMI debe ser un número mayor o igual a 0.",
    MEDICINES_ERROR_IOMA_PRICE              : "El precio IOMA debe ser un número mayor o igual a 0.",
    MEDICINES_SUCCESS_CREATE                : "El medicamento se creó correctamente.",
    MEDICINES_SUCCESS_EDIT                  : "El medicamento se editó correctamente.",
    MEDICINES_SUCCESS_DELETE                : "El medicamento se eliminó correctamente.",

    // Medicine: Laboratories
    LABORATORIES_NAME                       : "Laboratorios",
    LABORATORIES_SINGULAR                   : "Laboratorio",
    LABORATORIES_FILTER_TITLE               : "Filtrar laboratorios",
    LABORATORIES_CREATE_TITLE               : "Crear laboratorio",
    LABORATORIES_EDIT_TITLE                 : "Editar laboratorio",
    LABORATORIES_DELETE_TITLE               : "Eliminar laboratorio",
    LABORATORIES_DELETE_TEXT                : "¿Realmente desea eliminar el laboratorio <b>{0}</b>?",
    LABORATORIES_NONE_AVAILABLE             : "No hay laboratorios disponibles.",
    LABORATORIES_ERROR_EXISTS               : "No existe el laboratorio indicado.",
    LABORATORIES_SUCCESS_CREATE             : "El laboratorio se creó correctamente.",
    LABORATORIES_SUCCESS_EDIT               : "El laboratorio se editó correctamente.",
    LABORATORIES_SUCCESS_DELETE             : "El laboratorio se eliminó correctamente.",

    // Medicine: Mono Drug
    MONO_DRUGS_NAME                         : "Mono drogas",
    MONO_DRUGS_SINGULAR                     : "Mono droga",
    MONO_DRUGS_FILTER_TITLE                 : "Filtrar mono drogas",
    MONO_DRUGS_CREATE_TITLE                 : "Crear mono droga",
    MONO_DRUGS_EDIT_TITLE                   : "Editar mono droga",
    MONO_DRUGS_DELETE_TITLE                 : "Eliminar mono droga",
    MONO_DRUGS_DELETE_TEXT                  : "¿Realmente desea eliminar la mono droga <b>{0}</b>?",
    MONO_DRUGS_NONE_AVAILABLE               : "No hay mono drogas disponibles.",
    MONO_DRUGS_ERROR_EXISTS                 : "No existe la mono droga indicada.",
    MONO_DRUGS_SUCCESS_CREATE               : "La mono droga se creó correctamente.",
    MONO_DRUGS_SUCCESS_EDIT                 : "La mono droga se editó correctamente.",
    MONO_DRUGS_SUCCESS_DELETE               : "La mono droga se eliminó correctamente.",

    // Medicine: Drug Types
    DRUG_TYPES_NAME                         : "Tipos de droga",
    DRUG_TYPES_SINGULAR                     : "Tipo de droga",
    DRUG_TYPES_FILTER_TITLE                 : "Filtrar tipos de drogas",
    DRUG_TYPES_CREATE_TITLE                 : "Crear tipo de droga",
    DRUG_TYPES_EDIT_TITLE                   : "Editar tipo de droga",
    DRUG_TYPES_DELETE_TITLE                 : "Eliminar tipo de droga",
    DRUG_TYPES_DELETE_TEXT                  : "¿Realmente desea eliminar el tipo de droga <b>{0}</b>?",
    DRUG_TYPES_NONE_AVAILABLE               : "No hay tipos de drogas disponibles.",
    DRUG_TYPES_ERROR_EXISTS                 : "No existe el tipo de droga indicado.",
    DRUG_TYPES_SUCCESS_CREATE               : "El tipo de droga se creó correctamente.",
    DRUG_TYPES_SUCCESS_EDIT                 : "El tipo de droga se editó correctamente.",
    DRUG_TYPES_SUCCESS_DELETE               : "El tipo de droga se eliminó correctamente.",

    // Medicine: Sale Types
    SALE_TYPES_NAME                         : "Tipos de venta",
    SALE_TYPES_SINGULAR                     : "Tipo de venta",
    SALE_TYPES_FILTER_TITLE                 : "Filtrar tipos de ventas",
    SALE_TYPES_CREATE_TITLE                 : "Crear tipo de venta",
    SALE_TYPES_EDIT_TITLE                   : "Editar tipo de venta",
    SALE_TYPES_DELETE_TITLE                 : "Eliminar tipo de venta",
    SALE_TYPES_DELETE_TEXT                  : "¿Realmente desea eliminar el tipo de venta <b>{0}</b>?",
    SALE_TYPES_NONE_AVAILABLE               : "No hay tipos de ventas disponibles.",
    SALE_TYPES_ERROR_EXISTS                 : "No existe el tipo de venta indicado.",
    SALE_TYPES_SUCCESS_CREATE               : "El tipo de venta se creó correctamente.",
    SALE_TYPES_SUCCESS_EDIT                 : "El tipo de venta se editó correctamente.",
    SALE_TYPES_SUCCESS_DELETE               : "El tipo de venta se eliminó correctamente.",

    // Medicine: Health Types
    HEALTH_TYPES_NAME                       : "Tipos de salud",
    HEALTH_TYPES_SINGULAR                   : "Tipo de salud",
    HEALTH_TYPES_FILTER_TITLE               : "Filtrar tipos de salud",
    HEALTH_TYPES_CREATE_TITLE               : "Crear tipo de salud",
    HEALTH_TYPES_EDIT_TITLE                 : "Editar tipo de salud",
    HEALTH_TYPES_DELETE_TITLE               : "Eliminar tipo de salud",
    HEALTH_TYPES_DELETE_TEXT                : "¿Realmente desea eliminar el tipo de salud <b>{0}</b>?",
    HEALTH_TYPES_NONE_AVAILABLE             : "No hay tipos de salud disponibles.",
    HEALTH_TYPES_ERROR_EXISTS               : "No existe el tipo de salud indicado.",
    HEALTH_TYPES_SUCCESS_CREATE             : "El tipo de salud se creó correctamente.",
    HEALTH_TYPES_SUCCESS_EDIT               : "El tipo de salud se editó correctamente.",
    HEALTH_TYPES_SUCCESS_DELETE             : "El tipo de salud se eliminó correctamente.",

    // Medicine: Size Types
    SIZE_TYPES_NAME                         : "Tipos de tamaño",
    SIZE_TYPES_SINGULAR                     : "Tipo de tamaño",
    SIZE_TYPES_FILTER_TITLE                 : "Filtrar tipos de tamaños",
    SIZE_TYPES_CREATE_TITLE                 : "Crear tipo de tamaño",
    SIZE_TYPES_EDIT_TITLE                   : "Editar tipo de tamaño",
    SIZE_TYPES_DELETE_TITLE                 : "Eliminar tipo de tamaño",
    SIZE_TYPES_DELETE_TEXT                  : "¿Realmente desea eliminar el tipo de tamaño <b>{0}</b>?",
    SIZE_TYPES_NONE_AVAILABLE               : "No hay tipos de tamaños disponibles.",
    SIZE_TYPES_ERROR_EXISTS                 : "No existe el tipo de tamaño indicado.",
    SIZE_TYPES_SUCCESS_CREATE               : "El tipo de tamaño se creó correctamente.",
    SIZE_TYPES_SUCCESS_EDIT                 : "El tipo de tamaño se editó correctamente.",
    SIZE_TYPES_SUCCESS_DELETE               : "El tipo de tamaño se eliminó correctamente.",

    // Medicine: Shape Types
    SHAPE_TYPES_NAME                        : "Tipos de forma",
    SHAPE_TYPES_SINGULAR                    : "Tipo de forma",
    SHAPE_TYPES_FILTER_TITLE                : "Filtrar tipos de formas",
    SHAPE_TYPES_CREATE_TITLE                : "Crear tipo de forma",
    SHAPE_TYPES_EDIT_TITLE                  : "Editar tipo de forma",
    SHAPE_TYPES_DELETE_TITLE                : "Eliminar tipo de forma",
    SHAPE_TYPES_DELETE_TEXT                 : "¿Realmente desea eliminar el tipo de forma <b>{0}</b>?",
    SHAPE_TYPES_NONE_AVAILABLE              : "No hay tipos de formas disponibles.",
    SHAPE_TYPES_ERROR_EXISTS                : "No existe el tipo de forma indicado.",
    SHAPE_TYPES_SUCCESS_CREATE              : "El tipo de forma se creó correctamente.",
    SHAPE_TYPES_SUCCESS_EDIT                : "El tipo de forma se editó correctamente.",
    SHAPE_TYPES_SUCCESS_DELETE              : "El tipo de forma se eliminó correctamente.",

    // Medicine: Potency Types
    POTENCY_TYPES_NAME                      : "Tipos de potencia",
    POTENCY_TYPES_SINGULAR                  : "Tipo de potencia",
    POTENCY_TYPES_FILTER_TITLE              : "Filtrar tipos de potencias",
    POTENCY_TYPES_CREATE_TITLE              : "Crear tipo de potencia",
    POTENCY_TYPES_EDIT_TITLE                : "Editar tipo de potencia",
    POTENCY_TYPES_DELETE_TITLE              : "Eliminar tipo de potencia",
    POTENCY_TYPES_DELETE_TEXT               : "¿Realmente desea eliminar el tipo de potencia <b>{0}</b>?",
    POTENCY_TYPES_NONE_AVAILABLE            : "No hay tipos de potencias disponibles.",
    POTENCY_TYPES_ERROR_EXISTS              : "No existe el tipo de potencia indicado.",
    POTENCY_TYPES_SUCCESS_CREATE            : "El tipo de potencia se creó correctamente.",
    POTENCY_TYPES_SUCCESS_EDIT              : "El tipo de potencia se editó correctamente.",
    POTENCY_TYPES_SUCCESS_DELETE            : "El tipo de potencia se eliminó correctamente.",

    // Medicine: Unit Types
    UNIT_TYPES_NAME                         : "Tipos de unidad",
    UNIT_TYPES_SINGULAR                     : "Tipo de unidad",
    UNIT_TYPES_FILTER_TITLE                 : "Filtrar tipos de unidad",
    UNIT_TYPES_CREATE_TITLE                 : "Crear tipo de unidad",
    UNIT_TYPES_EDIT_TITLE                   : "Editar tipo de unidad",
    UNIT_TYPES_DELETE_TITLE                 : "Eliminar tipo de unidad",
    UNIT_TYPES_DELETE_TEXT                  : "¿Realmente desea eliminar el tipo de unidad <b>{0}</b>?",
    UNIT_TYPES_NONE_AVAILABLE               : "No hay tipos de unidades disponibles.",
    UNIT_TYPES_ERROR_EXISTS                 : "No existe el tipo de unidad indicado.",
    UNIT_TYPES_SUCCESS_CREATE               : "El tipo de unidad se creó correctamente.",
    UNIT_TYPES_SUCCESS_EDIT                 : "El tipo de unidad se editó correctamente.",
    UNIT_TYPES_SUCCESS_DELETE               : "El tipo de unidad se eliminó correctamente.",

    // Medicine: Administration Routes
    ADMIN_ROUTES_NAME                       : "Vías de administración",
    ADMIN_ROUTES_SINGULAR                   : "Vía de administración",
    ADMIN_ROUTES_FILTER_TITLE               : "Filtrar vías de administración",
    ADMIN_ROUTES_CREATE_TITLE               : "Crear vía de administración",
    ADMIN_ROUTES_EDIT_TITLE                 : "Editar vía de administración",
    ADMIN_ROUTES_DELETE_TITLE               : "Eliminar vía de administración",
    ADMIN_ROUTES_DELETE_TEXT                : "¿Realmente desea eliminar la vía de administración <b>{0}</b>?",
    ADMIN_ROUTES_NONE_AVAILABLE             : "No hay vías de administración disponibles.",
    ADMIN_ROUTES_ERROR_EXISTS               : "No existe la vía de administración indicada.",
    ADMIN_ROUTES_SUCCESS_CREATE             : "La vía de administración se creó correctamente.",
    ADMIN_ROUTES_SUCCESS_EDIT               : "La vía de administración se editó correctamente.",
    ADMIN_ROUTES_SUCCESS_DELETE             : "La vía de administración se eliminó correctamente.",

    // Medicine: Pharma Actions
    PHARMA_ACTIONS_NAME                     : "Acciones farmacoterapéuticas",
    PHARMA_ACTIONS_SINGULAR                 : "Acción farmacoterapéutica",
    PHARMA_ACTIONS_FILTER_TITLE             : "Filtrar acciones farmacoterapéuticas",
    PHARMA_ACTIONS_CREATE_TITLE             : "Crear acción farmacoterapéutica",
    PHARMA_ACTIONS_EDIT_TITLE               : "Editar acción farmacoterapéutica",
    PHARMA_ACTIONS_DELETE_TITLE             : "Eliminar acción farmacoterapéutica",
    PHARMA_ACTIONS_DELETE_TEXT              : "¿Realmente desea eliminar la acción farmacoterapéutica <b>{0}</b>?",
    PHARMA_ACTIONS_NONE_AVAILABLE           : "No hay acciones farmacoterapéuticas disponibles.",
    PHARMA_ACTIONS_ERROR_EXISTS             : "No existe la acción farmacoterapéutica indicada.",
    PHARMA_ACTIONS_SUCCESS_CREATE           : "La acción farmacoterapéutica se creó correctamente.",
    PHARMA_ACTIONS_SUCCESS_EDIT             : "La acción farmacoterapéutica se editó correctamente.",
    PHARMA_ACTIONS_SUCCESS_DELETE           : "La acción farmacoterapéutica se eliminó correctamente.",



    // Change: Products
    CHANGE_PRODUCTS_NAME                    : "Cambios de productos",
    CHANGE_PRODUCTS_EDIT_TITLE              : "Editar cambio de producto",
    CHANGE_PRODUCTS_PROCESS_TITLE           : "Procesar cambio de producto",
    CHANGE_PRODUCTS_VALIDATE_TITLE          : "Validar cambio de producto",
    CHANGE_PRODUCTS_PROCESS                 : "Para procesar",
    CHANGE_PRODUCTS_VALIDATE                : "Para validar",
    CHANGE_PRODUCTS_CURRENT_VALUES          : "Valores existentes",
    CHANGE_PRODUCTS_UPDATED_VALUES          : "Valores a procesar",
    CHANGE_PRODUCTS_PROCESSED_AT            : "Procesado el",
    CHANGE_PRODUCTS_PROCESSED_BY            : "Procesado por",
    CHANGE_PRODUCTS_NONE_AVAILABLE          : "No hay cambios de productos disponibles.",
    CHANGE_PRODUCTS_ERROR_USING             : "Otro usuario esta procesando el cambio de producto indicado.",
    CHANGE_PRODUCTS_ERROR_EXISTS            : "No existe el cambio de producto indicado.",
    CHANGE_PRODUCTS_SUCCESS_PROCESS         : "El cambio de producto se procesó correctamente.",
    CHANGE_PRODUCTS_SUCCESS_VALIDATE        : "El cambio de producto se validó correctamente.",

    // Change: Catalog
    CHANGE_CATALOGS_NAME                    : "Cambios de catálogo",
    CHANGE_CATALOGS_VIEW_TITLE              : "Ver cambio de catálogo",
    CHANGE_CATALOGS_PROCESS_TITLE           : "Procesar cambio",
    CHANGE_CATALOGS_VALIDATE_TITLE          : "Validar cambio",
    CHANGE_CATALOGS_MULTI_VALIDATE_TITLE    : "Validar cambios",
    CHANGE_CATALOGS_MULTI_VALIDATE_SINGULAR : "¿Realmente desea validar <b>{0}</b> cambio?",
    CHANGE_CATALOGS_MULTI_VALIDATE_PLURAL   : "¿Realmente desea validar <b>{0}</b> cambios?",
    CHANGE_CATALOGS_PROCESS                 : "Para procesar",
    CHANGE_CATALOGS_VALIDATE                : "Para validar",
    CHANGE_CATALOGS_COMPLETE                : "Completados",
    CHANGE_CATALOGS_MODULE                  : "Módulo",
    CHANGE_CATALOGS_PROCESSED_AT            : "Procesado el",
    CHANGE_CATALOGS_PROCESSED_BY            : "Procesado por",
    CHANGE_CATALOGS_VALIDATED_AT            : "Validado el",
    CHANGE_CATALOGS_VALIDATED_BY            : "Validado por",
    CHANGE_CATALOGS_OLD_ALFABETA            : "Viejo Alfabeta",
    CHANGE_CATALOGS_NEW_ALFABETA            : "Nuevo Alfabeta",
    CHANGE_CATALOGS_OLD_NAME_ALFABETA       : "Valor viejo Alfabeta",
    CHANGE_CATALOGS_NEW_NAME_ALFABETA       : "Valor nuevo Alfabeta",
    CHANGE_CATALOGS_OLD_BENVIDA             : "Viejo Benvida",
    CHANGE_CATALOGS_NEW_BENVIDA             : "Nuevo Benvida",
    CHANGE_CATALOGS_OLD_NAME_BENVIDA        : "Valor viejo Benvida",
    CHANGE_CATALOGS_NEW_NAME_BENVIDA        : "Valor nuevo Benvida",
    CHANGE_CATALOGS_NONE_AVAILABLE          : "No hay cambios de catálogo disponibles.",
    CHANGE_CATALOGS_ERROR_USING             : "Otro usuario esta procesando el cambio indicado.",
    CHANGE_CATALOGS_ERROR_EXISTS            : "No existe el cambio indicado.",
    CHANGE_CATALOGS_SUCCESS_PROCESS         : "El cambio se procesó correctamente.",
    CHANGE_CATALOGS_SUCCESS_VALIDATE        : "El cambio se validó correctamente.",
    CHANGE_CATALOGS_SUCCESS_MULTI_VALIDATE  : "Los cambios se validaron correctamente.",

    // Change: Updates
    CHANGE_UPDATES_NAME                     : "Cambios de actualizaciones",
    CHANGE_UPDATES_NONE_AVAILABLE           : "No hay cambios de actualizaciones disponibles.",



    // Coverages
    COVERAGES_NAME                          : "Obras sociales",
    COVERAGES_SINGULAR                      : "Obra social",
    COVERAGES_ADD_TITLE                     : "Agregar obra social",
    COVERAGES_CREATE_TITLE                  : "Crear obra social",
    COVERAGES_EDIT_TITLE                    : "Editar obra social",
    COVERAGES_DELETE_TITLE                  : "Eliminar obra social",
    COVERAGES_DELETE_TEXT                   : "¿Realmente desea eliminar la obra social <b>{0}</b>?",
    COVERAGES_OTHER                         : "Otra",
    COVERAGES_DEFAULTS                      : "<b>Predeterminado:</b> {0}",
    COVERAGES_ALL_RETIREMENTS               : "Todos los tipos de entrega",
    COVERAGES_ALL_GATEWAYS                  : "Todas las pasarelas de pago",
    COVERAGES_DEFAULT_RETIREMENTS           : "Usar tipos de entrega predeterminados",
    COVERAGES_DEFAULT_GATEWAYS              : "Usar pasarelas de pago predeterminadas",
    COVERAGES_APPROVE_MINUTES               : "Minutos para aprobar / rechazar",
    COVERAGES_PAY_MINUTES                   : "Minutos para pagar",
    COVERAGES_EMPTY_MINUTES                 : "Dejar vacío para usar el tiempo por defecto.",
    COVERAGES_REMOVE_RETIRE_EXPIRATION      : "No agregar vencimiento de retiro",
    COVERAGES_NO_PLAN_REQUIRED              : "No requiere plan",
    COVERAGES_REQ_AFFILIATE_DNI             : "Requiere DNI de afiliado",
    COVERAGES_NONE_AVAILABLE                : "No hay obra sociales disponibles.",
    COVERAGES_ERROR_EXISTS                  : "No existe la obra social indicada.",
    COVERAGES_ERROR_REPEATED                : "Ya existe la obra social indicada.",
    COVERAGES_ERROR_EMPTY                   : "Debe completar la obra social.",
    COVERAGES_ERROR_CODE_EMPTY              : "Debe completar el código de la obra social.",
    COVERAGES_ERROR_CODE_REPEATED           : "No se deben repetir los códigos de la obra social.",
    COVERAGES_ERROR_NAME                    : "Debe completar el nombre de la obra social.",
    COVERAGES_ERROR_RETIREMENT_TYPES        : "Debe completar indicar un tipo de entrega disponible.",
    COVERAGES_ERROR_GATEWAYS                : "Debe completar indicar una pasarela de pago disponible.",
    COVERAGES_ERROR_APPROVE_MINUTES         : "Los minutos para aprobar / rechazar deben ser un valor numérico.",
    COVERAGES_ERROR_PAY_MINUTES             : "Los minutos para pagar deben ser un valor numérico.",
    COVERAGES_ERROR_STATUS                  : "Debe completar el estado de la obra social.",
    COVERAGES_SUCCESS_CREATE                : "La obra social se creó correctamente.",
    COVERAGES_SUCCESS_EDIT                  : "La obra social se editó correctamente.",
    COVERAGES_SUCCESS_DELETE                : "La obra social se eliminó correctamente.",

    // Coverages: Types
    COVERAGE_TYPES_NAME                     : "Tipos de coberturas",
    COVERAGE_TYPES_SINGULAR                 : "Tipo de cobertura",
    COVERAGE_TYPES_CREATE_TITLE             : "Crear tipo de cobertura",
    COVERAGE_TYPES_EDIT_TITLE               : "Editar tipo de cobertura",
    COVERAGE_TYPES_DELETE_TITLE             : "Eliminar tipo de cobertura",
    COVERAGE_TYPES_DELETE_TEXT              : "¿Realmente desea eliminar el tipo de cobertura <b>{0}</b>?",
    COVERAGE_TYPES_REQ_COVERAGE             : "Requiere cobertura",
    COVERAGE_TYPES_NONE_AVAILABLE           : "No hay tipos de coberturas disponibles.",
    COVERAGE_TYPES_ERROR_EXISTS             : "No existe el tipo de cobertura indicado.",
    COVERAGE_TYPES_ERROR_CODE_EMPTY         : "Debe completar el código del tipo de cobertura.",
    COVERAGE_TYPES_ERROR_CODE_REPEATED      : "No se deben repetir los códigos de los tipos de coberturas.",
    COVERAGE_TYPES_ERROR_NAME               : "Debe completar el nombre del tipo de cobertura.",
    COVERAGE_TYPES_SUCCESS_CREATE           : "El tipo de cobertura se creó correctamente.",
    COVERAGE_TYPES_SUCCESS_EDIT             : "El tipo de cobertura se editó correctamente.",
    COVERAGE_TYPES_SUCCESS_DELETE           : "El tipo de cobertura se eliminó correctamente.",



    // Logistics
    LOGISTICS_NAME                          : "Logísticas",
    LOGISTICS_SINGULAR                      : "Logística",
    LOGISTICS_EDIT_TITLE                    : "Editar logística",
    LOGISTICS_EDIT_TEXT                     : "Editar: {0}",
    LOGISTICS_FEE                           : "Fee de logística",
    LOGISTICS_USE_FEE                       : "Usar este fee",
    LOGISTICS_MIN_KM                        : "Desde KM",
    LOGISTICS_MAX_KM                        : "Hasta KM",
    LOGISTICS_NONE_AVAILABLE                : "No hay logísticas disponibles.",
    LOGISTICS_ERROR_EXISTS                  : "No existe la logística indicada.",
    LOGISTICS_ERROR_MIN_KM                  : "El KM desde debe ser un valor numérico.",
    LOGISTICS_ERROR_MAX_KM                  : "El KM hasta debe ser un valor numérico.",
    LOGISTICS_SUCCESS_EDIT                  : "La logística se editó correctamente.",
    LOGISTICS_SUCCESS_EDIT_ALL              : "Las logísticas se editaron correctamente.",

    // Logistics: Reasons
    LOGISTIC_REASONS_NAME                   : "Errores de logística",
    LOGISTIC_REASONS_SINGULAR               : "Error de logística",
    LOGISTIC_REASONS_EDIT_TITLE             : "Editar error de logística",
    LOGISTIC_REASONS_PHARMACY               : "Motivo para el farmacéutico",
    LOGISTIC_REASONS_CLIENT                 : "Motivo para el cliente",
    LOGISTIC_REASONS_NONE_AVAILABLE         : "No hay errores de logística disponibles.",
    LOGISTIC_REASONS_ERROR_EXISTS           : "No existe el error de logística indicado.",
    LOGISTIC_REASONS_ERROR_NAME             : "Debe completar el nombre del error de logística.",
    LOGISTIC_REASONS_ERROR_PHARMACY         : "Debe completar el motivo para el farmacéutico.",
    LOGISTIC_REASONS_SUCCESS_EDIT           : "El error de logística se editó correctamente.",

    // Logistics: Retirement Types
    RETIREMENT_TYPES_NAME                   : "Tipos de entregas",
    RETIREMENT_TYPES_SINGULAR               : "Tipo de entrega",
    RETIREMENT_TYPES_EDIT_TITLE             : "Editar tipo de entrega",
    RETIREMENT_TYPES_NONE_AVAILABLE         : "No hay tipos de entregas disponibles.",
    RETIREMENT_TYPES_ERROR_EXISTS           : "No existe el tipo de entrega indicado.",
    RETIREMENT_TYPES_ERROR_NAME             : "Debe completar el nombre del tipo de entrega.",
    RETIREMENT_TYPES_SUCCESS_EDIT           : "El tipo de entrega se editó correctamente.",



    // Transactions
    TRANSACTIONS_NAME                       : "Transacciones",
    TRANSACTIONS_SINGULAR                   : "Transacción",
    TRANSACTIONS_VIEW_TITLE                 : "Ver transacción",
    TRANSACTIONS_FILTER_TITLE               : "Filtrar transacciones",
    TRANSACTIONS_FILE_NAME                  : "Transacciones.xlsx",
    TRANSACTIONS_NONE_AVAILABLE             : "No hay transacciones disponibles.",

    // Transactions: Transaction Types
    TRANSACTION_TYPES_NAME                  : "Tipos de transacciones",
    TRANSACTION_TYPES_SINGULAR              : "Tipo de transacción",
    TRANSACTION_TYPES_CREATE_TITLE          : "Crear tipo de transacción",
    TRANSACTION_TYPES_EDIT_TITLE            : "Editar tipo de transacción",
    TRANSACTION_TYPES_DELETE_TITLE          : "Eliminar tipo de transacción",
    TRANSACTION_TYPES_DELETE_TEXT           : "¿Realmente desea eliminar el tipo de transacción <b>{0}</b>?",
    TRANSACTION_TYPES_NONE_AVAILABLE        : "No hay tipos de transacciones disponibles.",
    TRANSACTION_TYPES_ERROR_EXISTS          : "No existe el tipo de transacción indicado.",
    TRANSACTION_TYPES_ERROR_NAME            : "Debe completar el nombre del tipo de transacción.",
    TRANSACTION_TYPES_ERROR_CODE_EMPTY      : "Debe indicar el código del tipo de transacción.",
    TRANSACTION_TYPES_ERROR_CODE_INVALID    : "El código debe contener solo letras.",
    TRANSACTION_TYPES_ERROR_CODE_EXISTS     : "Ya existe otro tipo de transacción con ese código.",
    TRANSACTION_TYPES_SUCCESS_CREATE        : "El tipo de transacción se creó correctamente.",
    TRANSACTION_TYPES_SUCCESS_EDIT          : "El tipo de transacción se editó correctamente.",
    TRANSACTION_TYPES_SUCCESS_DELETE        : "El tipo de transacción se eliminó correctamente.",

    // Transactions: Gateways
    GATEWAYS_NAME                           : "Pasarelas de pago",
    GATEWAYS_SINGULAR                       : "Pasarela de pago",
    GATEWAYS_SHORT                          : "Pasarelas",
    GATEWAYS_FILTER_TITLE                   : "Filtrar pasarelas de pago",
    GATEWAYS_EDIT_TITLE                     : "Editar pasarela de pago",
    GATEWAYS_EDIT_TEXT                      : "Editar: {0}",
    GATEWAYS_PHARMACY                       : "Pago en sucursal",
    GATEWAYS_MERCADOPAGO                    : "MercadoPago",
    GATEWAYS_FEE                            : "Fee de pasarela",
    GATEWAYS_USER_ID                        : "ID del usuario",
    GATEWAYS_IS_LINKED                      : "Está vinculada",
    GATEWAYS_LINK_ACCOUNT                   : "Para recibir pagos vincula tu cuenta de Mercado Pago",
    GATEWAYS_LINK_BUTTON                    : "Vincular cuenta",
    GATEWAYS_LINK_ALERT                     : "*Recordá que se vinculará la cuenta de mercado pago que tengas ingresada en el momento. Por eso, recomendamos que la revises con anterioridad.",
    GATEWAYS_NO_PROVIDER                    : "No tiene datos del proveedor",
    GATEWAYS_NONE_AVAILABLE                 : "No hay pasarelas de pago disponibles.",
    GATEWAYS_ERROR_EXISTS                   : "No existe la pasarela de pago indicada.",
    GATEWAYS_ERROR_NAME                     : "Debe completar el nombre de la pasarela de pago.",
    GATEWAYS_SUCCESS_EDIT                   : "La pasarela de pago se editó correctamente.",
    GATEWAYS_SUCCESS_EDIT_ALL               : "Las pasarelas de pago se editaron correctamente.",

    // Transactions: Payment Types
    PAYMENT_TYPES_NAME                      : "Tipos de pagos",
    PAYMENT_TYPES_SINGULAR                  : "Tipo de pago",
    PAYMENT_TYPES_CREATE_TITLE              : "Crear tipo de pago",
    PAYMENT_TYPES_EDIT_TITLE                : "Editar tipo de pago",
    PAYMENT_TYPES_DELETE_TITLE              : "Eliminar tipo de pago",
    PAYMENT_TYPES_DELETE_TEXT               : "¿Realmente desea eliminar el tipo de pago <b>{0}</b>?",
    PAYMENT_TYPES_NONE_AVAILABLE            : "No hay tipos de pagos disponibles.",
    PAYMENT_TYPES_ERROR_EXISTS              : "No existe el tipo de pago indicado.",
    PAYMENT_TYPES_ERROR_NAME                : "Debe completar el nombre del tipo de pago.",
    PAYMENT_TYPES_ERROR_CODE_EMPTY          : "Debe indicar el código del tipo de pago.",
    PAYMENT_TYPES_ERROR_CODE_INVALID        : "El código debe contener solo letras.",
    PAYMENT_TYPES_ERROR_CODE_EXISTS         : "Ya existe otro tipo de pago con ese código.",
    PAYMENT_TYPES_SUCCESS_CREATE            : "El tipo de pago se creó correctamente.",
    PAYMENT_TYPES_SUCCESS_EDIT              : "El tipo de pago se editó correctamente.",
    PAYMENT_TYPES_SUCCESS_DELETE            : "El tipo de pago se eliminó correctamente.",

    // Transactions: Payment Methods
    PAYMENT_METHODS_NAME                    : "Métodos de pagos",
    PAYMENT_METHODS_SINGULAR                : "Método de pago",
    PAYMENT_METHODS_CREATE_TITLE            : "Crear método de pago",
    PAYMENT_METHODS_EDIT_TITLE              : "Editar método de pago",
    PAYMENT_METHODS_DELETE_TITLE            : "Eliminar método de pago",
    PAYMENT_METHODS_DELETE_TEXT             : "¿Realmente desea eliminar el método de pago <b>{0}</b>?",
    PAYMENT_METHODS_NONE_AVAILABLE          : "No hay métodos de pagos disponibles.",
    PAYMENT_METHODS_ERROR_EXISTS            : "No existe el método de pago indicado.",
    PAYMENT_METHODS_ERROR_NAME              : "Debe completar el nombre del método de pago.",
    PAYMENT_METHODS_ERROR_CODE_EMPTY        : "Debe indicar el código del método de pago.",
    PAYMENT_METHODS_ERROR_CODE_INVALID      : "El código debe contener solo letras.",
    PAYMENT_METHODS_ERROR_CODE_EXISTS       : "Ya existe otro método de pago con ese código.",
    PAYMENT_METHODS_SUCCESS_CREATE          : "El método de pago se creó correctamente.",
    PAYMENT_METHODS_SUCCESS_EDIT            : "El método de pago se editó correctamente.",
    PAYMENT_METHODS_SUCCESS_DELETE          : "El método de pago se eliminó correctamente.",

    // Transactions: Card Types
    CARD_TYPES_NAME                         : "Tipos de tarjetas",
    CARD_TYPES_SINGULAR                     : "Tipo de tarjeta",
    CARD_TYPES_CREATE_TITLE                 : "Crear tipo de tarjeta",
    CARD_TYPES_EDIT_TITLE                   : "Editar tipo de tarjeta",
    CARD_TYPES_DELETE_TITLE                 : "Eliminar tipo de tarjeta",
    CARD_TYPES_DELETE_TEXT                  : "¿Realmente desea eliminar el tipo de tarjeta <b>{0}</b>?",
    CARD_TYPES_INITIAL_NUMBER               : "Número inicial",
    CARD_TYPES_NONE_AVAILABLE               : "No hay tipos de tarjetas disponibles.",
    CARD_TYPES_ERROR_EXISTS                 : "No existe el tipo de tarjeta indicado.",
    CARD_TYPES_ERROR_INITIAL_NUMBER_EMPTY   : "Debe indicar el número inicial del tipo de tarjeta.",
    CARD_TYPES_ERROR_INITIAL_NUMBER_INVALID : "El número inicial debe contener solo letras.",
    CARD_TYPES_ERROR_INITIAL_NUMBER_EXISTS  : "Ya existe otro tipo de tarjeta con ese número inicial.",
    CARD_TYPES_SUCCESS_CREATE               : "El tipo de tarjeta se creó correctamente.",
    CARD_TYPES_SUCCESS_EDIT                 : "El tipo de tarjeta se editó correctamente.",
    CARD_TYPES_SUCCESS_DELETE               : "El tipo de tarjeta se eliminó correctamente.",

    // Transactions: Banks
    BANKS_NAME                              : "Bancos",
    BANKS_SINGULAR                          : "Banco",
    BANKS_CREATE_TITLE                      : "Crear banco",
    BANKS_EDIT_TITLE                        : "Editar banco",
    BANKS_DELETE_TITLE                      : "Eliminar banco",
    BANKS_DELETE_TEXT                       : "¿Realmente desea eliminar el banco <b>{0}</b>?",
    BANKS_FANTASY_NAME                      : "Nombre de fantasía",
    BANKS_BANK                              : "Banco",
    BANKS_NONE_AVAILABLE                    : "No hay bancos disponibles.",
    BANKS_ERROR_EXISTS                      : "No existe el banco indicado.",
    BANKS_ERROR_NAME                        : "Debe indicar el nombre de fantasía o razón social.",
    BANKS_SUCCESS_CREATE                    : "El banco se creó correctamente.",
    BANKS_SUCCESS_EDIT                      : "El banco se editó correctamente.",
    BANKS_SUCCESS_DELETE                    : "El banco se eliminó correctamente.",

    // Transactions: Commissions
    COMMISSIONS_NAME                        : "Comisiones",
    COMMISSIONS_SINGULAR                    : "Comisión",
    COMMISSIONS_FILTER_TITLE                : "Filtrar comisiones",
    COMMISSIONS_CREATE_TITLE                : "Crear comisión",
    COMMISSIONS_EDIT_TITLE                  : "Editar comisión",
    COMMISSIONS_DELETE_TITLE                : "Eliminar comisión",
    COMMISSIONS_DELETE_TEXT                 : "¿Realmente desea eliminar la comisión indicada?",
    COMMISSIONS_COVERAGE                    : "Cobertura",
    COMMISSIONS_WITH_COVERAGE               : "Con cobertura",
    COMMISSIONS_USE_SUB_TOTAL               : "Aplicar al subtotal",
    COMMISSIONS_NONE_AVAILABLE              : "No hay comisiones disponibles.",
    COMMISSIONS_ERROR_EXISTS                : "No existe la comisión indicada.",
    COMMISSIONS_ERROR_REPEATED              : "Ya existe una comisión con los valores indicados.",
    COMMISSIONS_ERROR_PERCENT               : "Debe indicar el porcentaje de la comisión.",
    COMMISSIONS_SUCCESS_CREATE              : "La comisión se creó correctamente.",
    COMMISSIONS_SUCCESS_EDIT                : "La comisión se editó correctamente.",
    COMMISSIONS_SUCCESS_DELETE              : "La comisión se eliminó correctamente.",



    // Setup: Admins
    ADMINS_NAME                             : "Admins",
    ADMINS_CREATE_TITLE                     : "Crear admin",
    ADMINS_EDIT_TITLE                       : "Editar admin",
    ADMINS_DELETE_TITLE                     : "Eliminar admin",
    ADMINS_DELETE_TEXT                      : "¿Realmente desea eliminar el admin <b>{0}</b>?",
    ADMINS_NONE_AVAILABLE                   : "No hay admins disponibles.",
    ADMINS_ERROR_EDIT                       : "No puede editar el admin indicado.",
    ADMINS_ERROR_EXISTS                     : "No existe el admin indicado.",
    ADMINS_ERROR_STATUS                     : "Debe completar el estado del admin.",
    ADMINS_ERROR_DELETE                     : "No puede eliminar el admin indicado.",
    ADMINS_SUCCESS_CREATE                   : "El admin se creó correctamente.",
    ADMINS_SUCCESS_EDIT                     : "El admin se editó correctamente.",
    ADMINS_SUCCESS_DELETE                   : "El admin se eliminó correctamente.",

    // Setup: Objectives
    OBJECTIVES_NAME                         : "Objetivos",
    OBJECTIVES_CREATE_TITLE                 : "Crear objetivo",
    OBJECTIVES_EDIT_TITLE                   : "Editar objetivo",
    OBJECTIVES_DELETE_TITLE                 : "Eliminar objetivo",
    OBJECTIVES_DELETE_TEXT                  : "¿Realmente desea eliminar el objetivo <b>{0}</b>?",
    OBJECTIVES_MONTH_YEAR                   : "Mes y año",
    OBJECTIVES_GROSS_TOTAL                  : "Objetivo bruto",
    OBJECTIVES_SALES_TOTAL                  : "Objetivo pedidos",
    OBJECTIVES_NONE_AVAILABLE               : "No hay objetivos disponibles.",
    OBJECTIVES_ERROR_EXISTS                 : "No existe el objetivo indicado.",
    OBJECTIVES_ERROR_REPEATED               : "Ya existe un objetivo para el mes y año indicados.",
    OBJECTIVES_ERROR_MONTH                  : "Debe completar un mes válido para el objetivo.",
    OBJECTIVES_ERROR_YEAR                   : "Debe completar un año cómo un número mayor a cero.",
    OBJECTIVES_ERROR_GROSS_TOTAL            : "Debe completar un objetivo bruto cómo un número mayor a cero.",
    OBJECTIVES_ERROR_SALES_TOTAL            : "Debe completar un objetivo pedidos cómo un número mayor a cero.",
    OBJECTIVES_ERROR_DELETE                 : "No puede eliminar el objetivo indicado.",
    OBJECTIVES_SUCCESS_CREATE               : "El objetivo se creó correctamente.",
    OBJECTIVES_SUCCESS_EDIT                 : "El objetivo se editó correctamente.",
    OBJECTIVES_SUCCESS_DELETE               : "El objetivo se eliminó correctamente.",

    // Setup: Holidays
    HOLIDAYS_NAME                           : "Feriados",
    HOLIDAYS_CREATE_TITLE                   : "Crear feriado",
    HOLIDAYS_EDIT_TITLE                     : "Editar feriado",
    HOLIDAYS_DELETE_TITLE                   : "Eliminar feriado",
    HOLIDAYS_DELETE_TEXT                    : "¿Realmente desea eliminar el feriado <b>{0}</b>?",
    HOLIDAYS_NONE_AVAILABLE                 : "No hay feriados disponibles.",
    HOLIDAYS_ERROR_EXISTS                   : "No existe el feriado indicado.",
    HOLIDAYS_ERROR_NAME                     : "Debe indicar el nombre del feriado.",
    HOLIDAYS_ERROR_DAY                      : "Debe indicar el día del feriado.",
    HOLIDAYS_ERROR_MONTH                    : "Debe indicar un mes válido para el feriado.",
    HOLIDAYS_ERROR_YEAR                     : "Debe indicar un año válido para el feriado.",
    HOLIDAYS_ERROR_DELETE                   : "No puede eliminar el feriado indicado.",
    HOLIDAYS_SUCCESS_CREATE                 : "El feriado se creó correctamente.",
    HOLIDAYS_SUCCESS_EDIT                   : "El feriado se editó correctamente.",
    HOLIDAYS_SUCCESS_DELETE                 : "El feriado se eliminó correctamente.",

    // Setup: States
    STATES_NAME                             : "Estados",
    STATES_SINGULAR                         : "Estado",
    STATES_EDIT_TITLE                       : "Editar estado",
    STATES_NONE_AVAILABLE                   : "No hay estados disponibles.",
    STATES_ERROR_EXISTS                     : "No existe el estado indicado.",
    STATES_ERROR_NAME                       : "Debe completar el nombre del estado.",
    STATES_SUCCESS_EDIT                     : "El estado se editó correctamente.",

    // Setup: State Groups
    STATE_GROUPS_NAME                       : "Grupos de estados",
    STATE_GROUPS_SINGULAR                   : "Grupo de estados",
    STATE_GROUPS_CREATE_TITLE               : "Crear grupo de estados",
    STATE_GROUPS_EDIT_TITLE                 : "Editar grupo de estados",
    STATE_GROUPS_DELETE_TITLE               : "Eliminar grupo de estados",
    STATE_GROUPS_DELETE_TEXT                : "¿Realmente desea eliminar el grupo de estados <b>{0}</b>?",
    STATE_GROUPS_NONE_AVAILABLE             : "No hay grupos de estados disponibles.",
    STATE_GROUPS_ERROR_EXISTS               : "No existe el grupo de estados indicado.",
    STATE_GROUPS_ERROR_NAME                 : "Debe completar el nombre del grupo de estados.",
    STATE_GROUPS_SUCCESS_CREATE             : "El grupo de estados se creó correctamente.",
    STATE_GROUPS_SUCCESS_EDIT               : "El grupo de estados se editó correctamente.",
    STATE_GROUPS_SUCCESS_DELETE             : "El grupo de estados se eliminó correctamente.",

    // Setup: Reject Reasons
    REJECT_REASONS_NAME                     : "Motivos de rechazo",
    REJECT_REASONS_SINGULAR                 : "Motivo de rechazo",
    REJECT_REASONS_MESSAGE                  : "Mensaje de rechazo",
    REJECT_REASONS_CREATE_TITLE             : "Crear motivo de rechazo",
    REJECT_REASONS_EDIT_TITLE               : "Editar motivo de rechazo",
    REJECT_REASONS_DELETE_TITLE             : "Eliminar motivo de rechazo",
    REJECT_REASONS_DELETE_TEXT              : "¿Realmente desea eliminar el motivo de rechazo <b>{0}</b>?",
    REJECT_REASONS_NONE_AVAILABLE           : "No hay motivos de rechazos disponibles.",
    REJECT_REASONS_ERROR_EXISTS             : "No existe el motivo de rechazo indicado.",
    REJECT_REASONS_ERROR_CODE_EMPTY         : "Debe completar el código del motivo de rechazo.",
    REJECT_REASONS_ERROR_CODE_REPEATED      : "No se deben repetir los códigos del motivo de rechazo.",
    REJECT_REASONS_ERROR_NAME               : "Debe completar el nombre del motivo de rechazo.",
    REJECT_REASONS_SUCCESS_CREATE           : "El motivo de rechazo se creó correctamente.",
    REJECT_REASONS_SUCCESS_EDIT             : "El motivo de rechazo se editó correctamente.",
    REJECT_REASONS_SUCCESS_DELETE           : "El motivo de rechazo se eliminó correctamente.",

    // Setup: Cancel Reasons
    CANCEL_REASONS_NAME                     : "Motivos de cancelado",
    CANCEL_REASONS_SINGULAR                 : "Motivo de cancelado",
    CANCEL_REASONS_MESSAGE                  : "Mensaje de cancelado",
    CANCEL_REASONS_CREATE_TITLE             : "Crear motivo de cancelado",
    CANCEL_REASONS_EDIT_TITLE               : "Editar motivo de cancelado",
    CANCEL_REASONS_DELETE_TITLE             : "Eliminar motivo de cancelado",
    CANCEL_REASONS_DELETE_TEXT              : "¿Realmente desea eliminar el motivo de cancelado <b>{0}</b>?",
    CANCEL_REASONS_SYSTEM                   : "Sistema",
    CANCEL_REASONS_NONE_AVAILABLE           : "No hay motivos de cancelados disponibles.",
    CANCEL_REASONS_ERROR_EXISTS             : "No existe el motivo de cancelado indicado.",
    CANCEL_REASONS_ERROR_CODE_EMPTY         : "Debe completar el código del motivo de cancelado.",
    CANCEL_REASONS_ERROR_CODE_REPEATED      : "No se deben repetir los códigos del motivo de cancelado.",
    CANCEL_REASONS_ERROR_NAME               : "Debe completar el nombre del motivo de cancelado.",
    CANCEL_REASONS_ERROR_DELETE             : "No se puede eliminar el motivo de cancelado indicado.",
    CANCEL_REASONS_SUCCESS_CREATE           : "El motivo de cancelado se creó correctamente.",
    CANCEL_REASONS_SUCCESS_EDIT             : "El motivo de cancelado se editó correctamente.",
    CANCEL_REASONS_SUCCESS_DELETE           : "El motivo de cancelado se eliminó correctamente.",

    // Setup: Tooltips
    TOOLTIPS_NAME                           : "Tooltips",
    TOOLTIPS_SINGULAR                       : "Tooltip",
    TOOLTIPS_EDIT_TITLE                     : "Editar tooltip",
    TOOLTIPS_ERROR_EXISTS                   : "No existe el tooltip indicado.",
    TOOLTIPS_ERROR_DESCRIPTION              : "Debe indicar la descripción del tooltip.",
    TOOLTIPS_ERROR_VIDEO                    : "El archivo debe ser un video.",
    TOOLTIPS_ERROR_PDF                      : "El archivo debe ser un PDF.",
    TOOLTIPS_SUCCESS_EDIT                   : "El tooltip se editó correctamente.",

    // Setup: Media
    MEDIA_NAME                              : "Archivos",
    MEDIA_SINGULAR                          : "Archivo",
    MEDIA_SELECT_TITLE                      : "Seleccionar archivo",
    MEDIA_CREATE_TITLE                      : "Crear directorio",
    MEDIA_EDIT_DIR_TITLE                    : "Renombrar directorio",
    MEDIA_EDIT_FILE_TITLE                   : "Renombrar archivo",
    MEDIA_DELETE_DIR_TITLE                  : "Eliminar directorio",
    MEDIA_DELETE_FILE_TITLE                 : "Eliminar archivo",
    MEDIA_DELETE_DIR_TEXT                   : "¿Realmente desea eliminar el directorio <b>{0}</b>?",
    MEDIA_DELETE_FILE_TEXT                  : "¿Realmente desea eliminar el archivo <b>{0}</b>?",
    MEDIA_NONE_AVAILABLE                    : "No hay archivos disponibles.",
    MEDIA_ERROR_CREATE                      : "No fue posible crear el directorio indicado.",
    MEDIA_ERROR_CREATE_NAME                 : "Debe indicar el nombre del directorio.",
    MEDIA_ERROR_CREATE_EXISTS               : "Ya existe un archivo/directorio con ese nombre.",
    MEDIA_ERROR_UPLOAD                      : "Ocurrió un error al subir el archivo.",
    MEDIA_ERROR_UPLOAD_EMPTY                : "Debe indicar un archivo para subir.",
    MEDIA_ERROR_UPLOAD_SIZE                 : "El archivo pesa más de 8mb.",
    MEDIA_ERROR_UPLOAD_SIZES                : "Hay archivos que pesan más de 8mb.",
    MEDIA_ERROR_UPLOAD_EXISTS               : "Ya existe un archivo con ese nombre.",
    MEDIA_ERROR_RENAME                      : "Ocurrió un error al renombrar el archivo/directorio indicado.",
    MEDIA_ERROR_RENAME_OLD_NAME             : "Debe indicar el archivo/directorio para renombrar.",
    MEDIA_ERROR_RENAME_NEW_NAME             : "Debe indicar el nuevo nombre del archivo.",
    MEDIA_ERROR_RENAME_OLD_EXISTS           : "No existe el archivo/directorio para renombrar.",
    MEDIA_ERROR_RENAME_NEW_EXISTS           : "Ya existe un archivo/directorio con el nuevo nombre.",
    MEDIA_ERROR_MOVE                        : "Ocurrió un error al mover el archivo indicado.",
    MEDIA_ERROR_MOVE_OLD_PATH               : "Debe indicar el archivo para mover.",
    MEDIA_ERROR_MOVE_NEW_PATH               : "Debe indicar el nuevo directorio del archivo.",
    MEDIA_ERROR_MOVE_OLD_EXISTS             : "No existe el archivo para mover.",
    MEDIA_ERROR_MOVE_NEW_EXISTS             : "Ya existe un archivo con el mismo nombre en el directorio indicado.",
    MEDIA_ERROR_DELETE                      : "Ocurrió un error al eliminar el archivo/directorio indicado.",
    MEDIA_ERROR_DELETE_NAME                 : "Debe indicar el archivo/directorio para eliminar.",
    MEDIA_ERROR_DELETE_EXISTS               : "No existe el archivo/directorio para eliminar.",
    MEDIA_SUCCESS_CREATE                    : "El directorio se creó correctamente.",
    MEDIA_SUCCESS_UPLOAD                    : "El archivo se subió correctamente.",
    MEDIA_SUCCESS_RENAME                    : "El archivo/directorio se renombró correctamente.",
    MEDIA_SUCCESS_MOVE                      : "El archivo se movió correctamente.",
    MEDIA_SUCCESS_DELETE                    : "El archivo/directorio se eliminó correctamente.",



    // Document
    DOCUMENTS_NAME                          : "Documentación",
    DOCUMENTS_SINGULAR                      : "Documento",
    DOCUMENTS_CREATE_TITLE                  : "Crear documento",
    DOCUMENTS_EDIT_TITLE                    : "Editar documento",
    DOCUMENTS_PRINT_TITLE                   : "Imprimir documento",
    DOCUMENTS_DELETE_TITLE                  : "Eliminar documento",
    DOCUMENTS_DELETE_TEXT                   : "¿Realmente desea eliminar el documento <b>{0}</b>?",
    DOCUMENTS_WARNING_TITLE                 : "Cambios sin guardar",
    DOCUMENTS_WARNING_TEXT                  : "Hay cambios sin guardar en el documento. ¿Desea descartarlos o seguir editando y guardarlos?",
    DOCUMENTS_SELECT_ONE                    : "Selecciona un documento del listado para poder obtener la información que necesita.",
    DOCUMENTS_HELP                          : "Ayuda del módulo",
    DOCUMENTS_PARENT                        : "Documento padre",
    DOCUMENTS_URL                           : "URL del módulo",
    DOCUMENTS_TYPE_MAIN                     : "Principal",
    DOCUMENTS_TYPE_PARTNER                  : "Partner",
    DOCUMENTS_TYPE_ADMINS                   : "Administrativo",
    DOCUMENTS_TYPE_RELEASES                 : "Releases",
    DOCUMENTS_NONE_AVAILABLE                : "No hay documentos disponibles.",
    DOCUMENTS_ERROR_EXISTS                  : "No existe el documento indicado.",
    DOCUMENTS_ERROR_TYPE                    : "No existe el tipo de documento indicado.",
    DOCUMENTS_ERROR_NAME_EMPTY              : "Debe indicar el nombre del documento.",
    DOCUMENTS_ERROR_NAME_REPEATED           : "No puede repetir los nombres de los documentos dentro del padre indicado.",
    DOCUMENTS_ERROR_URL                     : "La url no debe ser relativa y no incluir el dominio.",
    DOCUMENTS_ERROR_PARENT_EXISTS           : "No existe el padre indicado.",
    DOCUMENTS_ERROR_PARENT_SELF             : "No puede seleccionar el mismo documento como el padre.",
    DOCUMENTS_ERROR_PARENT_CYCLE            : "No puede crear ciclos con los documentos.",
    DOCUMENTS_ERROR_STATUS                  : "Debe indicar el estado del documento.",
    DOCUMENTS_SUCCESS_CREATE                : "El documento se creó correctamente.",
    DOCUMENTS_SUCCESS_EDIT                  : "El documento se editó correctamente.",
    DOCUMENTS_SUCCESS_DELETE                : "El documento se eliminó correctamente.",



    // Email: Templates
    EMAIL_TEMPLATES_NAME                    : "Plantillas de emails",
    EMAIL_TEMPLATES_VIEW_TITLE              : "Ver plantilla de email",
    EMAIL_TEMPLATES_NONE_AVAILABLE          : "No hay plantillas de emails disponibles.",

    // Email: Queue
    EMAIL_QUEUE_NAME                        : "Cola de emails",
    EMAIL_QUEUE_VIEW_TITLE                  : "Ver email",
    EMAIL_QUEUE_RESEND_TITLE                : "Reenviar email",
    EMAIL_QUEUE_RESEND_TEXT_SINGULAR        : "¿Realmente desea marcar <b>{0}</b> email para envío?",
    EMAIL_QUEUE_RESEND_TEXT_PLURAL          : "¿Realmente desea marcar <b>{0}</b> emails para envío?",
    EMAIL_QUEUE_NONE_AVAILABLE              : "No hay emails disponibles en la cola de emails.",
    EMAIL_QUEUE_ERROR_RESEND                : "Debe indicar al menos un email a para reenviar.",
    EMAIL_QUEUE_SUCCESS_RESEND              : "El email se marcó para envió correctamente.",
    EMAIL_QUEUE_SUCCESS_MULTI_RESEND        : "Los emails se marcaron para envío correctamente.",

    // Email: White List
    EMAIL_WHITE_LIST_NAME                   : "Lista blanca de emails",
    EMAIL_WHITE_LIST_CREATE_TITLE           : "Agregar email",
    EMAIL_WHITE_LIST_EDIT_TITLE             : "Editar email",
    EMAIL_WHITE_LIST_DELETE_TITLE           : "Quitar email",
    EMAIL_WHITE_LIST_DELETE_TEXT            : "¿Realmente desea quitar el email <b>{0}</b>?",
    EMAIL_WHITE_LIST_EMAIL                  : "Email disponible para envíos",
    EMAIL_WHITE_LIST_DESCRIPTION            : "Descripción sobre el email",
    EMAIL_WHITE_LIST_NONE_AVAILABLE         : "No hay emails disponibles.",
    EMAIL_WHITE_LIST_ERROR_EXISTS           : "No existe la plantilla de email indicada.",
    EMAIL_WHITE_LIST_ERROR_EMPTY            : "Debe indicar el email a agregar.",
    EMAIL_WHITE_LIST_ERROR_INVALID          : "El email indicado es incorrecto.",
    EMAIL_WHITE_LIST_ERROR_USED             : "El email indicado ya esta agregado.",
    EMAIL_WHITE_LIST_SUCCESS_ADD            : "El email se agregó correctamente.",
    EMAIL_WHITE_LIST_SUCCESS_EDIT           : "El email se editó correctamente.",
    EMAIL_WHITE_LIST_SUCCESS_REMOVE         : "El email se quitó correctamente.",



    // Log: Providers
    PROVIDERS_LOG_NAME                      : "Log de proveedores",
    PROVIDERS_LOG_VIEW_TITLE                : "Ver log de proveedor",
    PROVIDERS_LOG_PROVIDER                  : "Proveedor",
    PROVIDERS_LOG_ACTION                    : "Acción",
    PROVIDERS_LOG_REQUEST                   : "Request",
    PROVIDERS_LOG_RESPONSE                  : "Respuesta",
    PROVIDERS_LOG_NONE_AVAILABLE            : "No hay logs de proveedores disponibles.",

    // Log: Actions
    ACTIONS_LOG_NAME                        : "Log de acciones",
    ACTIONS_LOG_SESSION                     : "Sesión",
    ACTIONS_LOG_MODULE                      : "Módulo",
    ACTIONS_LOG_ACTION                      : "Acción",
    ACTIONS_LOG_NONE_AVAILABLE              : "No hay acciones disponibles.",

    // Log: Errors
    ERRORS_LOG_NAME                         : "Log de errores",
    ERRORS_LOG_VIEW_TITLE                   : "Ver error",
    ERRORS_LOG_RESOLVE_TITLE                : "Marcar como resuelto",
    ERRORS_LOG_RESOLVE_TEXT_SINGULAR        : "¿Realmente desea marcar <b>{0}</b> error como resuelto?",
    ERRORS_LOG_RESOLVE_TEXT_PLURAL          : "¿Realmente desea marcar <b>{0}</b> errores como resueltos?",
    ERRORS_LOG_DELETE_TITLE                 : "Eliminar error",
    ERRORS_LOG_DELETE_TEXT_SINGULAR         : "¿Realmente desea eliminar <b>{0}</b> error?",
    ERRORS_LOG_DELETE_TEXT_PLURAL           : "¿Realmente desea eliminar <b>{0}</b> errores?",
    ERRORS_LOG_AMOUNT                       : "Cantidad",
    ERRORS_LOG_ENVIRONMENT                  : "Entorno",
    ERRORS_LOG_FILE                         : "Archivo",
    ERRORS_LOG_BACKTRACE                    : "Backtrace",
    ERRORS_LOG_RESOLVED                     : "Resuelto",
    ERRORS_LOG_NONE_AVAILABLE               : "No hay errores disponibles.",
    ERRORS_LOG_ERROR_RESOLVE                : "Debe indicar al menos un error a para resolver.",
    ERRORS_LOG_ERROR_DELETE                 : "Debe indicar al menos un error a eliminar.",
    ERRORS_LOG_SUCCESS_RESOLVED             : "El error se marcó como resuelto correctamente.",
    ERRORS_LOG_SUCCESS_MULTI_RESOLVED       : "Los errores se marcaron como resueltos correctamente.",
    ERRORS_LOG_SUCCESS_DELETE               : "El error se eliminó correctamente.",
    ERRORS_LOG_SUCCESS_MULTI_DELETE         : "Los errores se eliminaron correctamente.",



    // Access
    ACCESS_OPERATIVE                        : "Operativo",
    ACCESS_SUPERVISOR                       : "Encargado",
    ACCESS_CATALOG                          : "Catalogador",
    ACCESS_FINANCE                          : "Financiero",
    ACCESS_ADMIN                            : "Administrador",
    ACCESS_SUPER_ADMIN                      : "Super admin",

    // DropZone
    DROPZONE_FILES_TITLE                    : "Arrastra archivos a cualquier lugar para subirlos",
    DROPZONE_IMAGES_TITLE                   : "Arrastra imágenes a cualquier lugar para subirlas",
    DROPZONE_OR                             : "ó",
    DROPZONE_FILES_BUTTON                   : "Seleccionar archivos",
    DROPZONE_IMAGES_BUTTON                  : "Seleccionar imágenes",
    DROPZONE_FILES_DROP                     : "Arrastra los archivos aquí para subirlos",
    DROPZONE_IMAGES_DROP                    : "Arrastra las imágenes aquí para subirlas",

    // Periods
    PERIOD_NAME                             : "Periodo",
    PERIOD_SELECT                           : "Seleccionar periodo",
    PERIOD_TODAY                            : "Hoy",
    PERIOD_YESTERDAY                        : "Ayer",
    PERIOD_LAST_7_DAYS                      : "Últimos 7 días",
    PERIOD_LAST_15_DAYS                     : "Últimos 15 días",
    PERIOD_LAST_30_DAYS                     : "Últimos 30 días",
    PERIOD_LAST_60_DAYS                     : "Últimos 60 días",
    PERIOD_LAST_90_DAYS                     : "Últimos 90 días",
    PERIOD_LAST_120_DAYS                    : "Últimos 120 días",
    PERIOD_LAST_YEAR                        : "Último año",
    PERIOD_THIS_WEEK                        : "Esta semana",
    PERIOD_THIS_MONTH                       : "Este mes",
    PERIOD_THIS_YEAR                        : "Este año",
    PERIOD_PAST_WEEK                        : "La semana pasada",
    PERIOD_PAST_MONTH                       : "El mes pasado",
    PERIOD_PAST_YEAR                        : "El año pasado",
    PERIOD_ALL_PERIOD                       : "Todo el periodo",
    PERIOD_CUSTOM                           : "Personalizado",

    // Replaceable Date Formats. {d: Day number, d0: 2 digit day, dn: Day name, d3: 3 letters day name, m: month, m0: 2 digit month, mn: Month name, m3: 3 letters month name, y: year, h: hours, i: minutes}
    DATE_DAY_NAMES                          : [ "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado" ],
    DATE_DAY_SHORTS                         : [ "Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá" ],
    DATE_DAY_POSITIONS                      : [ "Primer", "Segundo", "Tercer", "Cuarto" ],
    DATE_MONTH_NAMES                        : [ "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre" ],

    DATE_TIME                               : "{h}:{i}",
    DATE_DAY_TIME                           : "{d} {m3} {h}:{i}",
    DATE_DAY_MONTH                          : "{d} de {mn}",
    DATE_DAY_MONTH_SHORT                    : "{d}, {m3}",
    DATE_DAY_MONTH_MEDIUM                   : "{d} de {m3}",
    DATE_DAY_MONTH_YEAR                     : "{d} de {mn}, {y}",
    DATE_DAY_YEAR_SHORT                     : "{d}, {m3} {y}",
    DATE_DAY_YEAR_MEDIUM                    : "{d} de {m3} {y}",
    DATE_MONTH_YEAR                         : "{mn} {y}",
    DATE_REDUCED                            : "{dn} {d0}/{m0}",
    DATE_COMPLETE                           : "{dn}, {d} de {mn}, {y}",
    DATE_COMPLETE_SHORT                     : "{d3}, {d} de {m3}, {y}",
    DATE_COMPLETE_MONTH                     : "{dn}, {d} de {mn}",
    DATE_DASHES                             : "{d0}-{m0}-{y}",
    DATE_DASHES_REVERSE                     : "{y}-{m0}-{d0}",
    DATE_DASHES_TIME                        : "{d0}-{m0}-{y} a las {h}:{i}",
    DATE_DASHES_HOUR                        : "{d0}-{m0}-{y}, {h}:{i}",
    DATE_DASHES_SECONDS                     : "{d0}-{m0}-{y}, {h}:{i}:{s}",
    DATE_SLASHES                            : "{d0}/{m0}/{y}",
    DATE_SLASHES_DAY                        : "{d0}/{m0}",
    DATE_SORTABLE                           : "{y}/{m0}/{d0}",

    DATE_NOW                                : "Ahora",
    DATE_MIN_AGO                            : "{i} minuto atrás",
    DATE_MINS_AGO                           : "{i} minutos atrás",
    DATE_HOUR_AGO                           : "{h} hora atrás",
    DATE_HOURS_AGO                          : "{h} horas atrás",
    DATE_DAY_AGO                            : "{d} día atrás",
    DATE_DAYS_AGO                           : "{d} días atrás",
    DATE_IN_MIN                             : "En {i} minuto",
    DATE_IN_MINS                            : "En {i} minutos",
    DATE_IN_HOUR                            : "En {h} hora",
    DATE_IN_HOURS                           : "En {h} horas",
    DATE_IN_DAY                             : "En {d} día",
    DATE_IN_DAYS                            : "En {d} días",
    DATE_TOMORROW_AT                        : "Mañana a las {h}:{i}",
    DATE_TODAY_AT                           : "Hoy a las {h}:{i}",
    DATE_YESTERDAY_AT                       : "Ayer a las {h}:{i}",
    DATE_THIS_WEEK_AT                       : "{dn} a las {h}:{i}",
    DATE_THIS_YEAR_AT                       : "{d} de {m3} a las {h}:{i}",
    DATE_OTHER_YEAR_AT                      : "{d} de {m3} {y} a las {h}:{i}",

    DATE_YESTERDAY                          : "Ayer",
    DATE_TODAY                              : "Hoy",
    DATE_TOMORROW                           : "Mañana",
    DATE_LAST_WEEK                          : "La Semana Pasada",
    DATE_THIS_WEEK                          : "Esta Semana",
    DATE_NEXT_WEEK                          : "La Semana Próxima",
    DATE_PARSED_WEEK                        : "Semana del {0} al {1}",
    DATE_DURATION                           : "{0} - {1}",

};

export default strings;

import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Change Catalog Process Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChangeCatalogProcess(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { processElem } = Store.useAction("changeCatalog");


    // The Initial Data
    const initialData = {
        changeCatalogID : 0,
        newNameBenvida  : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("changeCatalog", initialData, processElem, onSubmit);

    // Load the Data
    const { loading, elem } = useDialog("changeCatalog", open, elemID, null, setElem);


    // Do the Render
    return <EditDialog
        open={open}
        icon="change-catalog"
        title="CHANGE_CATALOGS_PROCESS_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        withSpacing
    >
        <Columns>
            <ViewField
                label="GENERAL_TIME"
                value={elem.createdDate}
            />
            <ViewField
                label="GENERAL_TYPE"
                value={elem.modificationTypeText}
            />
            <ViewField
                label="CHANGE_CATALOGS_MODULE"
                value={elem.moduleText}
            />
            <ViewField
                label="GENERAL_CODE_ALFABETA"
                value={elem.alfabetaID}
            />
        </Columns>

        <Columns className="top-space-big">
            <ViewField
                label="CHANGE_CATALOGS_OLD_NAME_ALFABETA"
                value={elem.oldNameAlfabeta}
                showEmpty
            />
            <ViewField
                label="CHANGE_CATALOGS_NEW_NAME_ALFABETA"
                value={elem.newNameAlfabeta}
                showEmpty
            />
        </Columns>

        <ViewField
            label="CHANGE_CATALOGS_OLD_NAME_BENVIDA"
            value={elem.oldNameBenvida}
            showEmpty
        />
        <InputField
            className="top-space-big"
            name="newNameBenvida"
            label="CHANGE_CATALOGS_NEW_NAME_BENVIDA"
            value={data.newNameBenvida}
            onChange={handleChange}
            shrinkLabel
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChangeCatalogProcess.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default ChangeCatalogProcess;

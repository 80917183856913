import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Order Cancel Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function OrderCancel(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { cancelReasons } = Store.useState("order");
    const { cancelElem } = Store.useAction("order");


    // The Initial Data
    const initialData = {
        date           : "",
        time           : "",
        cancelReasonID : 0,
        reason         : "",
    };

    // Handle the Cancel
    const handleEdit = (data) => {
        return cancelElem({ ...data, orderID : elemID });
    };

    // The Form State
    const {
        loading, data, errors, handleChange, handleSubmit,
    } = useForm("order", initialData, handleEdit, onSubmit);


    // Do the Render
    return <EditDialog
        open={open}
        icon="cancel"
        title="ORDERS_CANCEL_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <DialogMessage
            variant="h3"
            message="ORDERS_CANCEL_TEXT"
            noSpace
        />

        <InputField
            type="double"
            error={errors.date}
            onChange={handleChange}
        >
            <InputItem
                type="date"
                name="date"
                label="DASHBOARD_PROCESS_CANCEL_DATE"
                maxValue="2999-12-31"
                value={data.date}
            />
            <InputItem
                type="time"
                name="time"
                label="DASHBOARD_PROCESS_CANCEL_TIME"
                value={data.time}
            />
        </InputField>

        <InputField
            type="select"
            name="cancelReasonID"
            label="CANCEL_REASONS_SINGULAR"
            options={cancelReasons}
            value={data.cancelReasonID}
            error={errors.cancelReasonID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="textarea"
            name="reason"
            label="DASHBOARD_PROCESS_REASON_TEXT"
            value={data.reason}
            error={errors.reason}
            onChange={handleChange}
            maxLength={255}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
OrderCancel.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default OrderCancel;

import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Coverage }         from "Utils/API";



// The initial State
const initialState = {
    loading         : true,
    error           : false,
    edition         : 0,
    canCreate       : false,
    canEdit         : false,
    canFilter       : false,
    canImport       : false,
    canExport       : false,
    list            : [],
    total           : 0,
    elem            : {},
    retirementTypes : [],
    gateways        : [],
    statuses        : [],
    sort            : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "COVERAGE_LOADING" });
    },

    /**
     * Fetches the Coverage List
     * @param {Function} dispatch
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, params = {}) {
        const data = await Coverage.getAll(params);
        data.sort = params;
        dispatch({ type : "COVERAGE_LIST", data });
    },

    /**
     * Fetches a single Coverage
     * @param {Function} dispatch
     * @param {Number}   coverageID
     * @returns {Promise}
     */
    async fetchElem(dispatch, coverageID) {
        const data = await Coverage.getOne({ coverageID });
        dispatch({ type : "COVERAGE_ELEM", data });
    },

    /**
     * Fetches the Coverage Edit data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchEditData(dispatch) {
        const data = await Coverage.getEditData();
        dispatch({ type : "COVERAGE_EDIT", data });
    },

    /**
     * Edits/Creates a Coverage
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return Coverage.edit(data);
    },

    /**
     * Deletes a Coverage
     * @param {Function} dispatch
     * @param {Number}   coverageID
     * @returns {Promise}
     */
    deleteElem(dispatch, coverageID) {
        return Coverage.delete({ coverageID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.retirementTypeIDs = elem.retirementTypes.map((retirementType) => retirementType.retirementTypeID);
    elem.gatewayCodes      = elem.gateways.map((gateway) => gateway.gatewayCode);

    if (!elem.retirementTypes.length) {
        elem.retirementTypeNames = "GENERAL_ALL";
    } else {
        elem.retirementTypeNames = elem.retirementTypes.map((retirementType) => retirementType.retirementTypeName).join(", ");
    }

    if (!elem.gateways.length) {
        elem.gatewayNames = "GENERAL_ALL_FEM";
    } else {
        elem.gatewayNames = elem.gateways.map((gateway) => gateway.gatewayName).join(", ");
    }
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "COVERAGE_LIST", "COVERAGE_ELEM", "COVERAGE_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "COVERAGE_LOADING":
        return {
            ...state,
            loading         : true,
        };

    case "COVERAGE_LIST":
        return {
            ...state,
            loading         : false,
            error           : false,
            canCreate       : action.data.canCreate,
            canEdit         : action.data.canEdit,
            canFilter       : action.data.canFilter,
            canImport       : action.data.canImport,
            canExport       : action.data.canExport,
            list            : Utils.parseList(action.data.list, parseElem),
            total           : action.data.total,
            sort            : action.data.sort,
        };

    case "COVERAGE_ELEM":
        return {
            ...state,
            error           : false,
            edition         : state.edition + 1,
            elem            : parseElem(action.data.elem),
            retirementTypes : action.data.retirementTypes,
            gateways        : action.data.gateways,
            statuses        : action.data.statuses,
        };

    case "COVERAGE_EDIT":
        return {
            ...state,
            error           : false,
            edition         : state.edition + 1,
            retirementTypes : action.data.retirementTypes,
            gateways        : action.data.gateways,
            statuses        : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);

import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import useList              from "Dashboard/Hooks/List";

// Components
import CommissionFilter     from "./CommissionFilter";
import CommissionEdit       from "./CommissionEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Commission List
 * @returns {React.ReactElement}
 */
function CommissionList() {
    const { fetch, load, loadFilter } = useList("commission");

    const data = Store.useState("commission");
    const { canEdit, list, total, filters, sort, loading } = data;

    const { deleteElem } = Store.useAction("commission");


    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    return <Main>
        <Header message="COMMISSIONS_NAME" icon="commission">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="COMMISSIONS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="withCoverage"       message="COMMISSIONS_COVERAGE"      isTitle maxWidth="90" />
                    <TableHeader field="saleTypeName"       message="SALE_TYPES_SINGULAR"       />
                    <TableHeader field="pharmacyName"       message="PHARMACIES_SINGULAR"       />
                    <TableHeader field="subsidiaryFullName" message="SUBSIDIARIES_SINGULAR"     />
                    <TableHeader field="percent"            message="GENERAL_PERCENT"           align="right"  maxWidth="90" />
                    <TableHeader field="useSubTotal"        message="COMMISSIONS_USE_SUB_TOTAL" align="center" maxWidth="120" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.commissionID} elemID={elem.commissionID}>
                        <TableCell message={elem.withCoverageText}   />
                        <TableCell message={elem.saleTypeName}       />
                        <TableCell message={elem.pharmacyName}       />
                        <TableCell message={elem.subsidiaryFullName} />
                        <TableCell message={elem.percentText}        />
                        <TableCell message={elem.useSubTotalText}    />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="COMMISSIONS_EDIT_TITLE"   />
                    <TableAction action="DELETE" message="COMMISSIONS_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <CommissionFilter
            open={action.isFilter}
            filters={filters}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <CommissionEdit
            open={action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="COMMISSIONS_DELETE_TITLE"
            message="COMMISSIONS_DELETE_TEXT"
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

export default CommissionList;

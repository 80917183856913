import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import ViewTable            from "Components/Utils/View/ViewTable";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";



/**
 * The Product Subsidiaries
 * @returns {React.ReactElement}
 */
function ProductSubsidiaries() {
    const { updates } = Store.useState("product");


    // Do the Render
    if (!updates || !updates.length) {
        return <React.Fragment />;
    }

    // Do the Render
    return <ViewTable message="PRODUCTS_UPDATES">
        <Table noSorting notFixed>
            <TableHead>
                <TableHeader field="createdTime" message="GENERAL_CREATED_AT_FEM" maxWidth="160" />
                <TableHeader field="createdUser" message="USERS_SINGULAR"         />
                <TableHeader field="field"       message="PRODUCTS_FIELD"         />
                <TableHeader field="oldValue"    message="PRODUCTS_OLD_VALUE"     />
                <TableHeader field="newValue"    message="PRODUCTS_NEW_VALUE"     />
            </TableHead>
            <TableBody>
                {updates.map((elem) => <TableRow key={elem.changeUpdateID} elemID={elem.changeUpdateID}>
                    <TableCell message={elem.createdTimeDate} />
                    <TableCell message={elem.createdUserName} />
                    <TableCell message={elem.fieldText}       />
                    <TableCell message={elem.oldValue}        />
                    <TableCell message={elem.newValue}        />
                </TableRow>)}
            </TableBody>
        </Table>
    </ViewTable>;
}

export default ProductSubsidiaries;

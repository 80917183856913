import React                from "react";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import { Transaction }      from "Utils/API";

// Components
import TransactionFilter    from "./TransactionFilter";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import Html                 from "Dashboard/Components/Common/Html";



/**
 * The Transaction List
 * @returns {React.ReactElement}
 */
function TransactionList() {
    const { load, loadFilter } = useList("transaction");
    const navigate = Navigate.useGoto();

    const data = Store.useState("transaction");
    const { loading, canEdit, list, total, filters, sort } = data;

    // The Current State
    const [ exporting, setExporting ] = React.useState(false);
    const [ action, updateAction ] = Action.useAction();


    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isView) {
            updateAction();
            const orderID = Utils.getValue(list, "transactionID", elemID, "orderID");
            navigate("TRANSACTIONS", orderID);
        } else if (action.isExport) {
            setExporting(true);
            updateAction();
            const source = Transaction.export({ ...filters });
            await Utils.download(source, NLS.get("TRANSACTIONS_FILE_NAME"));
            setExporting(false);
        } else {
            updateAction(action, elemID);
        }
    };

    // Handles the Filter Submit
    const handleFilter = (filters) => {
        updateAction();
        loadFilter(filters);
    };


    // Do the Render
    return <Main>
        <Header message="TRANSACTIONS_NAME" icon="transaction">
            <ActionList
                data={data}
                onAction={handleAction}
                isExporting={exporting}
            />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="TRANSACTIONS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="settlementTime"      message="GENERAL_CREATED_AT_FEM"     minWidth="160" maxWidth="160" />
                    <TableHeader field="orderNumber"         message="ORDERS_SINGULAR"            maxWidth="80" />
                    <TableHeader field="transactionTypeName" message="TRANSACTION_TYPES_SINGULAR" />
                    <TableHeader field="paymentTypeName"     message="PAYMENT_TYPES_SINGULAR"     />
                    <TableHeader field="paymentMethodName"   message="PAYMENT_METHODS_SINGULAR"   />
                    <TableHeader field="cardType"            message="CARD_TYPES_SINGULAR"        />
                    <TableHeader field="bankFantasyName"     message="BANKS_SINGULAR"             />
                    <TableHeader field="cardCategory"        message="CATEGORIES_SINGULAR"        />
                    <TableHeader field="installments"        message="GENERAL_INSTALLMENTS"       align="center" maxWidth="60" />
                    <TableHeader field="commission"          message="ORDERS_COMMISSION"          align="right" maxWidth="100" />
                    <TableHeader field="mercadoPagoFee"      message="GENERAL_MP"                 align="right" maxWidth="100" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.transactionID} elemID={elem.transactionID}>
                        <TableCell message={elem.settlementDate}      />
                        <TableCell message={elem.numberText}          />
                        <TableCell message={elem.transactionTypeName} />
                        <TableCell message={elem.paymentTypeName}     />
                        <TableCell message={elem.paymentMethodName}   />
                        <TableCell message={elem.cardName}            />
                        <TableCell message={elem.bankName}            />
                        <TableCell message={elem.cardCategory}        />
                        <TableCell message={elem.installments}        />
                        <TableCell><Html>{elem.commissionText}</Html></TableCell>
                        <TableCell><Html>{elem.mercadoPagoText}</Html></TableCell>
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW" message="TRANSACTIONS_VIEW_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <TransactionFilter
            open={action.isFilter}
            filters={filters}
            onSubmit={handleFilter}
            onClose={updateAction}
        />
    </Main>;
}

export default TransactionList;

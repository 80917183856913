import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import { Product }          from "Utils/API";



// The initial State
const initialState = {
    loading                  : true,
    charging                 : false,
    error                    : false,
    edition                  : 0,
    canCreate                : false,
    canEdit                  : false,
    canFilter                : false,
    canImport                : false,
    canExport                : false,
    list                     : [],
    total                    : 0,
    elem                     : {},
    spaceNames               : [],
    medicine                 : {},
    drugs                    : [],
    subsidiaries             : [],
    updates                  : [],
    benvidaNextID            : 0,
    spaces                   : [],
    macroCategories          : [],
    categories               : [],
    categoriesMacro          : {},
    categoriesPerMacro       : {},
    subCategories            : [],
    subCategoriesCategory    : {},
    subCategoriesPerMacro    : {},
    subCategoriesPerCategory : {},
    saleTypes                : [],
    shapeTypes               : [],
    pharmaActions            : [],
    filters                  : {},
    hasFilters               : false,
    sort                     : {
        orderBy  : "name",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "PRODUCT_LOADING" });
    },

    /**
     * Fetches the Product List
     * @param {Function} dispatch
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        const data   = await Product.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "PRODUCT_LIST", data });
    },

    /**
     * Fetches a single Product
     * @param {Function} dispatch
     * @param {Number}   productID
     * @returns {Promise}
     */
    async fetchElem(dispatch, productID) {
        dispatch({ type : "PRODUCT_CHARGING" });
        const data = await Product.getOne({ productID });
        dispatch({ type : "PRODUCT_ELEM", data });
    },

    /**
     * Fetches the Product Edit data
     * @param {Function} dispatch
     * @param {Number=}  productID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, productID = 0) {
        const data = await Product.getEditData({ productID });
        dispatch({ type : "PRODUCT_EDIT", data });
    },

    /**
     * Fetches the Product Filter data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchFilterData(dispatch) {
        const data = await Product.getFilterData();
        dispatch({ type : "PRODUCT_FILTER", data });
    },

    /**
     * Edits/Creates a Product
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return Product.edit(data);
    },

    /**
     * Edits multiple Products
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editMultiElem(dispatch, data) {
        return Product.editMulti(data);
    },

    /**
     * Deletes a Product
     * @param {Function} dispatch
     * @param {Number}   productID
     * @returns {Promise}
     */
    deleteElem(dispatch, productID) {
        return Product.delete({ productID });
    },
};



/**
 * Parses a single Subsidiary
 * @param {Object} elem
 * @returns {Object}
 */
function parseSubsidiary(elem) {
    elem.priceText = `${Utils.formatPrice(elem.price)}`;
    return elem;
}

/**
 * Parses a single Update
 * @param {Object} elem
 * @returns {Object}
 */
function parseUpdate(elem) {
    elem.createdTimeDate = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.fieldText       = NLS.get("SELECT_FIELDS", elem.field);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "PRODUCT_LIST", "PRODUCT_ELEM")) {
        return { ...state, loading : false, charging : false, error : true };
    }

    switch (action.type) {
    case "PRODUCT_LOADING":
        return {
            ...state,
            loading                  : true,
        };

    case "PRODUCT_CHARGING":
        return {
            ...state,
            charging                 : true,
        };

    case "PRODUCT_LIST":
        return {
            ...state,
            loading                  : false,
            error                    : false,
            canCreate                : action.data.canCreate,
            canEdit                  : action.data.canEdit,
            canFilter                : action.data.canFilter,
            canImport                : action.data.canImport,
            canExport                : action.data.canExport,
            list                     : Utils.parseList(action.data.list, Commons.parseProduct),
            total                    : action.data.total,
            filters                  : action.data.filters,
            hasFilters               : !Utils.isEmpty(action.data.filters),
            sort                     : action.data.sort,
        };

    case "PRODUCT_ELEM":
        return {
            ...state,
            charging                 : false,
            error                    : false,
            edition                  : state.edition + 1,
            canEdit                  : action.data.canEdit,
            elem                     : Commons.parseProduct(action.data.elem, action.data.medicine, action.data.fields),
            spaceNames               : action.data.spaceNames,
            medicine                 : Commons.parseMedicine(action.data.medicine, action.data.elem, action.data.fields),
            drugs                    : Utils.parseList(action.data.drugs, Commons.parseDrug),
            subsidiaries             : Utils.parseList(action.data.subsidiaries, parseSubsidiary),
            updates                  : Utils.parseList(action.data.updates, parseUpdate),
        };

    case "PRODUCT_EDIT":
        return {
            ...state,
            error                    : false,
            edition                  : state.edition + 1,
            elem                     : action.data.elem ? Commons.parseProduct(action.data.elem) : state.elem,
            benvidaNextID            : action.data.benvidaNextID,
            macroCategories          : action.data.macroCategories,
            categoriesMacro          : action.data.categoriesMacro,
            subCategoriesCategory    : action.data.subCategoriesCategory,
            laboratories             : action.data.laboratories,
            saleTypes                : action.data.saleTypes,
            shapeTypes               : action.data.shapeTypes,
            potencyTypes             : action.data.potencyTypes,
            pharmaActions            : action.data.pharmaActions,
        };

    case "PRODUCT_FILTER":
        return {
            ...state,
            error                    : false,
            edition                  : state.edition + 1,
            spaces                   : action.data.spaces,
            macroCategories          : action.data.macroCategories,
            categories               : action.data.categories,
            categoriesPerMacro       : action.data.categoriesPerMacro,
            subCategories            : action.data.subCategories,
            subCategoriesPerMacro    : action.data.subCategoriesPerMacro,
            subCategoriesPerCategory : action.data.subCategoriesPerCategory,
            laboratories             : action.data.laboratories,
            saleTypes                : action.data.saleTypes,
            pharmaActions            : action.data.pharmaActions,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);

import React                from "react";
import Store                from "Dashboard/Core/Store";

// Dashboard
import BarIcon              from "Dashboard/Components/Core/BarIcon";



/**
 * The Operative Menu
 * @returns {React.ReactElement}
 */
function OperativeMenu() {
    const { closeMenu } = Store.useAction("core");
    const { isOperative } = Store.useState("auth");


    // Do the Render
    if (!isOperative) {
        return <React.Fragment />;
    }
    return <>
        <BarIcon
            url="DASHBOARD"
            icon="home"
            message="DASHBOARD_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            url="ORDERS"
            icon="order"
            message="ORDERS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            url="GATEWAYS"
            icon="gateway"
            message="GATEWAYS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
    </>;
}

export default OperativeMenu;

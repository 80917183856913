import React                from "react";
import Store                from "Dashboard/Core/Store";
import Commons              from "Utils/Commons";

// Components
import BarChart             from "../../Utils/Charts/BarChart";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableHeader          from "Dashboard/Components/Table/TableHeader";



/**
 * The Commercial Categories
 * @returns {React.ReactElement}
 */
function CommercialCategories() {
    const { categoryTotals } = Store.useState("dashboardCommercial");


    // Do the Render
    return <BarChart
        title="DASHBOARD_CATEGORY_TITLE"
        data={categoryTotals}
    >
        <Table noSorting notFixed>
            <TableHead>
                <TableHeader field="name"           message="CATEGORIES_SINGULAR"     isFlex isTitle />
                <TableHeader field="effectiveUnits" message="DASHBOARD_UNITS"         isFlex maxWidth="70" align="center" />
                <TableHeader field="billingTotal"   message="DASHBOARD_GROSS_BILLING" isFlex maxWidth="120" align="right" />
            </TableHead>
            <TableBody>
                {categoryTotals.map((elem) => <TableRow key={elem.name}>
                    <TableCell message={elem.name}           />
                    <TableCell message={elem.effectiveUnits} />
                    <TableCell message={Commons.formatNumber(elem.billingTotal, true)} />
                </TableRow>)}
            </TableBody>
        </Table>
    </BarChart>;
}

export default CommercialCategories;

import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import SubsidiaryList       from "./Subsidiary/SubsidiaryList";
import PharmacyList         from "./Pharmacy/PharmacyList";
import GatewayList          from "./Gateway/GatewayList";
import UserList             from "./User/UserList";
import ContactList          from "./Contact/ContactList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Business Container
 * @returns {React.ReactElement}
 */
function BusinessContainer() {
    const { isFinance } = Store.useState("auth");


    // Do the Render
    return <>
        <SecondaryNav message="PHARMACIES_NAME">
            <NavigationList>
                <NavigationItem
                    message="SUBSIDIARIES_NAME"
                    url="SUBSIDIARIES"
                    icon="subsidiary"
                />
                <NavigationItem
                    message="PHARMACIES_NAME"
                    url="PHARMACIES"
                    icon="pharmacy"
                />
            </NavigationList>
            <NavigationList>
                <NavigationItem
                    message="GATEWAYS_NAME"
                    url="GATEWAYS"
                    icon="gateway"
                />
                <NavigationItem
                    isHidden={isFinance}
                    message="USERS_NAME"
                    url="USERS"
                    icon="user"
                />
                <NavigationItem
                    message="CONTACTS_NAME"
                    url="CONTACTS"
                    icon="contact"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="ADMIN">
            <AdminRoute url="SUBSIDIARIES" component={SubsidiaryList} />
            <AdminRoute url="PHARMACIES"   component={PharmacyList}      />
            <AdminRoute url="GATEWAYS"     component={GatewayList}    />
            <AdminRoute url="USERS"        component={UserList}       />
            <AdminRoute url="CONTACTS"     component={ContactList}    />
        </Router>
    </>;
}

export default BusinessContainer;

import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Components
import DashboardFilter      from "../DashboardFilter";

// Dashboard
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionList           from "Dashboard/Components/Header/ActionList";



/**
 * The Commercial Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CommercialHeader(props) {
    const { isHidden } = props;

    const { hasFilters, filters } = Store.useState("dashboardCommercial");
    const { setFilters } = Store.useAction("dashboardCommercial");

    // The Current State
    const [ openFilter, setOpenFilter ] = React.useState(false);


    // Handles the Filter
    const handleFilter = (filters) => {
        setFilters(filters);
        setOpenFilter(false);
    };


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <>
        <ActionList>
            <ActionItem
                action="FILTER"
                message="GENERAL_FILTER"
                onClick={() => setOpenFilter(true)}
                withMark={hasFilters}
            />
        </ActionList>

        <DashboardFilter
            open={openFilter}
            filters={filters}
            onSubmit={handleFilter}
            onClose={() => setOpenFilter(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CommercialHeader.propTypes = {
    isHidden : PropTypes.bool.isRequired,
};

export default CommercialHeader;

import React                from "react";
import Styled               from "styled-components";
import Auth                 from "Dashboard/Core/Auth";
import Store                from "Dashboard/Core/Store";

// Components
import SettingsTitle        from "./SettingsTitle";

// Dashboard
import Button               from "Dashboard/Components/Form/Button";



// Styles
const List = Styled.ul`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 12px 0 0 0;
    padding: 0;
    list-style: none;
`;

const Item = Styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--main-gap);
    background-color: var(--lighter-gray);
    border-radius: var(--border-radius);
    transition: all 0.2s;

    :hover {
        background-color: var(--light-gray);
    }
`;

const Content = Styled.div`
    display: flex;
    gap: 12px;
`;



/**
 * The Settings Connections
 * @returns {React.ReactElement}
 */
function SettingsConnections() {
    const { connections } = Store.useState("profile");
    const { fetchProfile } = Store.useAction("profile");
    const { deleteRefreshToken } = Store.useAction("auth");


    // Disconnects the Refresh Token
    const handleDisconnect = async (refreshToken) => {
        await deleteRefreshToken(refreshToken);
        fetchProfile();
    };


    // Do the Render
    if (!connections.length) {
        return <React.Fragment />;
    }
    return <section>
        <SettingsTitle
            icon="login"
            title="ACCOUNT_CONNECTIONS_TITLE"
        />

        <List>
            {connections.map(({ refreshToken, platform, date }) => <Item key={refreshToken}>
                <Content>
                    <b>{platform}</b>
                    <i>{date}</i>
                </Content>
                <Button
                    isHidden={refreshToken === Auth.getRefreshToken()}
                    variant="primary"
                    message="ACCOUNT_CONNECTIONS_BUTTON"
                    onClick={() => handleDisconnect(refreshToken)}
                    isSmall
                />
            </Item>)}
        </List>
    </section>;
}

export default SettingsConnections;

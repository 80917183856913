import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// APIs
import {
    DashboardCommercial,
} from "Utils/API";



// The initial State
const initialState = {
    loading              : true,
    error                : false,
    edition              : 0,
    totals               : {
        grossBilling       : 0,
        netBilling         : 0,
        averageTicket      : 0,
        pendingOrders      : 0,
        totalOrders        : 0,
        effectiveOrders    : 0,
        benvidaFeeCharged  : 0,
        benvidaFeeToCharge : 0,
    },
    objectives           : {
        grossTotal : 0,
        salesTotal : 0,
    },
    holidays             : [],
    dailyChart           : {
        labels          : [],
        totalOrders     : [],
        effectiveOrders : [],
        billingTotals   : [],
        netTotals       : [],
    },
    monthlyChart         : {
        labels          : [],
        totalOrders     : [],
        effectiveOrders : [],
        netTotals       : [],
    },
    retirementTotals     : [],
    subsidiaryTotals     : [],
    pharmacyTotals       : [],
    paymentGatewayTotals : [],
    paymentTypeTotals    : [],
    paymentMethodTotals  : [],
    bankTotals           : [],
    cardTypeTotals       : [],
    productTotals        : [],
    productUnits         : [],
    macroCategoryTotals  : [],
    categoryTotals       : [],
    subCategoryTotals    : [],
    coverageTypeTotals   : [],
    hasFilters           : false,
    filters              : {},
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "DASHBOARD_COMMERCIAL_LOADING" });
    },

    /**
     * Sets the new Filters
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Void}
     */
    setFilters(dispatch, params) {
        const { hasFilters, filters } = Commons.parseDashboardFilters(params);
        dispatch({ type : "DASHBOARD_COMMERCIAL_FILTERS", data : { hasFilters, filters } });
    },

    /**
     * Fetches the Commercial Dashboard
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async fetchList(dispatch, params) {
        const { hasFilters, filters } = Commons.parseDashboardFilters(params);

        const data = await DashboardCommercial.getAll(filters);
        data.hasFilters = hasFilters;
        data.filters    = filters;
        dispatch({ type : "DASHBOARD_COMMERCIAL_LIST", data });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "DASHBOARD_COMMERCIAL_LIST")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "DASHBOARD_COMMERCIAL_LOADING":
        return {
            ...state,
            loading              : true,
        };

    case "DASHBOARD_COMMERCIAL_FILTERS":
        return {
            ...state,
            hasFilters           : action.data.hasFilters,
            filters              : action.data.filters,
        };

    case "DASHBOARD_COMMERCIAL_LIST":
        return {
            ...state,
            loading              : false,
            error                : false,
            totals               : action.data.totals,
            objectives           : action.data.objectives || { ...initialState.objectives },
            holidays             : action.data.holidays,
            dailyChart           : action.data.dailyChart,
            monthlyChart         : action.data.monthlyChart,
            retirementTotals     : action.data.retirementTotals,
            subsidiaryTotals     : action.data.subsidiaryTotals,
            pharmacyTotals       : action.data.pharmacyTotals,
            paymentGatewayTotals : action.data.paymentGatewayTotals,
            paymentTypeTotals    : action.data.paymentTypeTotals,
            paymentMethodTotals  : action.data.paymentMethodTotals,
            bankTotals           : action.data.bankTotals,
            cardTypeTotals       : action.data.cardTypeTotals,
            productTotals        : action.data.productTotals,
            productUnits         : action.data.productUnits,
            macroCategoryTotals  : action.data.macroCategoryTotals,
            categoryTotals       : action.data.categoryTotals,
            subCategoryTotals    : action.data.subCategoryTotals,
            coverageTypeTotals   : action.data.coverageTypeTotals,
            hasFilters           : action.data.hasFilters,
            filters              : action.data.filters,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);

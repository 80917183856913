import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import CoreAuth             from "Dashboard/Core/Auth";

// Components
import App                  from "Components/Core/App";
import Auth                 from "Components/Core/Auth";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import GuestRoute           from "Dashboard/Components/Route/GuestRoute";
import UserRoute            from "Dashboard/Components/Route/UserRoute";

// Styles
import "Styles/Core/Properties.css";
import "Styles/Core/Main.css";
import "Styles/Core/Icons.css";



/**
 * The Main
 * @returns {React.ReactElement}
 */
function Main() {
    const { isAuthenticated } = Store.useState("auth");
    const { setRedirect } = Store.useAction("core");
    const { tokenLogin, refreshTokenLogin } = Store.useAction("auth");

    const navigate     = Navigate.useGoto();
    const path         = Navigate.usePath();
    const searchParams = Navigate.useSearchParams();


    // Login or store the path to redirect
    React.useEffect(() => {
        if (!isAuthenticated) {
            const token        = searchParams.get("token");
            const refreshToken = CoreAuth.getRefreshToken();
            if (token || refreshToken) {
                handleLogin(token, refreshToken, path);
            } else {
                setRedirect(path);
            }
        }
    }, [ isAuthenticated ]);

    // Handle the Token Login
    const handleLogin = async (token, refreshToken, redirect) => {
        if (token) {
            await tokenLogin(token);
        } else if (refreshToken) {
            await refreshTokenLogin();
        }
        navigate(redirect);
    };



    // Do the Render
    return <Router>
        <GuestRoute
            isHidden={isAuthenticated}
            url="NONE"
            component={Auth}
        />
        <UserRoute
            isHidden={!isAuthenticated}
            url="NONE"
            component={App}
        />
    </Router>;
}

export default Main;

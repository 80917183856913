import React                from "react";
import PropTypes            from "prop-types";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Client Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ClientFilter(props) {
    const { open, filters, onClose, onSubmit } = props;


    // The Initial Data
    const initialData = {
        fullName : "",
        email    : "",
        phone    : "",
        dni      : "",
    };

    // The Filter State
    const {
        loading, data, handleChange, handleSubmit,
    } = useFilter("client", open, initialData, filters, onSubmit);


    // Do the Render
    return <FilterDialog
        open={open}
        title="CLIENTS_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns>
            <InputField
                name="fullName"
                label="GENERAL_NAME"
                value={data.fullName}
                onChange={handleChange}
            />
            <InputField
                type="email"
                name="email"
                label="GENERAL_EMAIL"
                value={data.email}
                onChange={handleChange}
            />
            <InputField
                name="phone"
                label="GENERAL_PHONE"
                value={data.phone}
                onChange={handleChange}
            />
            <InputField
                name="dni"
                label="CLIENTS_DNI"
                value={data.dni}
                onChange={handleChange}
            />
        </Columns>
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ClientFilter.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    filters  : PropTypes.object,
};

export default ClientFilter;

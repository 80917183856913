import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import { DashboardProcess } from "Utils/API";



// The initial State
const initialState = {
    loading       : true,
    error         : false,
    edition       : 0,
    canCreate     : false,
    canEdit       : false,
    list          : [],
    elem          : {},
    recipeFiles   : [],
    states        : [],
    products      : [],
    messages      : [],
    actions       : [],
    chatItems     : [],
    rejectReasons : [],
    cancelReasons : [],
    coverageTypes : [],
    coverages     : [],
    saleTypes     : [],
    tooltips      : {},
    canWaitOrder  : false,
    lastUpdate    : 0,
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "DASHBOARD_PROCESS_LOADING" });
    },

    /**
     * Fetches the Process Dashboard
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchList(dispatch) {
        const data = await DashboardProcess.getAll();
        dispatch({ type : "DASHBOARD_PROCESS_LIST", data });
    },

    /**
     * Fetches a single Order
     * @param {Function} dispatch
     * @param {Number=}  orderID
     * @returns {Promise}
     */
    async fetchElem(dispatch, orderID) {
        const data = await DashboardProcess.getOne({ orderID });
        dispatch({ type : "DASHBOARD_PROCESS_ELEM", data });
    },

    /**
     * Starts Using an Order
     * @param {Function} dispatch
     * @param {Number}   orderID
     * @returns {Promise}
     */
    startUsingElem(dispatch, orderID) {
        return DashboardProcess.startUsing({ orderID });
    },

    /**
     * Stops Using an Order
     * @param {Function} dispatch
     * @param {Number}   orderID
     * @returns {Promise}
     */
    stopUsingElem(dispatch, orderID) {
        return DashboardProcess.stopUsing({ orderID });
    },

    /**
     * Fetches new Messages in an Order
     * @param {Function} dispatch
     * @param {Number}   orderID
     * @param {Number}   lastUpdate
     * @returns {Promise}
     */
    async fetchMessages(dispatch, orderID, lastUpdate) {
        const data = await DashboardProcess.getMessages({ orderID, lastUpdate });
        if (!data.error) {
            dispatch({ type : "DASHBOARD_PROCESS_MESSAGES", data });
        }
    },

    /**
     * Sends a Message
     * @param {Function} dispatch
     * @param {Number}   orderID
     * @param {String}   message
     * @param {Number}   lastUpdate
     * @returns {Promise}
     */
    async sendMessage(dispatch, orderID, message, lastUpdate) {
        const data = await DashboardProcess.sendMessage({ orderID, message, lastUpdate });
        if (!data.error) {
            dispatch({ type : "DASHBOARD_PROCESS_MESSAGES", data });
        }
    },

    /**
     * Edits an Order
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async editOrder(dispatch, params) {
        const data = await DashboardProcess.edit(params);
        if (data.success) {
            dispatch({ type : "DASHBOARD_PROCESS_ELEM", data });
        }
        return data;
    },

    /**
     * Edits an Order Data
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async editData(dispatch, params) {
        const data = await DashboardProcess.editData(params);
        if (data.success) {
            dispatch({ type : "DASHBOARD_PROCESS_ELEM", data });
        }
        return data;
    },

    /**
     * Edits an Order Recipe
     * @param {Function} dispatch
     * @param {Number}   orderID
     * @param {Number}   index
     * @param {String}   file
     * @returns {Promise}
     */
    async editRecipe(dispatch, orderID, index, file) {
        const data = await DashboardProcess.editRecipe({ orderID, index, file });
        if (data.success) {
            dispatch({ type : "DASHBOARD_PROCESS_RECIPE", data });
        }
    },

    /**
     * Edits an Order Coverage
     * @param {Function} dispatch
     * @param {Number}   orderID
     * @param {String}   file
     * @returns {Promise}
     */
    async editCoverage(dispatch, orderID, file) {
        const data = await DashboardProcess.editCoverage({ orderID, file });
        if (data.success) {
            dispatch({ type : "DASHBOARD_PROCESS_ELEM", data });
        }
    },

    /**
     * Approves an Order
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async approveElem(dispatch, params) {
        const data = await DashboardProcess.approve(params);
        if (data.message) {
            dispatch({ type : "DASHBOARD_PROCESS_LIST", data });
        }
        return data;
    },

    /**
     * Rejects an Order
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async rejectElem(dispatch, params) {
        const data = await DashboardProcess.reject(params);
        if (data.message) {
            dispatch({ type : "DASHBOARD_PROCESS_LIST", data });
        }
        return data;
    },

    /**
     * Marks the Order as Retire
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async retireElem(dispatch, params) {
        const data = await DashboardProcess.retire(params);
        if (data.message) {
            dispatch({ type : "DASHBOARD_PROCESS_LIST", data });
        }
        return data;
    },

    /**
     * Marks the Order as Retired
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async retiredElem(dispatch, params) {
        const data = await DashboardProcess.retired(params);
        if (data.message) {
            dispatch({ type : "DASHBOARD_PROCESS_LIST", data });
        }
        return data;
    },

    /**
     * Cancels an Order
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async cancelElem(dispatch, params) {
        const data = await DashboardProcess.cancel(params);
        if (data.message) {
            dispatch({ type : "DASHBOARD_PROCESS_LIST", data });
        }
        return data;
    },

    /**
     * Nullifies an Order
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async nullifyElem(dispatch, params) {
        const data = await DashboardProcess.nullify(params);
        if (data.message) {
            dispatch({ type : "DASHBOARD_PROCESS_LIST", data });
        }
        return data;
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate = DateTime.formatString(elem.createdTime);
    elem.stateDate   = DateTime.formatString(elem.orderStateTime);
    elem.numberText  = `#${elem.orderNumber}`;
    elem.name        = NLS.format("ORDERS_ITS_NAME", elem.orderNumber);
    elem.productText = NLS.pluralize("ORDERS_PRODUCTS", elem.totalProducts);
    elem.color       = elem.isColumnPending ? DateTime.getExpiredColor(elem.createdTime, 30, 60) : "";
    return elem;
}

/**
 * Parses a single State
 * @param {Object} elem
 * @returns {Object}
 */
function parseState(elem) {
    elem.createdTimeDate = DateTime.formatString(elem.createdTime);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "DASHBOARD_PROCESS_LIST", "DASHBOARD_PROCESS_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "DASHBOARD_PROCESS_LOADING":
        return {
            ...state,
            loading       : true,
        };

    case "DASHBOARD_PROCESS_LIST":
        return {
            ...state,
            loading       : false,
            error         : false,
            canCreate     : action.data.canCreate,
            canEdit       : action.data.canEdit,
            list          : Utils.parseList(action.data.list, parseElem),
            tooltips      : action.data.tooltips,
        };

    case "DASHBOARD_PROCESS_ELEM":
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            elem          : parseElem(action.data.elem),
            recipeFiles   : action.data.elem?.recipeFiles || [],
            states        : Utils.parseList(action.data.states, parseState),
            products      : action.data.products,
            rejectReasons : action.data.rejectReasons,
            cancelReasons : action.data.cancelReasons,
            coverageTypes : action.data.coverageTypes,
            coverages     : action.data.coverages,
            saleTypes     : action.data.saleTypes,
            messages      : action.data.messages,
            actions       : action.data.actions,
            chatItems     : Commons.parseMessages(action.data.messages, action.data.actions),
            tooltips      : action.data.tooltips,
            canWaitOrder  : action.data.canWaitOrder,
            lastUpdate    : Utils.getCurrentTime(),
        };

    case "DASHBOARD_PROCESS_RECIPE":
        return {
            ...state,
            recipeFiles   : action.data.elem?.recipeFiles || [],
            messages      : action.data.messages,
            actions       : action.data.actions,
            chatItems     : Commons.parseMessages(action.data.messages, action.data.actions),
            lastUpdate    : Utils.getCurrentTime(),
        };

    case "DASHBOARD_PROCESS_MESSAGES": {
        const messages = Commons.updateList("orderMessageID", state.messages, action.data.messages);
        const actions  = Commons.updateList("orderActionID", state.actions, action.data.actions);
        return {
            ...state,
            elem          : parseElem(action.data.elem),
            messages      : messages,
            actions       : actions,
            chatItems     : Commons.parseMessages(messages, actions),
            lastUpdate    : Utils.getCurrentTime(),
        };
    }

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);

import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Coverage Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CoverageEdit(props) {
    const { open, elemID, subsidiaryID, onClose, onSubmit } = props;

    const { coverages, retirementTypes, gateways, statuses } = Store.useState("subsidiaryCoverage");
    const { editElem } = Store.useAction("subsidiaryCoverage");


    // The Initial Data
    const initialData = {
        subsidiaryID         : 0,
        subsidiaryCoverageID : 0,
        coverageID           : 0,
        defaultRetirements   : 0,
        retirementTypeIDs    : [],
        defaultGateways      : 0,
        gatewayCodes         : [],
        status               : "Active",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("subsidiaryCoverage", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("subsidiaryCoverage", open, elemID, { subsidiaryID }, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="coverage"
        title={elemID ? "COVERAGES_EDIT_TITLE" : "COVERAGES_ADD_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="select"
            name="coverageID"
            label="COVERAGES_SINGULAR"
            options={coverages}
            value={data.coverageID}
            error={errors.coverageID}
            onChange={handleChange}
            isRequired
        />

        <InputField
            type="toggle"
            name="defaultRetirements"
            label="COVERAGES_DEFAULT_RETIREMENTS"
            value={data.defaultRetirements}
            error={errors.defaultRetirements}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!!data.defaultRetirements}
            type="chooser"
            name="retirementTypeIDs"
            label="RETIREMENT_TYPES_NAME"
            options={retirementTypes}
            value={data.retirementTypeIDs}
            error={errors.retirementTypeIDs}
            onChange={handleChange}
            isRequired
        />

        <InputField
            type="toggle"
            name="defaultGateways"
            label="COVERAGES_DEFAULT_GATEWAYS"
            value={data.defaultGateways}
            error={errors.defaultGateways}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!!data.defaultGateways}
            type="chooser"
            name="gatewayCodes"
            label="GATEWAYS_NAME"
            options={gateways}
            value={data.gatewayCodes}
            error={errors.gatewayCodes}
            onChange={handleChange}
            isRequired
        />

        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CoverageEdit.propTypes = {
    open         : PropTypes.bool.isRequired,
    onClose      : PropTypes.func.isRequired,
    onSubmit     : PropTypes.func.isRequired,
    elemID       : PropTypes.number,
    subsidiaryID : PropTypes.number,
};

export default CoverageEdit;

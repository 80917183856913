import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import useList              from "Dashboard/Hooks/List";

// Components
import TooltipEdit          from "./TooltipEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";



/**
 * The Cancel Reason List
 * @returns {React.ReactElement}
 */
function TooltipList() {
    const { fetch, load } = useList("tooltip");

    const data = Store.useState("tooltip");
    const { loading, canEdit, list, total, sort } = data;


    // The Current State
    const [ action, elemCode, startAction, endAction ] = Action.useActionCode();


    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };


    // Do the Render
    return <Main>
        <Header message="TOOLTIPS_NAME" icon="tooltip">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="TOOLTIPS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="description" message="GENERAL_DESCRIPTION" isTitle />
                    <TableHeader field="content"     message="GENERAL_CONTENT"     />
                    <TableHeader field="position"    message="GENERAL_POSITION"    maxWidth="80" align="center" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.tooltipCode} elemID={elem.tooltipCode}>
                        <TableCell message={elem.description} />
                        <TableCell message={elem.content}     />
                        <TableCell message={elem.position}    />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT" message="TOOLTIPS_EDIT_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <TooltipEdit
            open={action.isVCE}
            elemCode={elemCode}
            onSubmit={handleEdit}
            onClose={endAction}
        />
    </Main>;
}

export default TooltipList;

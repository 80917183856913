import React                from "react";
import Store                from "Dashboard/Core/Store";
import Commons              from "Utils/Commons";

// Components
import DoughnutChart        from "../../Utils/Charts/DoughnutChart";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import ColorCircle          from "Dashboard/Components/Common/ColorCircle";



/**
 * The Commercial Card Types
 * @returns {React.ReactElement}
 */
function CommercialCardTypes() {
    const { cardTypeTotals } = Store.useState("dashboardCommercial");


    // Do the Render
    return <DoughnutChart
        title="DASHBOARD_CARD_TYPE_TITLE"
        field="netTotal"
        data={cardTypeTotals}
    >
        <Table noSorting notFixed>
            <TableHead>
                <TableHeader field="cardName"    message="CARD_TYPES_SINGULAR" isFlex isTitle />
                <TableHeader field="bankName"    message="BANKS_SINGULAR"      isFlex />
                <TableHeader field="totalOrders" message="ORDERS_SALES"        isFlex maxWidth="80" align="center" />
                <TableHeader field="netTotal"    message="DASHBOARD_BILLING"   isFlex maxWidth="120" align="right" />
            </TableHead>
            <TableBody>
                {cardTypeTotals.map((elem, index) => <TableRow key={elem.name}>
                    <TableCell>
                        <ColorCircle color={Commons.getColor(index)} />
                        {elem.cardName}
                    </TableCell>
                    <TableCell message={elem.bankName}    />
                    <TableCell message={elem.totalOrders} />
                    <TableCell message={Commons.formatNumber(elem.netTotal, true)} />
                </TableRow>)}
            </TableBody>
        </Table>
    </DoughnutChart>;
}

export default CommercialCardTypes;

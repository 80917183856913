import React                from "react";
import Store                from "Dashboard/Core/Store";

// Dashboard
import BarIcon              from "Dashboard/Components/Core/BarIcon";



/**
 * The Super Operative Menu
 * @returns {React.ReactElement}
 */
function SuperOperativeMenu() {
    const { closeMenu } = Store.useAction("core");
    const { isSuperOperative } = Store.useState("auth");


    // Do the Render
    if (!isSuperOperative) {
        return <React.Fragment />;
    }
    return <>
        <BarIcon
            url="DASHBOARD"
            icon="home"
            message="DASHBOARD_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            url="ORDERS"
            icon="order"
            message="ORDERS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
    </>;
}

export default SuperOperativeMenu;

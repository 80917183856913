import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { EmailQueue }       from "Utils/API";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canFilter : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    filters   : {
        search   : "",
        fromDate : "",
        toDate   : "",
    },
    sort      : {
        orderBy  : "createdTime",
        orderAsc : 0,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "EMAIL_QUEUE_LOADING" });
    },

    /**
     * Fetches the Email Queue List
     * @param {Function} dispatch
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, filters = {}, sort = {}) {
        const data   = await EmailQueue.getAll({ ...filters, ...sort });
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "EMAIL_QUEUE_LIST", data });
    },

    /**
     * Fetches a single Email Queue Element
     * @param {Function} dispatch
     * @param {Number}   emailID
     * @returns {Promise}
     */
    async fetchElem(dispatch, emailID) {
        const data = await EmailQueue.getOne({ emailID });
        dispatch({ type : "EMAIL_QUEUE_ELEM", data });
    },

    /**
     * Resends an Email
     * @param {Function}          dispatch
     * @param {(Number[]|Number)} emailID
     * @returns {Promise}
     */
    resendElem(dispatch, emailID) {
        const emailIDs = JSON.stringify(Utils.toArray(emailID));
        return EmailQueue.resend({ emailIDs });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.sentDate        = DateTime.formatIf(elem.sentTime, "dashes");
    elem.sentDateTime    = DateTime.formatIf(elem.sentTime, "dashesTime");
    elem.sendToText      = Utils.combineValues(elem, "sendTo");
    elem.emailResultText = NLS.get("SELECT_EMAIL_RESULTS", elem.emailResult);

    if (elem.isPending) {
        elem.resultText  = NLS.get("GENERAL_PENDING");
        elem.resultClass = "text-warning";
    } else if (elem.isError) {
        elem.resultText  = NLS.get("GENERAL_FAIL");
        elem.resultClass = "text-red";
    } else {
        elem.resultText  = NLS.get("GENERAL_SUCCESS");
        elem.resultClass = "text-green";
    }
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "EMAIL_QUEUE_LIST", "EMAIL_QUEUE_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "EMAIL_QUEUE_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "EMAIL_QUEUE_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canFilter : action.data.canFilter,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
            filters   : action.data.filters,
            sort      : action.data.sort,
        };

    case "EMAIL_QUEUE_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : parseElem(action.data.elem),
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);

import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Commons              from "Utils/Commons";

// Components
import BarChart             from "../../Utils/Charts/BarChart";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableHeader          from "Dashboard/Components/Table/TableHeader";



/**
 * The Commercial Products
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CommercialProducts(props) {
    const { byUnits } = props;

    const { productUnits, productTotals } = Store.useState("dashboardCommercial");


    // Do the Render
    const data = byUnits ? productUnits : productTotals;

    return <BarChart
        title={byUnits ? "DASHBOARD_PRODUCT_UNITS_TITLE" : "DASHBOARD_PRODUCT_SALES_TITLE"}
        data={data}
        field={byUnits ? "effectiveUnits" : "billingTotal"}
        isPrice={!byUnits}
    >
        <Table noSorting notFixed>
            <TableHead>
                <TableHeader field="name"           message="PRODUCTS_SINGULAR"       isFlex isTitle />
                <TableHeader field="benvidaID"      message="GENERAL_CODE_BENVIDA"    isFlex maxWidth="120" align="center" />
                <TableHeader field="effectiveUnits" message="DASHBOARD_UNITS"         isFlex maxWidth="70" align="center" />
                <TableHeader field="billingTotal"   message="DASHBOARD_GROSS_BILLING" isFlex maxWidth="120" align="right" />
            </TableHead>
            <TableBody>
                {data.map((elem, index) => <TableRow key={elem.name}>
                    <TableCell message={elem.name}           />
                    <TableCell message={elem.benvidaID}      />
                    <TableCell message={elem.effectiveUnits} />
                    <TableCell message={Commons.formatNumber(elem.billingTotal, true)} />
                </TableRow>)}
            </TableBody>
        </Table>
    </BarChart>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CommercialProducts.propTypes = {
    byUnits : PropTypes.bool,
};

export default CommercialProducts;

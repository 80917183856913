import React                from "react";
import PropTypes            from "prop-types";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Contact View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ContactView(props) {
    const { open, elemID, onClose } = props;

    // Load the Data
    const { loading, elem } = useDialog("contact", open, elemID);


    // Do the Render
    return <ViewDialog
        open={open}
        icon="contact"
        title="CONTACTS_VIEW_TITLE"
        onClose={onClose}
        isLoading={loading}
        withSpacing
    >
        <Columns>
            <ViewField
                label="GENERAL_FIRST_NAME"
                value={elem.firstName}
            />
            <ViewField
                label="GENERAL_LAST_NAME"
                value={elem.lastName}
            />

            <ViewField
                label="GENERAL_EMAIL"
                value={elem.email}
                showEmpty
            />
            <ViewField
                label="CONTACTS_SEND_ORDER_BY_EMAIL"
                value={elem.sendByEmailText}
            />

            <ViewField
                label="GENERAL_PHONE"
                value={elem.phone}
                showEmpty
            />
            <ViewField
                label="CONTACTS_SEND_ORDER_BY_WHATSAPP"
                value={elem.sendByWhatsAppText}
            />

            <ViewField
                className="columns-double"
                label="SUBSIDIARIES_NAME"
                value={elem.subsidiariesText}
            />
        </Columns>
    </ViewDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ContactView.propTypes = {
    open    : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    elemID  : PropTypes.number,
};

export default ContactView;

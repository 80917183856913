import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Dashboard
import Alert                from "Dashboard/Components/Form/Alert";
import Button               from "Dashboard/Components/Form/Button";
import HyperLink            from "Dashboard/Components/Link/HyperLink";



// Styles
const Form = Styled.form`
    display: flex;
    flex-direction: column;
    gap: var(--main-gap);
`;

const Submit = Styled(Button)`
    padding: 12px 16px;
`;

const Footer = Styled.section`
    text-align: center;
`;



/**
 * The Auth Form
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AuthForm(props) {
    const {
        error, url, link, button,
        isLoading, isDisabled, onSubmit, children,
    } = props;

    // Handles the Submit
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit();
    };


    // Do the Render
    const hasLink = !!url && !!link;

    return <Form onSubmit={handleSubmit}>
        <Alert variant="error" message={error} />

        {children}

        <Submit
            variant="primary"
            onClick={handleSubmit}
            isDisabled={isLoading || isDisabled}
            message={button}
            fullWidth
        />
        {hasLink && <Footer>
            <HyperLink
                variant="primary"
                url={url}
                message={link}
                useBase
            />
        </Footer>}
    </Form>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AuthForm.propTypes = {
    error      : PropTypes.string,
    url        : PropTypes.string,
    link       : PropTypes.string,
    button     : PropTypes.string.isRequired,
    isLoading  : PropTypes.bool.isRequired,
    isDisabled : PropTypes.bool,
    onSubmit   : PropTypes.func.isRequired,
    children   : PropTypes.any,
};

export default AuthForm;

import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Bank Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function BankEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { editElem } = Store.useAction("bank");


    // The Initial Data
    const initialData = {
        bankID       : 0,
        fantasyName  : "",
        socialReason : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("bank", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("bank", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="bank"
        title={elemID ? "BANKS_EDIT_TITLE" : "BANKS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="fantasyName"
            label="BANKS_FANTASY_NAME"
            value={data.fantasyName}
            error={errors.fantasyName}
            onChange={handleChange}
        />
        <InputField
            name="socialReason"
            label="GENERAL_SOCIAL_REASON"
            value={data.socialReason}
            error={errors.socialReason}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
BankEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default BankEdit;

import React                from "react";
import PropTypes            from "prop-types";
import Confetti             from "react-confetti";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Audios
// @ts-ignore
import Sale                 from "Styles/Audio/sale.mp3";
// @ts-ignore
import Cancel               from "Styles/Audio/cancel.mp3";



/**
 * The Daily Animation
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DailyAnimation(props) {
    const { isToday } = props;

    const { totals : { effectiveOrders } } = Store.useState("dashboardDaily");


    // The References
    const saleRef     = React.useRef(null);
    const cancelRef   = React.useRef(null);
    const confettiRef = React.useRef(0);

    // The Current State
    const [ initialized,  setInitialized  ] = React.useState(false);
    const [ lastSales,    setLastSales    ] = React.useState(-1);
    const [ showConfetti, setShowConfetti ] = React.useState(false);
    const [ runConfetti,  setRunConfetti  ] = React.useState(false);


    // Initial Fetch
    React.useEffect(() => {
        setInitialized(true);
        saleRef.current   = new Audio(Sale);
        cancelRef.current = new Audio(Cancel);

        return () => {
            Utils.clearTimeout(confettiRef);
        };
    }, []);


    // Handle the Update
    React.useEffect(() => {
        if (!isToday) {
            return;
        }
        if (lastSales < 0) {
            setLastSales(effectiveOrders);
            return;
        }
        if (!initialized) {
            return;
        }

        if (effectiveOrders > lastSales) {
            setShowConfetti(true);
            setRunConfetti(true);
            Commons.playAudio(saleRef);
            confettiRef.current = window.setTimeout(() => {
                setRunConfetti(false);
            }, 5 * 1000);
        } else if (effectiveOrders < lastSales) {
            Commons.playAudio(cancelRef);
        }
        setLastSales(effectiveOrders);
    }, [ effectiveOrders ]);


    // Do the Render
    if (!showConfetti) {
        return <React.Fragment />;
    }
    return <Confetti
        recycle={runConfetti}
        width={window.innerWidth}
        onConfettiComplete={() => setShowConfetti(false)}
    />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DailyAnimation.propTypes = {
    isToday : PropTypes.bool.isRequired,
};

export default DailyAnimation;

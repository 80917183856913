import React                from "react";
import PropTypes            from "prop-types";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Change Catalog View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChangeCatalogView(props) {
    const { open, elemID, onClose } = props;

    // Load the Data
    const { loading, elem } = useDialog("changeCatalog", open, elemID);


    // Do the Render
    return <ViewDialog
        open={open}
        icon="change-catalog"
        title="CHANGE_CATALOGS_VIEW_TITLE"
        onClose={onClose}
        isLoading={loading}
        withSpacing
    >
        <Columns>
            <ViewField
                label="GENERAL_CREATED_AT"
                value={elem.createdDate}
            />
            <ViewField
                label="GENERAL_TYPE"
                value={elem.modificationTypeText}
            />
            <ViewField
                label="CHANGE_CATALOGS_MODULE"
                value={elem.moduleText}
            />
            <ViewField
                label="GENERAL_CODE_ALFABETA"
                value={elem.alfabetaID}
            />

            <ViewField
                label="CHANGE_CATALOGS_PROCESSED_AT"
                value={elem.processDate}
            />
            <ViewField
                label="CHANGE_CATALOGS_PROCESSED_BY"
                value={elem.processCredentialName}
            />

            <ViewField
                label="CHANGE_CATALOGS_VALIDATED_AT"
                value={elem.validateDate}
            />
            <ViewField
                label="CHANGE_CATALOGS_VALIDATED_BY"
                value={elem.validateCredentialName}
            />
        </Columns>

        <Columns className="top-space-big">
            <ViewField
                label="CHANGE_CATALOGS_OLD_NAME_ALFABETA"
                value={elem.oldNameAlfabeta}
                showEmpty
            />
            <ViewField
                label="CHANGE_CATALOGS_NEW_NAME_ALFABETA"
                value={elem.newNameAlfabeta}
                showEmpty
            />
            <ViewField
                label="CHANGE_CATALOGS_OLD_NAME_BENVIDA"
                value={elem.oldNameBenvida}
                showEmpty
            />
            <ViewField
                label="CHANGE_CATALOGS_NEW_NAME_BENVIDA"
                value={elem.newNameBenvida}
                showEmpty
            />
        </Columns>
    </ViewDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChangeCatalogView.propTypes = {
    open    : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    elemID  : PropTypes.number,
};

export default ChangeCatalogView;

import React                from "react";
import Styled               from "styled-components";

// Components
import DailyTotal           from "./DailyTotal";



// Styles
const Container = Styled.ul`
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    gap: var(--main-gap);
    list-style: none;
    margin: 0;
    padding: 0;

    @media (max-width: 1500px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    @media (max-width: 1000px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media (max-width: 600px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`;



/**
 * The Daily Totals
 * @returns {React.ReactElement}
 */
function DailyTotals() {


    // Do the Render
    return <Container>
        <DailyTotal
            message="DASHBOARD_GROSS_BILLING"
            property="grossBilling"
            isPrice
        />
        <DailyTotal
            message="DASHBOARD_NET_BILLING"
            property="netBilling"
            isPrice
        />
        <DailyTotal
            message="DASHBOARD_AVERAGE_TICKET"
            property="averageTicket"
            isPrice
        />

        <DailyTotal
            message="DASHBOARD_PENDING_ORDERS"
            property="pendingOrders"
        />
        <DailyTotal
            message="DASHBOARD_TOTAL_ORDERS"
            property="totalOrders"
        />
        <DailyTotal
            message="DASHBOARD_EFFECTIVE_ORDERS"
            property="effectiveOrders"
        />

        <DailyTotal
            message="DASHBOARD_BENVIDA_FEE_CHARGED"
            property="benvidaFeeCharged"
            isPrice
        />
        <DailyTotal
            message="DASHBOARD_BENVIDA_FEE_TO_CHARGE"
            property="benvidaFeeToCharge"
            isPrice
        />
    </Container>;
}

export default DailyTotals;

import React                from "react";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import { Medicine }         from "Utils/API";

// Components
import MedicineFilter       from "./Dialogs/MedicineFilter";
import MedicineEdit         from "./Dialogs/MedicineEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import Html                 from "Dashboard/Components/Common/Html";



/**
 * The Medicine List
 * @returns {React.ReactElement}
 */
function MedicineList() {
    const { fetch, load, loadFilter } = useList("medicine");
    const navigate = Navigate.useGoto();

    const data = Store.useState("medicine");
    const { loading, canEdit, list, total, filters, sort } = data;

    // The Current State
    const [ exporting, setExporting ] = React.useState(false);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isExport) {
            setExporting(true);
            endAction();
            const source = Medicine.export({ ...filters });
            await Utils.download(source, NLS.get("MEDICINES_FILE_NAME"));
            setExporting(false);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
    };

    // Handles the Edit Submit
    const handleEdit = (response) => {
        if (response.medicineID) {
            navigate("CATALOG", "MEDICINES", response.medicineID);
        } else {
            fetch();
        }
        endAction();
    };


    // Do the Render
    return <Main>
        <Header message="MEDICINES_NAME" icon="medicine">
            <ActionList data={data} onAction={handleAction} isExporting={exporting} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="MEDICINES_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="alfabetaID"     message="GENERAL_CODE"          maxWidth="80" />
                    <TableHeader field="name"           message="GENERAL_NAME"          isTitle />
                    <TableHeader field="laboratoryName" message="LABORATORIES_SINGULAR" />
                    <TableHeader field="monoDrugName"   message="MONO_DRUGS_SINGULAR"   />
                    <TableHeader field="saleTypeName"   message="SALE_TYPES_SINGULAR"   />
                    <TableHeader field="price"          message="GENERAL_PRICE"         align="right" maxWidth="80" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.medicineID} elemID={elem.medicineID}>
                        <TableCell message={elem.alfabetaID}     />
                        <TableCell message={elem.name}           />
                        <TableCell message={elem.laboratoryName} />
                        <TableCell message={elem.monoDrugName}   />
                        <TableCell message={elem.saleTypeName}   />
                        <TableCell><Html>{elem.priceText}</Html></TableCell>
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW" message="MEDICINES_VIEW_TITLE" navigate />
                    <TableAction action="EDIT" message="MEDICINES_EDIT_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <MedicineFilter
            open={action.isFilter}
            filters={filters}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <MedicineEdit
            open={action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
    </Main>;
}

export default MedicineList;

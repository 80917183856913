import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { ActionLog }        from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    canCreate : false,
    canEdit   : false,
    canFilter : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    filters   : {
        credentialID : 0,
        fromDate     : "",
        toDate       : "",
    },
    sort      : {
        page   : 0,
        amount : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "ACTION_LOG_LOADING" });
    },

    /**
     * Fetches the Action Log
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        let data = null;

        switch (type) {
        case "CLIENT":
            params.clientID = elemID;
            data = await ActionLog.getAllForClient(params);
            break;
        case "PROFILE":
            data = await ActionLog.getAllForUser(params);
            break;
        default:
            data = await ActionLog.getAll(params);
            break;
        }
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "ACTION_LOG_LIST", data });
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, (elem) => {
        elem.timeText = DateTime.formatDate(elem.createdTime, "dashesSeconds");

        for (const action of elem.actions) {
            action.timeText   = DateTime.formatDate(action.createdTime, "dashesSeconds");
            action.moduleName = NLS.getModule(action.module);
            action.actionName = NLS.getAction(action.module, action.action);
            action.dataText   = action.dataID ? action.dataID.join(", ") : "";
        }
    });
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "ACTION_LOG_LIST")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "ACTION_LOG_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "ACTION_LOG_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canFilter : action.data.canFilter,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : parseList(action.data.list),
            total     : action.data.total,
            filters   : action.data.filters,
            sort      : action.data.sort,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);

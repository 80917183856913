import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import CommercialTotals     from "./Components/CommercialTotals";
import CommercialChartBar   from "./Components/CommercialChartBar";
import CommercialChartLine  from "./Components/CommercialChartLine";
import CommercialRetirement from "./Components/CommercialRetirements";
import CommercialPharmacies from "./Components/CommercialPharmacies";
import CommercialSubsidiary from "./Components/CommercialSubsidiaries";
import CommercialProducts   from "./Components/CommercialProducts";
import CommercialMacroCats  from "./Components/CommercialMacroCategories";
import CommercialCategories from "./Components/CommercialCategories";
import CommercialSubCats    from "./Components/CommercialSubCategories";
import CommercialCoverage   from "./Components/CommercialCoverageTypes";
import CommercialPayGateway from "./Components/CommercialPaymentGateways";
import CommercialPayTypes   from "./Components/CommercialPaymentTypes";
import CommercialPayMethods from "./Components/CommercialPaymentMethods";
import CommercialBanks      from "./Components/CommercialBanks";
import CommercialCardTypes  from "./Components/CommercialCardTypes";

// Dashboard
import Columns              from "Dashboard/Components/Form/Columns";



// Styles
const Container = Styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--main-gap);
`;



/**
 * The Commercial Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CommercialContent(props) {
    const { isHidden } = props;

    const { isAnyAdmin } = Store.useState("auth");
    const { filters, dailyChart, monthlyChart } = Store.useState("dashboardCommercial");
    const { fetchList } = Store.useAction("dashboardCommercial");


    // Auto Update
    Utils.useAutoUpdate(fetchList, isHidden, filters);


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <Container>
        <CommercialTotals />
        <Columns>
            <CommercialChartBar
                title="DASHBOARD_DAILY_BILLING_TITLE"
                data={dailyChart}
                isDaily
            />
            <CommercialChartBar
                title="DASHBOARD_MONTHLY_BILLING_TITLE"
                data={monthlyChart}
            />

            <CommercialChartLine
                title="DASHBOARD_DAILY_ORDERS_TITLE"
                data={dailyChart}
                isDaily
            />

            <CommercialChartLine
                title="DASHBOARD_MONTHLY_ORDERS_TITLE"
                data={monthlyChart}
            />
        </Columns>

        <CommercialRetirement />
        <CommercialSubsidiary />
        {isAnyAdmin && <CommercialPharmacies />}

        <CommercialProducts />
        <CommercialProducts byUnits />
        <CommercialMacroCats />
        <CommercialCategories />
        <CommercialSubCats />

        <CommercialCoverage />
        <CommercialPayGateway />
        <CommercialPayTypes />
        <CommercialPayMethods />
        <CommercialBanks />
        <CommercialCardTypes />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CommercialContent.propTypes = {
    isHidden : PropTypes.bool.isRequired,
};

export default CommercialContent;

import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { LogisticReason }   from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canFilter : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    sort      : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "LOGISTIC_REASON_LOADING" });
    },

    /**
     * Fetches the Logistic Reason List
     * @param {Function} dispatch
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, params = {}) {
        const data = await LogisticReason.getAll(params);
        data.sort = params;
        dispatch({ type : "LOGISTIC_REASON_LIST", data });
    },

    /**
     * Fetches a single Logistic Reason
     * @param {Function} dispatch
     * @param {String}   logisticError
     * @returns {Promise}
     */
    async fetchElem(dispatch, logisticError) {
        const data = await LogisticReason.getOne({ logisticError });
        dispatch({ type : "LOGISTIC_REASON_ELEM", data });
    },

    /**
     * Edits/Creates a Logistic Reason
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return LogisticReason.edit(data);
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "LOGISTIC_REASON_LIST", "LOGISTIC_REASON_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "LOGISTIC_REASON_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "LOGISTIC_REASON_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canFilter : action.data.canFilter,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : action.data.list,
            total     : action.data.total,
            sort      : action.data.sort,
        };

    case "LOGISTIC_REASON_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : action.data.elem,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);

import React                from "react";
import PropTypes            from "prop-types";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import PageContainer        from "Dashboard/Components/Page/PageContainer";
import PageAccordion        from "Dashboard/Components/Page/PageAccordion";
import PageFooter           from "Dashboard/Components/Page/PageFooter";
import AccordionItem        from "Dashboard/Components/Accordion/AccordionItem";
import InputField           from "Dashboard/Components/Form/InputField";
import ViewField            from "Dashboard/Components/Form/ViewField";
import Columns              from "Dashboard/Components/Form/Columns";
import Button               from "Dashboard/Components/Form/Button";



/**
 * The Gateway Page
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function GatewayPage(props) {
    const { type } = props;
    const { subsidiaryID } = Navigate.useParams();

    const { charging, canEdit, edition, content, gateways } = Store.useState("subsidiaryGateway");
    const { fetchAll, editAll } = Store.useAction("subsidiaryGateway");


    // The Current Data
    const [ selected, setSelected ] = React.useState("");

    // The Initial Data
    const initialData = {
        gateways : {},
    };

    // Handles the Gateway
    const handleGateway = (gatewayCode, name, value) => {
        const elem     = { ...data.gateways[gatewayCode], [name] : value };
        const gateways = { ...data.gateways, [gatewayCode] : elem };
        handleChange("gateways", gateways);
    };

    // Handles the Edit
    const handleEdit = async () => {
        const response = await editAll({
            subsidiaryID,
            gateways : JSON.stringify(data.gateways),
        });
        if (response.success) {
            fetchAll(type, subsidiaryID);
        }
    };

    // The Form State
    const {
        loading, data, errors, setElem, handleChange, handleSubmit,
    } = useForm("subsidiaryGateway", initialData, handleEdit);


    // Load the Data
    React.useEffect(() => {
        if (subsidiaryID) {
            fetchAll(subsidiaryID);
        }
    }, [ subsidiaryID ]);

    // Store the Data
    React.useEffect(() => {
        if (gateways.length) {
            setSelected(gateways[0].gatewayCode);
            setElem({ gateways : content });
        }
    }, [ edition ]);


    // Do the Render
    return <Main>
        <Header message="GATEWAYS_NAME" icon="gateway" />
        <Content isLoading={charging || loading}>
            <PageContainer error={errors.form}>
                <PageAccordion selected={selected} onChange={setSelected}>
                    {gateways.map((elem) => {
                        const item = data.gateways[elem.gatewayCode] ?? {};

                        return <AccordionItem
                            key={elem.gatewayCode}
                            value={elem.gatewayCode}
                            message={elem.name}
                            withGap
                        >
                            <InputField
                                type="toggle"
                                name="isActive"
                                label="GENERAL_IS_ACTIVE_FEM"
                                value={item.isActive || 0}
                                onChange={(name, value) => handleGateway(elem.gatewayCode, name, value)}
                                isDisabled={!canEdit}
                                withBorder
                            />

                            <Columns isHidden={!elem.hasLink}>
                                <ViewField
                                    label="GATEWAYS_USER_ID"
                                    value={item.providerID}
                                    showEmpty
                                />
                                <InputField
                                    type="toggle"
                                    name="isLinked"
                                    label="GATEWAYS_IS_LINKED"
                                    value={item.isLinked || 0}
                                    onChange={(name, value) => handleGateway(elem.gatewayCode, name, value)}
                                    isDisabled={!canEdit || !item.providerID}
                                    withBorder
                                />
                            </Columns>
                        </AccordionItem>;
                    })}
                </PageAccordion>

                <PageFooter isHidden={!canEdit}>
                    <Button
                        variant="primary"
                        message="GENERAL_SAVE"
                        onClick={() => handleSubmit()}
                        isDisabled={loading}
                    />
                </PageFooter>
            </PageContainer>
        </Content>
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
GatewayPage.propTypes = {
    type : PropTypes.string.isRequired,
};

export default GatewayPage;

import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import MediaType            from "Dashboard/Core/MediaType";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Tooltip Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TooltipEdit(props) {
    const { open, elemCode, onClose, onSubmit } = props;

    const { editElem } = Store.useAction("tooltip");


    // The Initial Data
    const initialData = {
        tooltipCode : 0,
        description : "",
        content     : "",
        file        : "",
        position    : "",
        isVideo     : 0,
        isPDF       : 0,
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("tooltip", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("tooltip", open, elemCode, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="tooltip"
        title="TOOLTIPS_EDIT_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="description"
            label="GENERAL_DESCRIPTION"
            value={data.description}
            error={errors.description}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="textarea"
            name="content"
            label="GENERAL_TEXT"
            value={data.content}
            error={errors.content}
            onChange={handleChange}
        />
        <MediaField
            isHidden={!data.isVideo}
            name="file"
            label="GENERAL_VIDEO"
            value={data.file}
            error={errors.file}
            onChange={handleChange}
            mediaType={MediaType.VIDEO}
        />
        <MediaField
            isHidden={!data.isPDF}
            name="file"
            label="GENERAL_PDF"
            value={data.file}
            error={errors.file}
            onChange={handleChange}
            mediaType={MediaType.PDF}
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TooltipEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemCode : PropTypes.string,
};

export default TooltipEdit;

import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Catalog Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CatalogFilter(props) {
    const { open, filters, onClose, onSubmit } = props;

    const { isCatalog } = Store.useState("auth");
    const { credentials } = Store.useState("dashboardCatalog");


    // The Initial Data
    const initialData = {
        period       : 0,
        fromDate     : "",
        toDate       : "",
        credentialID : [],
    };

    // The Filter State
    const {
        loading, data, handleChange, handlePeriod, handleSubmit,
    } = useFilter("dashboardCatalog", open, initialData, filters, onSubmit);


    // Do the Render
    return <FilterDialog
        open={open}
        title="DASHBOARD_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        noAutoFocus
    >
        <Columns amount="3">
            <InputField
                type="select"
                name="period"
                label="PERIOD_NAME"
                value={data.period}
                onChange={handlePeriod}
                options={Period.getSelect()}
            />
            <InputField
                type="date"
                name="fromDate"
                label="GENERAL_FROM"
                value={data.fromDate}
                maxValue="2999-12-31"
                onChange={handleChange}
            />
            <InputField
                type="date"
                name="toDate"
                label="GENERAL_TO"
                value={data.toDate}
                maxValue="2999-12-31"
                onChange={handleChange}
            />
        </Columns>

        <InputField
            isHidden={isCatalog}
            type="chooser"
            name="credentialID"
            label="USERS_NAME"
            options={credentials}
            value={data.credentialID}
            onChange={handleChange}
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CatalogFilter.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    filters  : PropTypes.object,
};

export default CatalogFilter;

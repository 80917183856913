import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Commission Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CommissionFilter(props) {
    const { open, filters, onClose, onSubmit } = props;

    const { pharmacies, subsidiaries, subsidiariesPerPharmacy } = Store.useState("user");


    // The Initial Data
    const initialData = {
        exclude      : 0,
        pharmacyID   : [],
        subsidiaryID : [],
    };

    // The Filter State
    const {
        loading, data, handleChange, handleSubmit,
    } = useFilter("user", open, initialData, filters, onSubmit);

    // Returns a select of subsidiaries based on the pharmacies
    const getSubsidiaries = Utils.useSelectList(loading, data.pharmacyID, subsidiaries, subsidiariesPerPharmacy);


    // Do the Render
    return <FilterDialog
        open={open}
        title="COMMISSIONS_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="toggle"
            name="exclude"
            label="GENERAL_EXCLUDE_FILTER"
            value={data.exclude}
            onChange={handleChange}
            withBorder
        />
        <InputField
            type="chooser"
            name="pharmacyID"
            label="PHARMACIES_NAME"
            options={pharmacies}
            value={data.pharmacyID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="subsidiaryID"
            label="SUBSIDIARIES_NAME"
            options={getSubsidiaries()}
            value={data.subsidiaryID}
            onChange={handleChange}
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CommissionFilter.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    filters  : PropTypes.object,
};

export default CommissionFilter;

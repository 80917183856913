import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import ViewColumns          from "Components/Utils/View/ViewColumns";
import ViewItem             from "Components/Utils/View/ViewItem";



// Styles
const Container = Styled(ViewColumns)`
    grid-area: client;
`;



/**
 * The Order Client
 * @returns {React.ReactElement}
 */
function OrderClient() {
    const { isAnyAdmin } = Store.useState("auth");
    const { elem } = Store.useState("order");


    // Do the Render
    return <Container message="ORDERS_CLIENT_INFO" amount="2">
        <ViewItem
            message="GENERAL_FIRST_NAME"
            value={elem.clientFirstName}
        />
        <ViewItem
            message="GENERAL_LAST_NAME"
            value={elem.clientLastName}
        />
        <ViewItem
            message="CLIENTS_DNI"
            value={elem.clientDni}
        />

        <ViewItem
            isHidden={!isAnyAdmin}
            message="GENERAL_PHONE"
            value={elem.clientPhone}
        />
        <ViewItem
            isHidden={!isAnyAdmin}
            className="columns-double"
            message="GENERAL_EMAIL"
            value={elem.clientEmail}
        />

        <ViewItem
            message="GENERAL_LOCATION"
            value={elem.clientLocation}
        />
        <ViewItem
            message="GENERAL_ADDRESS_ADDITIONAL"
            value={elem.clientAddressAdditional}
        />
    </Container>;
}

export default OrderClient;

import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import Commons              from "Utils/Commons";

// Components
import ProcessHeader        from "./Utils/ProcessHeader";
import ProcessFooter        from "./Utils/ProcessFooter";
import ProcessClient        from "./Components/ProcessClient";
import ProcessRequest       from "./Components/ProcessRequest";
import ProcessCoverage      from "./Components/ProcessCoverage";
import ProcessProducts      from "./Components/ProcessProducts";
import ProcessInvoice       from "./Components/ProcessInvoice";
import ProcessOrder         from "./Components/ProcessOrder";
import ProcessStates        from "./Components/ProcessStates";
import ProcessTotals        from "./Components/ProcessTotals";
import ProcessChat          from "./Components/ProcessChat";

// Dashboard
import Dialog               from "Dashboard/Components/Dialog/Dialog";
import DialogBody           from "Dashboard/Components/Dialog/DialogBody";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import PromptDialog         from "Dashboard/Components/Dialogs/PromptDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import Alert                from "Dashboard/Components/Form/Alert";



// Styles
const Content = Styled(DialogMessage).attrs(({ hasError }) => ({ hasError }))`
    --process-height: ${(props) => props.hasError ? "calc(var(--dialog-body) - 33px - 12px)" : "var(--dialog-body)"};
    --input-border-color: var(--border-color-light);
    --input-disabled-color: var(--main-color);

    display: grid;
    gap: var(--main-gap);
    grid-template-columns: 1fr 1fr 230px;
    padding: 0 var(--dialog-padding);
    min-height: var(--process-height);

    grid-template-rows: max-content max-content 1fr max-content;
    grid-template-areas:
        "client client aside"
        "request coverage aside"
        "products products aside"
        "invoice invoice aside";
`;

const Aside = Styled.aside`
    grid-area: aside;
    display: flex;
    flex-direction: column;
    gap: var(--main-gap);
`;

const Error = Styled(Alert)`
    position: sticky;
    top: 0;
    margin: 0 var(--dialog-padding) var(--main-gap);
    z-index: 1;
`;



/**
 * The Process View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProcessView(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const {
        elem, products, states,
        rejectReasons, cancelReasons, coverageTypes,
    } = Store.useState("dashboardProcess");

    const {
        editOrder, editData, editRecipe, editCoverage,
        approveElem, rejectElem, retireElem, retiredElem, cancelElem, nullifyElem,
    } = Store.useAction("dashboardProcess");


    // The References
    const unitPrices = React.useRef([]);
    const prices     = React.useRef([]);
    const subTotals  = React.useRef([]);

    // The Current State
    const [ dialog,          setDialog          ] = React.useState("");
    const [ reqCoverageFile, setReqCoverageFile ] = React.useState(false);
    const [ coverageFile,    setCoverageFile    ] = React.useState("");
    const [ edited,          setEdited          ] = React.useState(false);

    // The Initial Data
    const initialData = {
        orderID                  : 0,
        coverageTypeID           : 0,
        coverageID               : 0,
        clientCoverageName       : "",
        clientCoveragePlan       : "",
        clientCoverageDni        : "",
        clientCoverageCredential : "",
        products                 : "[{}]",
        ticketNumber             : "",
        invoiceNumber            : "",
    };

    // Reset the Edited
    React.useEffect(() => {
        setEdited(false);
    }, [ open ]);

    // Handles the Edit
    const handleElem = (elem) => {
        if (!elem) {
            return;
        }

        Commons.initializeOrderTotals(products, unitPrices, prices, subTotals);
        setReqCoverageFile(elem.reqCoverage && !elem.clientCoverageID);
        setCoverageFile("");

        setElem({
            orderID                  : elem.orderID,
            coverageTypeID           : elem.coverageTypeID,
            coverageID               : elem.coverageID,
            clientCoverageName       : elem.clientCoverageName,
            clientCoveragePlan       : elem.clientCoveragePlan,
            clientCoverageDni        : elem.clientCoverageDni,
            clientCoverageCredential : elem.clientCoverageCredential,
            discountType             : elem.discountType,
            discountValue            : elem.discountValue,
            ticketNumber             : elem.ticketNumber,
            invoiceNumber            : elem.invoiceNumber,
            products                 : Commons.encodeOrderProducts(products),
        });
    };

    // Handles the Change
    const handleOnChange = (name, value) => {
        if (name === "coverageTypeID") {
            const oldReqCoverage = Utils.getValue(coverageTypes, "key", data.coverageTypeID, "reqCoverage", false);
            const newReqCoverage = Utils.getValue(coverageTypes, "key", value, "reqCoverage", false);
            if (newReqCoverage && !oldReqCoverage) {
                setReqCoverageFile(true);
            } else {
                setReqCoverageFile(false);
            }
        }

        handleChange(name, value);
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        const { discount } = Commons.calcOrderTotals(data.products, unitPrices);
        data.discountTotal = discount;
        data.coverageFile  = coverageFile;

        if (!elem.canEditOrder) {
            return editData(data);
        }

        let result;
        try {
            result = await editOrder(data);
        } catch (errors) {
            setErrors(errors);
            return result;
        }
        setEdited(true);
        return result;
    };

    // Handles the Submit Click
    const handleOnSubmit = async () => {
        if (elem.canApprove) {
            const { discount : discountTotal } = Commons.calcOrderTotals(data.products, unitPrices);
            try {
                const result = await editOrder({
                    ...data,
                    discountTotal,
                    coverageFile,
                    forApprove : 1,
                });
                if (result.elem) {
                    resetErrors();
                    if (result.logisticCode && result.elem.requiresRecipe) {
                        setDialog("approveDelivery");
                    } else {
                        setDialog("approve");
                    }
                }
            } catch (errors) {
                setErrors(errors);
            }
        } else if (elem.canRetire) {
            setDialog("retire");
        } else if (elem.canRetired) {
            setDialog("retired");
        }
    };

    // Handles the Cancel Click
    const handleOnCancel = () => {
        if (elem.canReject) {
            setDialog("reject");
        } else if (elem.canCancel) {
            setDialog("cancel");
        } else if (elem.canNullify) {
            setDialog("nullify");
        } else {
            onClose();
        }
    };

    // Handles the Chat Action
    const handleChatAction = (type, index, file) => {
        if (type === "recipe") {
            editRecipe(elem.orderID, index, file);
        } else if (reqCoverageFile) {
            setCoverageFile(file);
        } else {
            editCoverage(elem.orderID, file);
        }
    };

    // Handles the Approve
    const handleApprove = async (disableLogistic) => {
        const response = await approveElem({
            ...data,
            disableLogistic : disableLogistic === "disable" ? 1 : 0,
        });
        if (response.logisticErrorReason) {
            response.message =  NLS.get(response.message);
            response.message += NLS.format("DASHBOARD_PROCESS_SUCCESS_APPROVE_ERROR", response.logisticErrorReason);
        }
        handleResponse(response);
    };

    // Handles the Retire
    const handleRetire = async () => {
        startLoading();
        const response = await retireElem(data);
        handleResponse(response);
    };

    // Handles the Retired
    const handleRetired = async () => {
        startLoading();
        const response = await retiredElem(data);
        handleResponse(response);
    };

    // Handles the Reject
    const handleReject = async (rejectReasonID, reason) => {
        startLoading();
        const response = await rejectElem({
            ...data, rejectReasonID, reason,
        });
        handleResponse(response);
    };

    // Handles the Cancel
    const handleCancel = async (cancelReasonID, reason) => {
        startLoading();
        const response = await cancelElem({
            ...data, cancelReasonID, reason,
        });
        handleResponse(response);
    };

    // Handles the Nullify
    const handleNullify = async () => {
        startLoading();
        const response = await nullifyElem(data);
        handleResponse(response);
    };


    // Handles the Response
    const handleResponse = (response) => {
        if (response.message) {
            onSubmit(response.message);
        } else if (response.success) {
            onClose(true);
        }
        setDialog("");
        endLoading();
    };

    // Handle the Print
    const handlePrint = () => {
        elem.ticketNumber  = data.ticketNumber;
        elem.invoiceNumber = data.invoiceNumber;
        Commons.printOrder(elem, products, states);
    };

    // Handle the Close
    const handleClose = () => {
        onClose(edited);
    };


    // The Form State
    const {
        loading : updating, data, errors, setElem, setErrors, resetErrors,
        startLoading, endLoading, handleChange,
    } = useForm("order", initialData, handleEdit, handleResponse, false);

    // Load the Data
    const { loading } = useDialog("dashboardProcess", open, elemID, null, handleElem);


    // Returns the error
    const error = React.useMemo(() => {
        if (errors.form) {
            return errors.form;
        }
        if (elem.canNullify) {
            return "DASHBOARD_PROCESS_NULLIFY_ERROR";
        }
        return "";
    }, [ errors.form, elem.canNullify ]);


    // Variables
    const { subTotal, discount } = Commons.calcOrderTotals(data.products, unitPrices);


    // Do the Render
    return <>
        <Dialog
            open={open}
            onClose={handleClose}
            width={900}
            isLoading={loading}
            aside={<ProcessChat
                reqCoverageFile={reqCoverageFile}
                onAction={handleChatAction}
            />}
        >
            <ProcessHeader onClose={handleClose} onPrint={handlePrint} />
            <DialogBody withSpacing={false} fullHeight>
                <Error variant="error" message={error} noClose />
                <Content hasError={!!error}>
                    <ProcessClient />
                    <ProcessRequest />

                    <ProcessCoverage
                        data={data}
                        errors={errors}
                        coverageFile={coverageFile}
                        onChange={handleOnChange}
                    />

                    <ProcessProducts
                        unitPrices={unitPrices}
                        prices={prices}
                        subTotals={subTotals}
                        data={data}
                        errors={errors}
                        onChange={handleOnChange}
                    />
                    <ProcessInvoice
                        data={data}
                        errors={errors}
                        onChange={handleOnChange}
                    />

                    <Aside>
                        <ProcessOrder />
                        <ProcessStates />
                        <ProcessTotals
                            subTotal={subTotal}
                            discount={discount}
                        />
                    </Aside>
                </Content>
            </DialogBody>
            <ProcessFooter
                isLoading={loading}
                onSubmit={handleOnSubmit}
                onCancel={handleOnCancel}
                onSecondary={() => handleEdit(data)}
            />
        </Dialog>

        <PromptDialog
            open={dialog === "approveDelivery"}
            icon="check"
            title="DASHBOARD_PROCESS_APPROVE_TITLE"
            message="DASHBOARD_PROCESS_APPROVE_DELIVERY_TEXT"
            primary="DASHBOARD_PROCESS_APPROVE"
            initialValue="none"
            inputType="select"
            inputOptions="SELECT_RETIREMENT_TYPES"
            inputWithNone={false}
            onSubmit={handleApprove}
            onClose={() => setDialog("")}
            isLoading={updating}
            isOptional
        />
        <ConfirmDialog
            open={dialog === "approve"}
            icon="check"
            title="DASHBOARD_PROCESS_APPROVE_TITLE"
            message="DASHBOARD_PROCESS_APPROVE_TEXT"
            primary="DASHBOARD_PROCESS_APPROVE"
            onSubmit={handleApprove}
            onClose={() => setDialog("")}
            isLoading={updating}
        />
        <ConfirmDialog
            open={dialog === "retire"}
            icon="check"
            title="DASHBOARD_PROCESS_RETIRE_TITLE"
            message="DASHBOARD_PROCESS_RETIRE_TEXT"
            onSubmit={handleRetire}
            onClose={() => setDialog("")}
            isLoading={updating}
        />
        <ConfirmDialog
            open={dialog === "retired"}
            icon="check"
            title="DASHBOARD_PROCESS_RETIRED_TITLE"
            message="DASHBOARD_PROCESS_RETIRED_TEXT"
            onSubmit={handleRetired}
            onClose={() => setDialog("")}
            isLoading={updating}
        />

        <PromptDialog
            open={dialog === "reject"}
            icon="cancel"
            title="DASHBOARD_PROCESS_REJECT_TITLE"
            message="DASHBOARD_PROCESS_REJECT_TEXT"
            inputType="select"
            inputLabel="REJECT_REASONS_SINGULAR"
            inputOptions={rejectReasons}
            secInputType="textarea"
            secInputLabel="DASHBOARD_PROCESS_REASON_TEXT"
            secHelperText="DASHBOARD_PROCESS_REASON_CLIENT"
            secMaxLength={255}
            secRequired
            onSubmit={handleReject}
            onClose={() => setDialog("")}
            isLoading={updating}
        />
        <PromptDialog
            open={dialog === "cancel"}
            icon="cancel"
            title="DASHBOARD_PROCESS_CANCEL_TITLE"
            message="DASHBOARD_PROCESS_CANCEL_TEXT"
            inputType="select"
            inputLabel="CANCEL_REASONS_SINGULAR"
            inputOptions={cancelReasons}
            secInputType="textarea"
            secInputLabel="DASHBOARD_PROCESS_REASON_TEXT"
            secHelperText="DASHBOARD_PROCESS_REASON_CLIENT"
            secMaxLength={255}
            secRequired
            onSubmit={handleCancel}
            onClose={() => setDialog("")}
            isLoading={updating}
        />
        <ConfirmDialog
            open={dialog === "nullify"}
            icon="check"
            title="DASHBOARD_PROCESS_NULLIFY_TITLE"
            message="DASHBOARD_PROCESS_NULLIFY_TEXT"
            onSubmit={handleNullify}
            onClose={() => setDialog("")}
            isLoading={updating}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProcessView.propTypes = {
    open     : PropTypes.bool.isRequired,
    elemID   : PropTypes.number,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
};

export default ProcessView;

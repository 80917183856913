import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionOption         from "Dashboard/Components/Header/ActionOption";
import ActionItem           from "Dashboard/Components/Header/ActionItem";



/**
 * The Document Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DocumentHeader(props) {
    const { action, updateAction, onDelete } = props;

    const parent = Navigate.useParent();

    const { list, elem, canCreate, canEdit } = Store.useState("document");


    // Handles the Action
    const handleAction = (action) => {
        if (action.isPrint) {
            const document = Utils.getValue(list, "documentID", elem.documentID);
            Utils.print(document.name, document.content);
        } else {
            updateAction(action, elem.documentID);
        }
    };



    // Do the Render
    const isMobile = Utils.isMobile();
    const goBack   = isMobile && action.isVCE;

    return <Header
        message="DOCUMENTS_NAME"
        icon="document"
        href={goBack ? parent : null}
    >
        <ActionList onAction={handleAction}>
            <ActionItem
                icon="more"
                message="GENERAL_ACTIONS"
            >
                <ActionOption
                    isHidden={!canCreate}
                    message="DOCUMENTS_CREATE_TITLE"
                    action="CREATE"
                />
                <ActionOption
                    isHidden={!elem.documentID || !canEdit}
                    message="DOCUMENTS_EDIT_TITLE"
                    action="EDIT"
                />
                <ActionOption
                    isHidden={!elem.documentID || !canEdit}
                    message="DOCUMENTS_PRINT_TITLE"
                    action="PRINT"
                />
                <ActionOption
                    isHidden={!elem.documentID || !canEdit}
                    message="DOCUMENTS_DELETE_TITLE"
                    action="DELETE"
                    onClick={onDelete}
                />
            </ActionItem>
        </ActionList>
    </Header>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DocumentHeader.propTypes = {
    action       : PropTypes.any.isRequired,
    updateAction : PropTypes.func.isRequired,
    onDelete     : PropTypes.func.isRequired,
};

export default DocumentHeader;

import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Pharmacy Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PharmacyEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { editElem } = Store.useAction("pharmacy");


    // The Initial Data
    const initialData = {
        pharmacyID         : 0,
        name               : "",
        image              : "",
        color              : "",
        hasWebChat         : 0,
        webChatID          : "",
        hideInPharmacyList : 0,
        forTesting         : 0,
        orderTesting       : 0,
    };

    // The Form Pharmacy
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("pharmacy", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("pharmacy", open, elemID, null, setElem);


    // Do the Render
    return <EditDialog
        open={open}
        icon="pharmacy"
        title={elemID ? "PHARMACIES_EDIT_TITLE" : "PHARMACIES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            name="image"
            label="GENERAL_IMAGE_URL"
            value={data.image}
            error={errors.image}
            onChange={handleChange}
        />
        <InputField
            type="color"
            name="color"
            label="GENERAL_COLOR"
            value={data.color}
            error={errors.color}
            onChange={handleChange}
        />

        <InputField
            type="toggle"
            name="hasWebChat"
            label="PHARMACIES_HAS_WEB_CHAT"
            value={data.hasWebChat}
            error={errors.hasWebChat}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!data.hasWebChat}
            name="webChatID"
            label="PHARMACIES_WEB_CHAT_ID"
            helperText="PHARMACIES_WEB_CHAT_ID_HELP"
            value={data.webChatID}
            error={errors.webChatID}
            onChange={handleChange}
        />

        <InputField
            type="toggle"
            name="hideInPharmacyList"
            label="PHARMACIES_HIDE_IN_LIST"
            value={data.hideInPharmacyList}
            error={errors.hideInPharmacyList}
            onChange={handleChange}
            withBorder
        />
        <InputField
            type="toggle"
            name="forTesting"
            label="PHARMACIES_FOR_TESTING"
            value={data.forTesting}
            error={errors.forTesting}
            onChange={handleChange}
            withBorder
        />
        <InputField
            type="toggle"
            name="orderTesting"
            label="PHARMACIES_ORDER_TESTING"
            value={data.orderTesting}
            error={errors.orderTesting}
            onChange={handleChange}
            withBorder
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PharmacyEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default PharmacyEdit;

import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import ProductList          from "Components/App/Catalog/Product/ProductList";
import ProductPage          from "Components/App/Catalog/Product/ProductPage";
import MacroCategoryList    from "Components/App/Catalog/MacroCategory/MacroCategoryList";
import CategoryList         from "Components/App/Catalog/Category/CategoryList";
import SubCategoryList      from "Components/App/Catalog/SubCategory/SubCategoryList";
import SpaceList            from "Components/App/Catalog/Space/SpaceList";
import BannerList           from "Components/App/Catalog/Banner/BannerList";
import MedicineList         from "Components/App/Medicine/Medicine/MedicineList";
import MedicinePage         from "Components/App/Medicine/Medicine/MedicinePage";
import LaboratoryList       from "Components/App/Medicine/Laboratory/LaboratoryList";
import MonoDrugList         from "Components/App/Medicine/MonoDrug/MonoDrugList";
import DrugTypeList         from "Components/App/Medicine/DrugType/DrugTypeList";
import SaleTypeList         from "Components/App/Medicine/SaleType/SaleTypeList";
import HealthTypeList       from "Components/App/Medicine/HealthType/HealthTypeList";
import SizeTypeList         from "Components/App/Medicine/SizeType/SizeTypeList";
import ShapeTypeList        from "Components/App/Medicine/ShapeType/ShapeTypeList";
import PotencyTypeList      from "Components/App/Medicine/PotencyType/PotencyTypeList";
import UnitTypeList         from "Components/App/Medicine/UnitType/UnitTypeList";
import AdminRouteList       from "Components/App/Medicine/AdminRoute/AdminRouteList";
import PharmaActionList     from "Components/App/Medicine/PharmaAction/PharmaActionList";
import ChangeProductList    from "Components/App/Change/ChangeProduct/ChangeProductList";
import ChangeCatalogList    from "Components/App/Change/ChangeCatalog/ChangeCatalogList";
import ChangeUpdateList     from "Components/App/Change/ChangeUpdate/ChangeUpdateList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Catalog Container
 * @returns {React.ReactElement}
 */
function CatalogContainer() {
    const { isCatalog } = Store.useState("auth");


    // Do the Render
    return <>
        <SecondaryNav message="PRODUCTS_NAME">
            <NavigationList>
                <NavigationItem
                    message="PRODUCTS_NAME"
                    url="PRODUCTS"
                    icon="product"
                />
                <NavigationItem
                    message="MACRO_CATEGORIES_NAME"
                    url="MACRO_CATEGORIES"
                    icon="macro-category"
                />
                <NavigationItem
                    message="CATEGORIES_NAME"
                    url="CATEGORIES"
                    icon="category"
                />
                <NavigationItem
                    message="SUB_CATEGORIES_NAME"
                    url="SUB_CATEGORIES"
                    icon="sub-category"
                />
                <NavigationItem
                    isHidden={isCatalog}
                    message="SPACES_NAME"
                    url="SPACES"
                    icon="space"
                />
                <NavigationItem
                    isHidden={isCatalog}
                    message="BANNERS_NAME"
                    url="BANNERS"
                    icon="banner"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="MEDICINES_NAME"
                    url="MEDICINES"
                    icon="medicine"
                />
                <NavigationItem
                    message="LABORATORIES_NAME"
                    url="LABORATORIES"
                    icon="laboratory"
                />
                <NavigationItem
                    message="MONO_DRUGS_NAME"
                    url="MONO_DRUGS"
                    icon="mono-drug"
                />
                <NavigationItem
                    message="DRUG_TYPES_NAME"
                    url="DRUG_TYPES"
                    icon="drug-type"
                />
                <NavigationItem
                    message="SALE_TYPES_NAME"
                    url="SALE_TYPES"
                    icon="sale-type"
                />
                <NavigationItem
                    message="HEALTH_TYPES_NAME"
                    url="HEALTH_TYPES"
                    icon="health-type"
                />
                <NavigationItem
                    message="SIZE_TYPES_NAME"
                    url="SIZE_TYPES"
                    icon="size-type"
                />
                <NavigationItem
                    message="SHAPE_TYPES_NAME"
                    url="SHAPE_TYPES"
                    icon="shape-type"
                />
                <NavigationItem
                    message="POTENCY_TYPES_NAME"
                    url="POTENCY_TYPES"
                    icon="potency-type"
                />
                <NavigationItem
                    message="UNIT_TYPES_NAME"
                    url="UNIT_TYPES"
                    icon="unit-type"
                />
                <NavigationItem
                    message="ADMIN_ROUTES_NAME"
                    url="ADMIN_ROUTES"
                    icon="administration-route"
                />
                <NavigationItem
                    message="PHARMA_ACTIONS_NAME"
                    url="PHARMA_ACTIONS"
                    icon="pharma-action"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="CHANGE_PRODUCTS_NAME"
                    url="CHANGE_PRODUCTS"
                    icon="change-product"
                />
                <NavigationItem
                    message="CHANGE_CATALOGS_NAME"
                    url="CHANGE_CATALOGS"
                    icon="change-catalog"
                />
                <NavigationItem
                    message="CHANGE_UPDATES_NAME"
                    url="CHANGE_UPDATES"
                    icon="change-update"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="ADMIN">
            <AdminRoute url="PRODUCTS"            component={ProductList}       />
            <AdminRoute url="PRODUCT"             component={ProductPage}       />
            <AdminRoute url="MACRO_CATEGORIES"    component={MacroCategoryList} />
            <AdminRoute url="CATEGORIES"          component={CategoryList}      />
            <AdminRoute url="SUB_CATEGORIES"      component={SubCategoryList}   />

            <AdminRoute url="SPACES"              component={SpaceList}         isHidden={isCatalog} />
            <AdminRoute url="BANNERS"             component={BannerList}        isHidden={isCatalog} />

            <AdminRoute url="MEDICINES"           component={MedicineList}      />
            <AdminRoute url="MEDICINE"            component={MedicinePage}      />
            <AdminRoute url="LABORATORIES"        component={LaboratoryList}    />
            <AdminRoute url="MONO_DRUGS"          component={MonoDrugList}      />
            <AdminRoute url="DRUG_TYPES"          component={DrugTypeList}      />
            <AdminRoute url="SALE_TYPES"          component={SaleTypeList}      />
            <AdminRoute url="HEALTH_TYPES"        component={HealthTypeList}    />
            <AdminRoute url="SIZE_TYPES"          component={SizeTypeList}      />
            <AdminRoute url="SHAPE_TYPES"         component={ShapeTypeList}     />
            <AdminRoute url="POTENCY_TYPES"       component={PotencyTypeList}   />
            <AdminRoute url="UNIT_TYPES"          component={UnitTypeList}      />
            <AdminRoute url="ADMIN_ROUTES"        component={AdminRouteList}    />
            <AdminRoute url="PHARMA_ACTIONS"      component={PharmaActionList}  />

            <AdminRoute url="CHANGE_PRODUCTS"     component={ChangeProductList} />
            <AdminRoute url="CHANGE_PRODUCTS_TAB" component={ChangeProductList} />
            <AdminRoute url="CHANGE_PRODUCT"      component={ChangeProductList} />
            <AdminRoute url="CHANGE_CATALOGS"     component={ChangeCatalogList} />
            <AdminRoute url="CHANGE_CATALOGS_TAB" component={ChangeCatalogList} />
            <AdminRoute url="CHANGE_UPDATES"      component={ChangeUpdateList}  />
        </Router>
    </>;
}

export default CatalogContainer;

import React                from "react";
import Action               from "Dashboard/Core/Action";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import { Client }           from "Utils/API";

// Components
import ClientFilter         from "./ClientFilter";
import ClientView           from "./ClientView";
import ClientEdit           from "./ClientEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Client List
 * @returns {React.ReactElement}
 */
function ClientList() {
    const { fetch, load, loadFilter } = useList("client");

    const data = Store.useState("client");
    const { loading, canEdit, list, total, filters, sort } = data;

    const { syncElem, deleteElem } = Store.useAction("client");

    // The Current State
    const [ submitting, setSubmitting ] = React.useState(false);
    const [ exporting,  setExporting  ] = React.useState(false);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isExport) {
            setExporting(true);
            endAction();
            const source = Client.export({ ...filters });
            await Utils.download(source, NLS.get("CLIENTS_FILE_NAME"));
            setExporting(false);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Sync Submit
    const handleSync = async () => {
        setSubmitting(true);
        const result = await syncElem(elemID);
        setSubmitting(false);
        endAction();
        if (result.success) {
            fetch();
        }
    };

    // Handles the Sync Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "clientID", elemID, "fullName");

    return <Main>
        <Header message="CLIENTS_NAME" icon="client">
            <ActionList
                data={data}
                onAction={handleAction}
                isExporting={exporting}
            />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="CLIENTS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="clientID"  message="GENERAL_ID"    maxWidth="50" />
                    <TableHeader field="fullName"  message="GENERAL_NAME"  isTitle />
                    <TableHeader field="dni"       message="CLIENTS_DNI"   maxWidth="100" />
                    <TableHeader field="phone"     message="GENERAL_PHONE" maxWidth="130" />
                    <TableHeader field="email"     message="GENERAL_EMAIL" />
                    <TableHeader field="city"      message="GENERAL_CITY"  />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.clientID} elemID={elem.clientID}>
                        <TableCell message={elem.clientID} />
                        <TableCell message={elem.fullName} />
                        <TableCell message={elem.dni}      />
                        <TableCell message={elem.phone}    />
                        <TableCell message={elem.email}    />
                        <TableCell message={elem.city}     />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW"   message="CLIENTS_VIEW_TITLE"   isHidden={canEdit} />
                    <TableAction action="EDIT"   message="CLIENTS_EDIT_TITLE"   />
                    <TableAction action="SYNC"   message="CLIENTS_SYNC_TITLE"   isHidden={!canEdit} />
                    <TableAction action="DELETE" message="CLIENTS_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <ClientFilter
            open={action.isFilter}
            filters={filters}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <ClientView
            open={action.isView}
            elemID={elemID}
            onClose={endAction}
        />
        <ClientEdit
            open={canEdit && action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <ConfirmDialog
            open={action.isSync}
            isLoading={submitting}
            icon="check"
            title="CLIENTS_SYNC_TITLE"
            message="CLIENTS_SYNC_TEXT"
            content={elemName}
            onSubmit={handleSync}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="CLIENTS_DELETE_TITLE"
            message="CLIENTS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

export default ClientList;

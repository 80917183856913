import React                from "react";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import Html                 from "Dashboard/Components/Common/Html";



/**
 * The Product Subsidiaries
 * @returns {React.ReactElement}
 */
function ProductSubsidiaries() {
    const { subsidiaries } = Store.useState("product");


    // Do the Render
    if (!subsidiaries || !subsidiaries.length) {
        return <React.Fragment />;
    }
    return <Table noSorting notFixed>
        <TableHead>
            <TableHeader field="sku"                message="PRODUCTS_SKU"          isTitle maxWidth="100" />
            <TableHeader field="subsidiaryFullName" message="SUBSIDIARIES_SINGULAR" />
            <TableHeader field="pharmacyName"       message="PHARMACIES_SINGULAR"   />
            <TableHeader field="price"              message="GENERAL_PRICE"         align="right" maxWidth="80" />
        </TableHead>
        <TableBody>
            {subsidiaries.map((elem) => <TableRow key={elem.productSubsidiaryID} elemID={elem.productSubsidiaryID}>
                <TableCell message={elem.sku}                />
                <TableCell message={elem.subsidiaryFullName} />
                <TableCell message={elem.pharmacyName}       />
                <TableCell><Html>{elem.priceText}</Html></TableCell>
            </TableRow>)}
        </TableBody>
    </Table>;
}

export default ProductSubsidiaries;

import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import useForm              from "Dashboard/Hooks/Form";

// Components
import AuthContainer        from "Components/Utils/Auth/AuthContainer";
import AuthHeader           from "Components/Utils/Auth/AuthHeader";
import AuthForm             from "Components/Utils/Auth/AuthForm";

// Dashboard
import IconField            from "Dashboard/Components/Form/IconField";



/**
 * The Recover Pass Page
 * @returns {React.ReactElement}
 */
function RecoverPassPage() {
    const { requestReset } = Store.useAction("auth");

    const navigate = Navigate.useGoto();


    // The Initial Data
    const initialData = {
        email : "",
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        await requestReset(data);
        navigate("PASS_CODE");
    };

    // The Form State
    const {
        loading, data, errors, handleChange, handleSubmit,
    } = useForm("recoverPass", initialData, handleEdit, null, false);



    // Do the Render
    return <AuthContainer>
        <AuthHeader
            title="RECOVER_TITLE"
            help="RECOVER_HELP"
        />
        <AuthForm
            url="LOGIN"
            link="RECOVER_ACCOUNT"
            button="RECOVER_BUTTON"
            error={errors.form}
            onSubmit={handleSubmit}
            isLoading={loading}
        >
            <IconField
                type="email"
                name="email"
                icon="email"
                label="GENERAL_EMAIL"
                autoComplete="username"
                value={data.email}
                error={errors.email}
                onChange={handleChange}
                isRequired
                withLabel
            />
        </AuthForm>
    </AuthContainer>;
}

export default RecoverPassPage;

import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import { MonoDrug }         from "Utils/API";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The Product Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProductEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const {
        benvidaNextID, macroCategories, categoriesMacro, subCategoriesCategory,
        laboratories, saleTypes, shapeTypes, pharmaActions, potencyTypes,
    } = Store.useState("product");

    const { fetchEditData, editElem } = Store.useAction("product");


    // The Initial Data
    const initialData = {
        productID        : 0,
        name             : "",
        benvidaID        : "",
        alfabetaID       : "",
        barCodes         : '[""]',
        image            : "",
        macroCategoryID  : 0,
        categoryID       : 0,
        subCategoryID    : 0,
        laboratoryID     : 0,
        monoDrugID       : 0,
        monoDrugName     : "",
        saleTypeID       : 0,
        shapeTypeID      : 0,
        pharmaActionID   : 0,
        potency          : "",
        potencyTypeID    : 0,
        units            : "",
        prospecto        : "",
        forCeliac        : 0,
        reqRefrigeration : 0,
        reqRecipe        : "none",
        isActive         : 1,
    };

    // Handles the Set
    const handleSet = (elem) => {
        setElem({
            ...elem,
            benvidaID : elem.benvidaID || benvidaNextID,
            barCodes  : elem?.barCodes?.length ? JSON.stringify(elem.barCodes) : '[""]',
        });
    };

    // Handles the Category
    const handleCategory = (name, value) => {
        if (name === "macroCategoryID") {
            setData({ macroCategoryID : value, categoryID : 0, subCategoryID : 0 });
        } else if (name === "categoryID") {
            setData({ categoryID : value, subCategoryID : 0 });
        } else {
            handleChange(name, value);
        }
    };

    // The Form State
    const {
        data, errors, setElem, setData, handleChange, handleSubmit,
    } = useForm("product", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("product", open, elemID, null, handleSet, fetchEditData);



    // Do the Render
    const isEdit = Boolean(elemID);

    return <EditDialog
        open={open}
        icon="product"
        title={isEdit ? "PRODUCTS_EDIT_TITLE" : "PRODUCTS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />

        <Columns>
            <InputField
                type="number"
                name="benvidaID"
                label="GENERAL_CODE_BENVIDA"
                value={data.benvidaID}
                error={errors.benvidaID}
                onChange={handleChange}
            />
            <InputField
                type="number"
                name="alfabetaID"
                label="GENERAL_CODE_ALFABETA"
                value={data.alfabetaID}
                error={errors.alfabetaID}
                onChange={handleChange}
            />

            <InputField
                className="columns-double"
                type="list"
                inputType="number"
                name="barCodes"
                label="GENERAL_BAR_CODES"
                addButton="GENERAL_ADD_BAR_CODE"
                value={data.barCodes}
                error={errors.barCodes}
                onChange={handleChange}
            />
            <InputField
                className="columns-double"
                name="image"
                label="GENERAL_IMAGE"
                value={data.image}
                error={errors.image}
                onChange={handleChange}
            />

            <InputField
                type="select"
                name="macroCategoryID"
                label="MACRO_CATEGORIES_SINGULAR"
                options={macroCategories}
                value={data.macroCategoryID}
                error={errors.macroCategoryID}
                onChange={handleCategory}
            />
            <InputField
                type="select"
                name="categoryID"
                label="CATEGORIES_SINGULAR"
                options={data.macroCategoryID ? categoriesMacro[data.macroCategoryID] : []}
                value={data.categoryID}
                error={errors.categoryID}
                onChange={handleCategory}
            />
            <InputField
                type="select"
                name="subCategoryID"
                label="SUB_CATEGORIES_SINGULAR"
                options={data.categoryID ? subCategoriesCategory[data.categoryID] : []}
                value={data.subCategoryID}
                error={errors.subCategoryID}
                onChange={handleChange}
            />

            <InputField
                type="select"
                name="laboratoryID"
                label="LABORATORIES_SINGULAR"
                options={laboratories}
                value={data.laboratoryID}
                error={errors.laboratoryID}
                onChange={handleChange}
            />
            <InputField
                type="suggest"
                name="monoDrugName"
                label="MONO_DRUGS_SINGULAR"
                value={data.monoDrugName}
                error={errors.monoDrugID}
                suggestID="monoDrugID"
                suggestFetch={MonoDrug.search}
                onChange={handleChange}
            />

            <InputField
                type="select"
                name="saleTypeID"
                label="SALE_TYPES_SINGULAR"
                options={saleTypes}
                value={data.saleTypeID}
                error={errors.saleTypeID}
                onChange={handleChange}
            />
            <InputField
                type="select"
                name="shapeTypeID"
                label="SHAPE_TYPES_SINGULAR"
                options={shapeTypes}
                value={data.shapeTypeID}
                error={errors.shapeTypeID}
                onChange={handleChange}
            />
            <InputField
                type="select"
                name="pharmaActionID"
                label="PHARMA_ACTIONS_SINGULAR"
                options={pharmaActions}
                value={data.pharmaActionID}
                error={errors.pharmaActionID}
                onChange={handleChange}
            />

            <InputField
                type="double"
                label="GENERAL_POTENCY"
                error={errors.potency}
                onChange={handleChange}
            >
                <InputItem
                    name="potency"
                    value={data.potency}
                />
                <InputItem
                    type="select"
                    name="potencyTypeID"
                    placeholder="POTENCY_TYPES_SINGULAR"
                    options={potencyTypes}
                    value={data.potencyTypeID}
                />
            </InputField>

            <InputField
                name="units"
                label="PRODUCTS_QUANTITY_PER_CONTAINER"
                value={data.units}
                error={errors.units}
                onChange={handleChange}
            />
            <InputField
                type="number"
                name="prospecto"
                label="GENERAL_PROSPECTO"
                value={data.prospecto}
                error={errors.prospecto}
                onChange={handleChange}
            />

            <InputField
                type="toggle"
                name="forCeliac"
                label="GENERAL_FOR_CELIAC"
                value={data.forCeliac}
                error={errors.forCeliac}
                onChange={handleChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="reqRefrigeration"
                label="GENERAL_REQ_REFRIGERATION"
                value={data.reqRefrigeration}
                error={errors.reqRefrigeration}
                onChange={handleChange}
                withBorder
            />
            <InputField
                type="select"
                name="reqRecipe"
                label="GENERAL_REQ_RECIPE"
                options="SELECT_REQ_RECIPES"
                value={data.reqRecipe}
                error={errors.reqRecipe}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="toggle"
                name="isActive"
                label="GENERAL_IS_ACTIVE"
                value={data.isActive}
                error={errors.isActive}
                onChange={handleChange}
                withBorder
            />
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProductEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default ProductEdit;

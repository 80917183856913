import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";

// Components
import OrderContent         from "Components/App/Order/Order/View/OrderContent";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Header               from "Dashboard/Components/Header/Header";



/**
 * The Transaction Page
 * @returns {React.ReactElement}
 */
function TransactionPage() {
    const { orderID } = Navigate.useParams();
    const parent = Navigate.useParent(1);

    const { elem } = Store.useState("order");
    const { fetchElem } = Store.useAction("order");


    // Load the Data
    React.useEffect(() => {
        fetchElem(orderID);
    }, [ orderID ]);


    // Do the Render
    return <Main>
        <Header
            message={elem.name || "ORDERS_SINGULAR"}
            icon="order"
            href={parent}
        />
        <OrderContent />
    </Main>;
}

export default TransactionPage;

import NLS                  from "Dashboard/Core/NLS";
import DateTime             from "Dashboard/Utils/DateTime";
import Period               from "Dashboard/Utils/Period";
import Utils                from "Dashboard/Utils/Utils";

// Constants
const COLORS = [
    "rgb(59,  199, 181)", "rgb(181, 158, 236)", "rgb(2, 131, 200)",
    "rgb(255, 134, 128)", "rgb(255, 196, 123)",
    "rgb(240, 150,   9)", "rgb(123,  79, 157)", "rgb( 51, 153,  51)",
    "rgb(229,  20,   0)", "rgb( 46, 139, 204)", "rgb(  0, 134,  65)",
    "rgb(255, 196,  13)", "rgb(230, 113, 184)", "rgb( 32,  96, 142)",
    "rgb( 78,   0,  56)", "rgb(184,  27, 108)", "rgb( 86, 156, 227)",
];



/**
 * Tries to play an audio
 * @param {Object} ref
 * @returns {Promise}
 */
async function playAudio(ref) {
    try {
        ref.current.currentTime = 0;
        await ref.current.play();
    } catch (e) {
        console.error(e);
    }
}

/**
 * Tries to stop an audio
 * @param {Object} ref
 * @returns {Promise}
 */
async function stopAudio(ref) {
    try {
        ref.current.currentTime = 0;
        await ref.current.pause();
    } catch (e) {
        console.error(e);
    }
}



/**
 * Parses the Dashboard Filters
 * @param {Object} params
 * @returns {Object}
 */
function parseDashboardFilters(params) {
    const period         = Period.THIS_MONTH;
    const initialFilters = {
        period   : period,
        fromDate : Period.getFromDate(period),
        toDate   : Period.getToDate(period),
    };

    let hasFilters = true;
    let filters    = params;
    if (Utils.isEmpty(params)) {
        hasFilters = false;
        filters    = { ...initialFilters };
    } else if (Utils.areEqual(filters, initialFilters)) {
        hasFilters = false;
    }
    return { hasFilters, filters };
}

/**
 * Adds/Removes items to the old ones
 * @param {String}    idKey
 * @param {Object[]}  oldList
 * @param {Object[]}  newList
 * @param {Number[]=} deletedIDs
 * @returns {Object[]}
 */
function updateList(idKey, oldList, newList, deletedIDs) {
    const result = oldList.slice(0);
    if (newList && newList.length) {
        for (const newElem of newList) {
            let found = false;
            for (const [ index, oldElem ] of result.entries()) {
                if (newElem[idKey] === oldElem[idKey]) {
                    result[index] = newElem;
                    found = true;
                    break;
                }
            }
            if (!found) {
                result.push(newElem);
            }
        }
    }

    if (deletedIDs && deletedIDs.length) {
        for (const deletedID of deletedIDs) {
            let removeIndex = -1;
            for (const [ index, oldElem ] of result.entries()) {
                if (deletedID === oldElem[idKey]) {
                    removeIndex = index;
                    break;
                }
            }
            if (removeIndex > -1) {
                result.splice(removeIndex, 1);
                break;
            }
        }
    }
    return result;
}

/**
 * Parses the Messages
 * @param {Object[]} messages
 * @param {Object[]} actions
 * @returns {Object[]}
 */
function parseMessages(messages, actions) {
    const list   = [ ...messages, ...actions ].sort((a, b) => a.createdTime - b.createdTime);
    const result = [];

    let lastDay   = "";
    let lastType  = "";
    let lastOwner = "";
    let lastID    = 0;
    let index     = -1;

    for (const item of list) {
        const { createdTime, createdUser, clientID } = item;
        const isMine    = !!createdUser;
        const thisDay   = new Date(createdTime * 1000).toDateString();
        const thisType  = item.orderMessageID ? "message" : "action";
        const thisOwner = isMine ? "mine" : "yours";
        const thisID    = createdUser ? createdUser : clientID;
        const userName  = createdUser ? item.createdUserName : item.clientName;
        const isNewDay  = thisDay !== lastDay;
        const isNewUser = thisType !== lastType || thisOwner !== lastOwner || thisID !== lastID;

        if (isNewDay || isNewUser) {
            index       += 1;
            result[index] = {
                isMine, userName,
                dayName  : isNewDay ? DateTime.formatDay(createdTime) : "",
                messages : [],
                actions  : [],
            };
        }

        if (item.orderMessageID) {
            result[index].messages.push(item);
        } else if (item.orderActionID) {
            result[index].actions.push(item);
        }

        lastDay   = thisDay;
        lastType  = thisType;
        lastOwner = thisOwner;
        lastID    = thisID;
    }
    return result;
}

/**
 * Returns a text for the given Action
 * @param {Object} action
 * @returns {String}
 */
function getActionText(action) {
    const string = NLS.get("SELECT_ORDER_ACTIONS", action.actionType);
    return NLS.format(
        string,
        action.createdUserName,
        action.paramName,
        DateTime.formatDate(action.createdTime, "time"),
    );
}



/**
 * Formats a Number
 * @param {Number}   value
 * @param {Boolean=} isPrice
 * @param {Boolean=} isPercent
 * @returns {String}
 */
function formatNumber(value, isPrice = false, isPercent = false) {
    const formatted = Utils.formatNumber(value);
    return `${isPrice ? "$" : ""} ${formatted}${isPercent ? "%" : ""}`;
}

/**
 * Formats a Chart Label
 * @param {Object} context
 * @returns {String}
 */
function formatChartLabel(context) {
    const { label, prefix, suffix } = context.dataset;
    return `${label}: ${prefix || ""}${Utils.formatNumber(Number(context.raw))}${suffix || ""}`;
}

/**
 * Returns a Color for the given Number
 * @param {Number}  number
 * @param {String=} defaultColor
 * @returns {String}
 */
function getColor(number, defaultColor = "") {
    if (defaultColor && defaultColor !== "#ffffff") {
        return defaultColor;
    }
    const index = number % COLORS.length;
    return COLORS[index];
}

/**
 * Returns a list of Colors for the Chart
 * @param {Object[]} list
 * @param {Number=}  amount
 * @returns {String[]}
 */
function getColorList(list, amount = 0) {
    const result = [];
    for (const [ index, elem ] of list.entries()) {
        result.push(getColor(index, elem.color));
        if (amount > 0 && result.length >= amount) {
            break;
        }
    }
    if (list.length > result.length) {
        result.push("rgb(220, 220, 220)");
    }
    return result;
}

/**
 * Returns a list of Labels for the Chart
 * @param {Object[]} list
 * @param {String=}  noneMessage
 * @param {Number=}  maxLength
 * @param {Number=}  amount
 * @returns {String[]}
 */
function getLabelList(list, noneMessage = "", maxLength = 0, amount = 0) {
    const result = [];
    for (const elem of list) {
        let name = String(elem.name || NLS.get(noneMessage));
        if (maxLength && name.length > maxLength) {
            name = name.substring(0, maxLength) + "...";
        }
        result.push(name);
        if (amount > 0 && result.length >= amount) {
            break;
        }
    }
    if (list.length > result.length) {
        result.push(NLS.get("GENERAL_OTHERS"));
    }
    return result;
}

/**
 * Returns a list of Values for the Chart
 * @param {Object[]} list
 * @param {String}   field
 * @param {Number=}  amount
 * @returns {String[]}
 */
function getValueList(list, field, amount = 0) {
    const result = [];
    let   others = 0;
    for (const elem of list) {
        if (amount > 0 && result.length >= amount) {
            others += elem[field];
        } else {
            result.push(elem[field]);
        }
    }
    if (list.length > result.length) {
        result.push(others);
    }
    return result;
}

/**
 * Creates a Date Scale
 * @param {Object[]} holidays
 * @param {Boolean}  isDaily
 * @param {Boolean}  isStacked
 * @returns {Object}
 */
function getDateScale(holidays, isDaily = false, isStacked = false) {
    return {
        stacked : isStacked,
        ticks   : {
            callback(value) {
                const label = this.getLabelForValue(Number(value));
                if (!isDaily) {
                    return label;
                }

                const { date, text } = crateDateFromLabel(label);
                if (isHoliday(holidays, date.day)) {
                    return `F ${text}`;
                }
                if (date.isWeekend) {
                    return `${date.getDayName(1)} ${text}`;
                }
                return text;
            },
            color(context) {
                if (!isDaily) {
                    return "black";
                }

                const label     = String(context.tick.label);
                const isWeekend = label.startsWith("S") || label.startsWith("D");
                const isHoliday = label.startsWith("F");
                if (isHoliday) {
                    return "green";
                }
                if (isWeekend) {
                    return "red";
                }
                return "black";
            },
        },
    };
}

/**
 * Returns the options for a Scale
 * @param {Boolean=} isPrice
 * @param {Boolean=} isPercent
 * @param {Boolean=} isStacked
 * @returns {Object}
 */
function getScaleOptions(isPrice = false, isPercent = false, isStacked = false) {
    return {
        stacked : isStacked,
        min     : 0,
        ticks   : {
            precision : 0,
            callback(label) {
                return formatNumber(Number(label), isPrice, isPercent);
            },
        },
    };
}

/**
 * Returns the Chart Legend options
 * @param {String} position
 * @returns {Object}
 */
function getLegendOptions(position) {
    return {
        position : position,
        labels   : {
            usePointStyle : true,
        },
    };
}

/**
 * Returns the Chart Tooltip options
 * @param {Boolean=} isPrice
 * @param {Object=}  callbacks
 * @param {Object=}  options
 * @returns {Object}
 */
function getTooltipOptions(isPrice, callbacks = {}, options = {}) {
    return {
        cornerRadius  : 10,
        padding       : 8,
        boxPadding    : 4,
        usePointStyle : true,
        callbacks     : {
            label(context) {
                if (context.dataset.label) {
                    return formatChartLabel(context);
                }
                return formatNumber(Number(context.raw), isPrice);
            },
            ...callbacks,
        },
        ...options,
    };
}



/**
 * Creates a Date and Text from the Label
 * @param {Object} label
 * @returns {Object}
 */
function crateDateFromLabel(label) {
    const [ day, month, year ] = String(label).split("-");
    const date     = DateTime.create().changeYear(Number(year), Number(month), Number(day));
    const yearText = Number(year) !== DateTime.create().year ? ` ${year}` : "";
    const text     = `${date.day} ${date.getMonthName(3)}${yearText}`;
    return { date, text };
}

/**
 * Returns true if the given day is a holiday
 * @param {Object[]} holidays
 * @param {Number}   day
 * @returns {Boolean}
 */
function isHoliday(holidays, day) {
    return holidays.findIndex((elem) => elem.day === day) > -1;
}

/**
 * Returns the Tooltip
 * @param {Object} tooltips
 * @param {String} tooltipCode
 * @returns {String}
 */
function getTooltip(tooltips, tooltipCode) {
    return tooltips?.[tooltipCode]?.content ?? "";
}


/**
 * Parses a single Product
 * @param {Object}    elem
 * @param {Object=}   other
 * @param {String[]=} fields
 * @returns {Object}
 */
function parseProduct(elem, other, fields) {
    elem.createdTimeDate      = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.updatedTimeDate      = DateTime.formatIf(elem.updatedTime, "dashesTime");
    elem.barCodesText         = elem?.barCodes?.length ? elem.barCodes.join(", ") : "";
    elem.priceText            = Utils.formatPrice(elem.price);
    elem.validityDate         = DateTime.formatDate(elem.validity, "dashes");
    elem.potencyText          = `${elem.potency} ${elem.potencyTypeName}`;
    elem.forCeliacText        = Utils.toYesNo(elem.forCeliac);
    elem.reqRefrigerationText = Utils.toYesNo(elem.reqRefrigeration);
    elem.reqRecipeText        = NLS.get("SELECT_REQ_RECIPES", elem.reqRecipe);
    elem.originText           = NLS.get("SELECT_PRODUCT_ORIGINS", elem.origin);
    elem.isActiveText         = Utils.toYesNo(elem.isActive);

    parseDifferences(elem, other, fields);
    return elem;
}

/**
 * Parses a single Medicine
 * @param {Object}    elem
 * @param {Object=}   other
 * @param {String[]=} fields
 * @returns {Object}
 */
function parseMedicine(elem, other, fields) {
    if (!elem.id) {
        return {};
    }

    elem.barCodesText         = elem?.barCodes?.length ? elem.barCodes.join(", ") : "";
    elem.gtinCodesText        = elem.gtinCodes.join(", ");
    elem.priceText            = Utils.formatPrice(elem.price);
    elem.validityDate         = DateTime.formatDate(elem.validity, "dashes");
    elem.validity             = DateTime.formatDate(elem.validity, "dashesReverse");
    elem.potencyText          = `${elem.potency} ${elem.potencyTypeName}`;
    elem.unitsText            = `${elem.units} ${elem.unitTypeName}`;
    elem.pamiPriceText        = Utils.formatPrice(elem.pamiPrice);
    elem.pamiPercentText      = elem.pamiPercent ? `${elem.pamiPercent}%` : "";
    elem.iomaNormatizadoText  = Utils.toYesBlank(elem.iomaNormatizado);
    elem.forSIFARText         = Utils.toYesBlank(elem.forSIFAR);
    elem.forCeliacText        = Utils.toYesNo(elem.forCeliac);
    elem.reqRefrigerationText = Utils.toYesNo(elem.reqRefrigeration);
    elem.hasTaxText           = Utils.toYesNo(elem.hasTax);
    elem.hasGravamenText      = Utils.toYesNo(elem.hasGravamen);
    elem.isImportedText       = Utils.toYesNo(elem.isImported);
    elem.isActiveText         = Utils.toYesNo(elem.isActive);
    elem.toDeleteText         = Utils.toYesNo(elem.toDeleteTime > 0 ? 1 : 0);

    parseDifferences(elem, other, fields);
    return elem;
}

/**
 * Parses the Differences between the Product and the Medicine
 * @param {Object}   product
 * @param {Object}   medicine
 * @param {String[]} fields
 * @returns {Void}
 */
function parseDifferences(product, medicine, fields) {
    if (product && product.id && medicine && medicine.id) {
        for (const field of fields) {
            const className = product[field] !== medicine[field] ? "text-yellow" : "";
            product[`${field}Class`]  = className;
            medicine[`${field}Class`] = className;
        }
    }
}

/**
 * Parses a single Drug
 * @param {Object} elem
 * @returns {Object}
 */
function parseDrug(elem) {
    if (!elem.id) {
        return {};
    }

    elem.potencyText = `${elem.potency} ${elem.potencyTypeName}`;
    return elem;
}

/**
 * Parses the Space IDs
 * @param {Object} elem
 * @param {String} field
 * @returns {String}
 */
function parseSpaceIDs(elem, field) {
    const ids    = Utils.isString(elem[field]) ? elem[field].split(",") : (Utils.isNumeric(elem[field]) ? [ String(elem[field]) ] : elem[field]);
    const result = [];
    for (let i = 0; i < ids.length; i++) {
        if (ids[i]) {
            result.push(String(ids[i]));
        }
    }
    return JSON.stringify(result);
}



/**
 * Encodes the Order Products
 * @param {Object[]} products
 * @returns {String}
 */
function encodeOrderProducts(products) {
    if (!products.length) {
        return JSON.stringify([{
            orderProductID : 0,
            productID      : 0,
            saleTypeID     : 0,
            isNew          : false,
            product        : "",
            name           : "",
            quantity       : 1,
            price          : 0,
            discountType   : "none",
            discountOrigin : "",
            discountValue  : 0,
            discountAmount : 0,
            subTotal       : 0,
            voucherDate    : "",
        }]);
    }

    const result = [];
    for (const elem of products) {
        result.push({
            orderProductID : elem.orderProductID,
            productID      : elem.productID,
            saleTypeID     : elem.saleTypeID,
            isNew          : elem.isNew,
            product        : elem.product,
            name           : `${elem.benvidaID} - ${elem.name}`,
            quantity       : elem.quantity,
            price          : elem.price,
            discountType   : elem.discountType || "none",
            discountOrigin : elem.discountOrigin,
            discountValue  : elem.discountValue,
            discountAmount : elem.discountAmount,
            subTotal       : 0,
            voucherDate    : DateTime.formatIf(elem.voucherTime, "dashesReverse"),
        });
    }
    return JSON.stringify(result);
}

/**
 * Initializes the Order Totals
 * @param {Object[]} products
 * @param {Object}   unitPrices
 * @param {Object}   prices
 * @param {Object}   subTotals
 * @returns {Void}
 */
function initializeOrderTotals(products, unitPrices, prices, subTotals) {
    unitPrices.current = [];
    prices.current     = [];
    subTotals.current  = [];

    for (const [ index, product ] of Object.entries(products)) {
        const { totalPrice, discountPrice } = calcProductTotals(product, product.price);

        unitPrices.current[index] = product.price;
        prices.current[index]     = Utils.round(totalPrice, 2);
        subTotals.current[index]  = Utils.round(totalPrice + discountPrice, 2);
    }
}

/**
 * Calculates the Order Totals
 * @param {String} dataProducts
 * @param {Object} unitPrices
 * @returns {{subTotal: Number, discount: Number}}
 */
function calcOrderTotals(dataProducts, unitPrices) {
    const products = JSON.parse(dataProducts);
    let   subTotal = 0;
    let   discount = 0;

    for (const [ index, product ] of products.entries()) {
        const basePrice = unitPrices.current[index] ?? 0;
        const { totalPrice, discountPrice } = calcProductTotals(product, basePrice);

        subTotal += totalPrice;
        discount += discountPrice;
    }

    return { subTotal, discount };
}

/**
 * Calculates the Product Totals
 * @param {Object} product
 * @param {Number} basePrice
 * @returns {{totalPrice: Number, discountPrice: Number}}
 */
function calcProductTotals(product, basePrice) {
    const quantity   = Math.max(Number(product.quantity ?? 1), 1);
    const price      = Number(product.price ?? 0);
    const unitPrice  = product.isNew ? price : (basePrice ?? price);
    const totalPrice = quantity * unitPrice;

    const discountValue = Number(product.discountValue ?? 0);
    let   discountPrice = 0;
    if (product.discountType === "percent") {
        const percent = Utils.clamp(discountValue / 100, 0, 1);
        discountPrice = totalPrice * -percent;
    } else if (product.discountType === "value") {
        discountPrice = Utils.clamp(discountValue, 0, totalPrice) * -1;
    }

    return { totalPrice, discountPrice };
}

/**
 * Prints the given Order
 * @param {Object}   order
 * @param {Object[]} products
 * @param {Object[]} states
 * @returns {Void}
 */
function printOrder(order, products, states) {
    const printer = window.open("", "PRINT", "height=600,width=1000");
    printer.document.write(`<html><head><title>${order.name}</title>`);
    printer.document.write("<style>");
    printer.document.write("body {margin:0px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:12px;background-color:white;color:rgb(22,25,28);backface-visibility:hidden;overscroll-behavior:none;}");
    printer.document.write("main {max-width:300px;border:2px solid black;padding:10px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:12px;background-color:white;color:rgb(22,25,28);backface-visibility:hidden;overscroll-behavior:none;}");
    printer.document.write("h1,h2,p {margin:0 0 10px 0;}");
    printer.document.write("h1 {font-size:1.5em;}");
    printer.document.write("h2 {font-size:1.2em;}");
    printer.document.write("hr {margin:20px 0;border-top:1px dashed black;}");
    printer.document.write("s {text-decoration:line-through;}");
    printer.document.write("</style>");
    printer.document.write("</head><body><main>");

    printer.document.write("<h1>Benvida</h1>");
    printer.document.write("<p><i>Tus farmacias a un click</i></p>");
    printer.document.write("<hr />");

    printer.document.write(`<h2>${order.name}</h2>`);
    printer.document.write(`<p><b>${NLS.get("GENERAL_CREATED_AT_FEM")}:</b> ${order.createdDate}</p>`);
    printer.document.write(`<p><b>${NLS.get("PAYMENT_TYPES_SINGULAR")}:</b> ${order.gatewayName}</p>`);
    if (order.paymentExternalID) {
        printer.document.write(`<p><b>${NLS.get("ORDERS_PAYMENT_EXTERNAL_ID")}:</b> ${order.paymentExternalID}</p>`);
    }
    printer.document.write(`<p><b>${NLS.get("RETIREMENT_TYPES_SINGULAR")}:</b> ${order.retirementTypeName}</p>`);
    if (order.ticketNumber) {
        printer.document.write(`<p><b>${NLS.get("ORDERS_TICKET_NUMBER")}:</b> ${order.ticketNumber}</p>`);
    }
    if (order.invoiceNumber) {
        printer.document.write(`<p><b>${NLS.get("ORDERS_INVOICE_NUMBER")}:</b> ${order.invoiceNumber}</p>`);
    }
    printer.document.write("<hr />");

    printer.document.write(`<h2>${order.clientName}</h2>`);
    printer.document.write(`<p><b>${NLS.get("CLIENTS_DNI")}:</b> ${order.clientDni}</p>`);
    printer.document.write(`<p><b>${NLS.get("GENERAL_LOCATION")}:</b> ${order.clientLocation}</p>`);
    if (order.clientAddressAdditional) {
        printer.document.write(`<p><b>${NLS.get("GENERAL_ADDRESS_ADDITIONAL")}:</b> ${order.clientAddressAdditional}</p>`);
    }

    printer.document.write(`<p><b>${NLS.get("COVERAGE_TYPES_SINGULAR")}:</b> ${order.coverageTypeName}</p>`);
    if (order.coverageName) {
        printer.document.write(`<p><b>${NLS.get("COVERAGES_NAME")}:</b> ${order.coverageName}</p>`);
    }
    if (order.clientCoverageName) {
        printer.document.write(`<p><b>${NLS.get("ORDERS_COVERAGE_NAME")}:</b> ${order.clientCoverageName}</p>`);
    }
    if (order.clientCoveragePlan) {
        printer.document.write(`<p><b>${NLS.get("ORDERS_COVERAGE_PLAN")}:</b> ${order.clientCoveragePlan}</p>`);
    }
    if (order.clientCoverageDni) {
        printer.document.write(`<p><b>${NLS.get("ORDERS_COVERAGE_DNI")}:</b> ${order.clientCoverageDni}</p>`);
    }
    if (order.clientCoverageCredential) {
        printer.document.write(`<p><b>${NLS.get("ORDERS_COVERAGE_CREDENTIAL")}:</b> ${order.clientCoverageCredential}</p>`);
    }
    printer.document.write("<hr />");

    printer.document.write(`<h2>${order.subsidiaryFullName}</h2>`);
    printer.document.write(`<p><b>${NLS.get("GENERAL_LOCATION")}:</b> ${order.subsidiaryLocation}</p>`);
    printer.document.write(`<p><b>${NLS.get("GENERAL_CELLPHONE")}:</b> ${order.subsidiaryPhone}</p>`);
    printer.document.write("<hr />");

    if (products && products.length) {
        printer.document.write(`<h2>${NLS.get("PRODUCTS_NAME")}</h2>`);
        for (const product of products) {
            if (product.subTotal !== product.total) {
                const percent = Utils.formatPercent(product.discountTotal, product.subTotal, 2, true);
                printer.document.write(`<p>
                    <b>${product.name}</b><br />
                    ${NLS.get("GENERAL_QUANTITY")}: ${product.quantity}<br />
                    ${NLS.get("Precio regular")}: <s>$${Utils.formatNumber(product.subTotal, 2, 0)}</s> ${percent}<br />
                    <b>${NLS.get("DASHBOARD_PROCESS_FINAL_PRICE")}: $${Utils.formatNumber(product.total, 2, 0)}</b>
                </p>`);
            } else {
                printer.document.write(`<p>
                    <b>${product.name}</b><br />
                    ${NLS.get("GENERAL_QUANTITY")}: ${product.quantity}<br />
                    <b>${NLS.get("DASHBOARD_PROCESS_FINAL_PRICE")}: $${Utils.formatNumber(product.total, 2, 0)}</b>
                </p>`);
            }
        }
        printer.document.write("<hr />");
    }

    printer.document.write(`<h2>${NLS.get("STATES_NAME")}</h2>`);
    for (const state of states) {
        printer.document.write(`<p><b>${state.name}:</b> ${state.createdTimeDate}</p>`);
    }
    printer.document.write("<hr />");

    printer.document.write(`<p><b>${NLS.get("GENERAL_SUBTOTAL")}:</b>	 $${Utils.formatNumber(order.grossTotal, 2, 0)}</p>`);
    printer.document.write(`<p><b>${NLS.get("ORDERS_DISCOUNT")}:</b>	 $${Utils.formatNumber(order.discountTotal, 2, 0)}</p>`);
    printer.document.write(`<p><b>${NLS.get("GENERAL_TOTAL")}:</b>	 $${Utils.formatNumber(order.netTotal, 2, 0)}</p>`);

    printer.document.write("</main></body></html>");
    printer.document.write('<script type="text/javascript">window.onload = function() { window.print(); };</script>');
    printer.document.close();
}




// The public API
export default {
    playAudio,
    stopAudio,

    parseDashboardFilters,
    updateList,
    parseMessages,
    getActionText,

    formatNumber,
    formatChartLabel,
    getColor,
    getColorList,
    getLabelList,
    getValueList,
    getDateScale,
    getScaleOptions,
    getLegendOptions,
    getTooltipOptions,

    crateDateFromLabel,
    isHoliday,
    getTooltip,

    parseProduct,
    parseMedicine,
    parseDifferences,
    parseDrug,
    parseSpaceIDs,

    encodeOrderProducts,
    initializeOrderTotals,
    calcOrderTotals,
    printOrder,
};

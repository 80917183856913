import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import MapToggle            from "./Components/MapToggle";
import MapView              from "./Components/MapView";



// Styled
const Filters = Styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--main-gap);
    margin-bottom: var(--main-gap);

    @media (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;



/**
 * The Map Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MapContent(props) {
    const { isHidden } = props;

    const { filters } = Store.useState("dashboardMap");
    const { fetchList } = Store.useAction("dashboardMap");


    // Auto Update
    Utils.useAutoUpdate(fetchList, isHidden, filters);


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <>
        <Filters>
            <MapToggle
                message="SUBSIDIARIES_NAME"
                show="showSubsidiaries"
                radius="subsidiaryRadius"
                step={100}
            />
            <MapToggle
                message="CLIENTS_NAME"
                show="showClients"
                radius="clientRadius"
                step={10}
            />
            <MapToggle
                message="DASHBOARD_RETIREMENTS"
                show="showRetirements"
                radius="retirementRadius"
                step={10}
            />
            <MapToggle
                message="DASHBOARD_DELIVERIES"
                show="showDeliveries"
                radius="deliveryRadius"
                step={10}
            />
        </Filters>

        <MapView />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MapContent.propTypes = {
    isHidden : PropTypes.bool.isRequired,
};

export default MapContent;

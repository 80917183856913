import React                from "react";
import Store                from "Dashboard/Core/Store";

// Dashboard
import BarIcon              from "Dashboard/Components/Core/BarIcon";



/**
 * The Finance Menu
 * @returns {React.ReactElement}
 */
function FinanceMenu() {
    const { closeMenu } = Store.useAction("core");
    const { isFinance } = Store.useState("auth");


    // Do the Render
    if (!isFinance) {
        return <React.Fragment />;
    }
    return <>
        <BarIcon
            url="DASHBOARD"
            icon="home"
            message="DASHBOARD_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            url="BUSINESS"
            icon="subsidiary"
            message="PHARMACIES_NAME"
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            url="ORDERS"
            icon="order"
            message="ORDERS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            url="CATALOG"
            icon="product"
            message="PRODUCTS_NAME"
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            url="CLIENTS"
            icon="client"
            message="CLIENTS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            url="TRANSACTIONS"
            icon="transaction"
            message="TRANSACTIONS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
    </>;
}

export default FinanceMenu;

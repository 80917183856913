import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// The API
import {
    SubsidiaryCoverage,
} from "Utils/API";



// The initial State
const initialState = {
    loading         : true,
    error           : false,
    edition         : 0,
    canCreate       : false,
    canEdit         : false,
    canFilter       : false,
    canImport       : false,
    canExport       : false,
    list            : [],
    total           : 0,
    elem            : {},
    coverages       : [],
    retirementTypes : [],
    gateways        : [],
    statuses        : [],
    sort            : {
        orderBy  : "coveragePosition",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "SUBSIDIARY_COVERAGE_LOADING" });
    },

    /**
     * Fetches the Subsidiary Coverage List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "SUBSIDIARY") {
            params.subsidiaryID = elemID;
        }
        const data = await SubsidiaryCoverage.getAll(params);
        data.sort = params;
        dispatch({ type : "SUBSIDIARY_COVERAGE_LIST", data });
    },

    /**
     * Fetches a single Subsidiary Coverage
     * @param {Function} dispatch
     * @param {Number}   subsidiaryCoverageID
     * @returns {Promise}
     */
    async fetchElem(dispatch, subsidiaryCoverageID) {
        const data = await SubsidiaryCoverage.getOne({ subsidiaryCoverageID });
        dispatch({ type : "SUBSIDIARY_COVERAGE_ELEM", data });
    },

    /**
     * Fetches the Subsidiary Coverage Edit data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchEditData(dispatch) {
        const data = await SubsidiaryCoverage.getEditData();
        dispatch({ type : "SUBSIDIARY_COVERAGE_EDIT", data });
    },

    /**
     * Edits/Creates a Subsidiary Coverage
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return SubsidiaryCoverage.edit(data);
    },

    /**
     * Deletes a Subsidiary Coverage
     * @param {Function} dispatch
     * @param {Number}   subsidiaryCoverageID
     * @returns {Promise}
     */
    deleteElem(dispatch, subsidiaryCoverageID) {
        return SubsidiaryCoverage.delete({ subsidiaryCoverageID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.retirementTypeIDs = elem.retirementTypes.map((retirementType) => retirementType.retirementTypeID);
    elem.gatewayCodes      = elem.gateways.map((gateway) => gateway.gatewayCode);

    if (!elem.retirementTypes.length) {
        let defaultRetirements = NLS.get("GENERAL_ALL");
        if (elem.coverageRetirementTypes.length) {
            defaultRetirements = elem.coverageRetirementTypes.map((retirementType) => retirementType.retirementTypeName).join(", ");
        }
        elem.retirementTypeNames = NLS.format("COVERAGES_DEFAULTS", defaultRetirements);
    } else {
        elem.retirementTypeNames = elem.retirementTypes.map((retirementType) => retirementType.retirementTypeName).join(", ");
    }

    if (!elem.gateways.length) {
        let defaultGateways = NLS.get("GENERAL_ALL_FEM");
        if (elem.coverageGateways.length) {
            defaultGateways = elem.coverageGateways.map((retirementType) => retirementType.retirementTypeName).join(", ");
        }
        elem.gatewayNames = NLS.format("COVERAGES_DEFAULTS", defaultGateways);
    } else {
        elem.gatewayNames = elem.gateways.map((gateway) => gateway.gatewayName).join(", ");
    }
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "SUBSIDIARY_COVERAGE_LIST", "SUBSIDIARY_COVERAGE_ELEM", "SUBSIDIARY_COVERAGE_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "SUBSIDIARY_COVERAGE_LOADING":
        return {
            ...state,
            loading         : true,
        };

    case "SUBSIDIARY_COVERAGE_LIST":
        return {
            ...state,
            loading         : false,
            error           : false,
            canCreate       : action.data.canCreate,
            canEdit         : action.data.canEdit,
            canFilter       : action.data.canFilter,
            canImport       : action.data.canImport,
            canExport       : action.data.canExport,
            list            : Utils.parseList(action.data.list, parseElem),
            total           : action.data.total,
            sort            : action.data.sort,
        };

    case "SUBSIDIARY_COVERAGE_ELEM":
        return {
            ...state,
            error           : false,
            edition         : state.edition + 1,
            elem            : parseElem(action.data.elem),
            coverages       : action.data.coverages,
            retirementTypes : action.data.retirementTypes,
            gateways        : action.data.gateways,
            statuses        : action.data.statuses,
        };

    case "SUBSIDIARY_COVERAGE_EDIT":
        return {
            ...state,
            error           : false,
            edition         : state.edition + 1,
            coverages       : action.data.coverages,
            retirementTypes : action.data.retirementTypes,
            gateways        : action.data.gateways,
            statuses        : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);

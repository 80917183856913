import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The User Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function UserEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { pharmacies, subsidiariesPharmacies, accesses, statuses } = Store.useState("user");
    const { editElem } = Store.useAction("user");


    // The Initial Data
    const initialData = {
        userID        : 0,
        credentialID  : 0,
        access        : "",
        pharmacyID    : 0,
        subsidiaryID  : 0,
        subsidiaryIDs : [],
        firstName     : "",
        lastName      : "",
        email         : "",
        password      : "",
        reqPassChange : 0,
        welcomeEmail  : 0,
        observations  : "",
        status        : "Active",
    };

    // Handles the Set
    const handleSet = (elem) => {
        setElem({ ...elem, subsidiaryIDs : elem.subsidiaryIDsParts });
    };

    // The Form User
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("user", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("user", open, elemID, null, handleSet);


    // Variables
    const isEdit           = Boolean(elemID);
    const singleSubsidiary = data.access === "Operative";
    const multiSubsidiary  = data.access === "SuperOperative" || data.access === "Supervisor";


    // Do the Render
    return <EditDialog
        open={open}
        icon="user"
        title={isEdit ? "USERS_EDIT_TITLE" : "USERS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="select"
            name="access"
            label="GENERAL_ACCESS"
            options={accesses}
            value={data.access}
            error={errors.access}
            onChange={handleChange}
            isRequired
        />

        <InputField
            type="select"
            name="pharmacyID"
            label="PHARMACIES_SINGULAR"
            options={pharmacies}
            value={data.pharmacyID}
            error={errors.pharmacyID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!singleSubsidiary}
            type="select"
            name="subsidiaryID"
            label="SUBSIDIARIES_SINGULAR"
            options={data.pharmacyID ? subsidiariesPharmacies[data.pharmacyID] : []}
            value={data.subsidiaryID}
            error={errors.subsidiaryID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!multiSubsidiary}
            type="chooser"
            name="subsidiaryIDs"
            label="SUBSIDIARIES_SINGULAR"
            options={data.pharmacyID ? subsidiariesPharmacies[data.pharmacyID] : []}
            value={data.subsidiaryIDs}
            error={errors.subsidiaryIDs}
            onChange={handleChange}
        />

        <Columns>
            <InputField
                name="firstName"
                label="GENERAL_FIRST_NAME"
                value={data.firstName}
                error={errors.firstName}
                onChange={handleChange}
                isRequired
            />
            <InputField
                name="lastName"
                label="GENERAL_LAST_NAME"
                value={data.lastName}
                error={errors.lastName}
                onChange={handleChange}
                isRequired
            />

            <InputField
                type="email"
                name="email"
                label="GENERAL_EMAIL"
                value={data.email}
                error={errors.email}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="password"
                name="password"
                label="GENERAL_PASSWORD"
                value={data.password}
                error={errors.password}
                onChange={handleChange}
                autoComplete="new-password"
                generateCode
            />

            <InputField
                type="toggle"
                name="reqPassChange"
                label="GENERAL_REQ_PASS_CHANGE"
                value={!!data.reqPassChange}
                onChange={handleChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="welcomeEmail"
                label="USERS_WELCOME_EMAIL"
                value={!!data.welcomeEmail}
                onChange={handleChange}
                withBorder
            />
        </Columns>

        <InputField
            type="textarea"
            name="observations"
            label="GENERAL_OBSERVATIONS"
            value={data.observations}
            onChange={handleChange}
        />
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
UserEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default UserEdit;

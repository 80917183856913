import React                from "react";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Styled               from "styled-components";

// Components
import DashboardTotals      from "../../Utils/Components/DashboardTotals";



// Styles
const Total = Styled.li`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--main-gap);
    padding: var(--main-gap);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
    min-width: 210px;
`;

const Title = Styled.h3`
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
`;

const Stat = Styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--main-gap);

    span {
        font-size: 18px;
    }
`;



/**
 * The Operations Totals
 * @returns {React.ReactElement}
 */
function OperationsTotals() {
    const { isSupervisor } = Store.useState("auth");
    const { stateTimes } = Store.useState("dashboardOperations");


    // Do the Render
    return <DashboardTotals>
        {stateTimes.map((elem) => <Total key={elem.key}>
            <Title>{NLS.get(elem.name)}</Title>
            <Stat>
                {NLS.get("SUBSIDIARIES_SINGULAR")}
                <span>{elem.subsidiary}</span>
            </Stat>
            {(!isSupervisor && elem.user) && <Stat>
                {NLS.get("USERS_SINGULAR")}
                <span>{elem.user}</span>
            </Stat>}
        </Total>)}
    </DashboardTotals>;
}

export default OperationsTotals;

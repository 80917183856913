import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Subsidiary Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SubsidiaryFilter(props) {
    const { open, filters, pharmacyID, onClose, onSubmit } = props;

    const { pharmacies, subsidiaries, subsidiariesPerPharmacy } = Store.useState("subsidiary");


    // The Initial Data
    const initialData = {
        exclude       : 0,
        pharmacyIDs   : [],
        subsidiaryIDs : [],
    };

    // The Filter State
    const {
        loading, data, handleChange, handleSubmit,
    } = useFilter("subsidiary", open, initialData, filters, onSubmit);

    // Returns a select of subsidiaries based on the pharmacies
    const pharmacyIDs     = pharmacyID ? [ pharmacyID ] : data.pharmacyIDs;
    const getSubsidiaries = Utils.useSelectList(loading, pharmacyIDs, subsidiaries, subsidiariesPerPharmacy);


    // Do the Render
    return <FilterDialog
        open={open}
        title="SUBSIDIARIES_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="toggle"
            name="exclude"
            label="GENERAL_EXCLUDE_FILTER"
            value={data.exclude}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!!pharmacyID}
            type="chooser"
            name="pharmacyIDs"
            label="PHARMACIES_NAME"
            options={pharmacies}
            value={data.pharmacyIDs}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="subsidiaryIDs"
            label="SUBSIDIARIES_NAME"
            options={getSubsidiaries()}
            value={data.subsidiaryIDs}
            onChange={handleChange}
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SubsidiaryFilter.propTypes = {
    open       : PropTypes.bool.isRequired,
    onClose    : PropTypes.func.isRequired,
    onSubmit   : PropTypes.func.isRequired,
    filters    : PropTypes.object,
    pharmacyID : PropTypes.number,
};

export default SubsidiaryFilter;

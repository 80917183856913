import React                                from "react";
import { createRoot }                       from "react-dom/client";
import * as ServiceWorker                   from "serviceWorker";
import { pdfjs }                            from "react-pdf";
import { Chart, registerables }             from "chart.js";

// Data
import actions                              from "Utils/Actions";
import params                               from "Utils/Params";
import esStrings                            from "NLS/Strings/es";
import enStrings                            from "NLS/Strings/en";
import esUrls                               from "NLS/Urls/es";
import enUrls                               from "NLS/Urls/en";
import esActions                            from "NLS/Actions/es";
import enActions                            from "NLS/Actions/en";
import store                                from "Stores/RootStores";

// Dashboard
import Dashboard                            from "Dashboard/Dashboard";
import NLS                                  from "Dashboard/Core/NLS";

// Dashboard
import Main                                 from "Components/Core/Main";

// PDF
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";



// Set the NLS Languages
NLS.initLang("es", esStrings, esUrls, esActions);
NLS.initLang("en", enStrings, enUrls, enActions);
NLS.setLang();


// React PDF
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


// Chart JS
Chart.register(...registerables);
Chart.defaults.font.family = "'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif";
Chart.defaults.color       = "rgb(64, 64, 64)";
Chart.defaults.responsive  = true;



// Render the App
const root = createRoot(document.getElementById("root"));
root.render(
    <Dashboard
        store={store}
        actions={actions}
        params={params}
    >
        <Main />
    </Dashboard>
);



// Service Worker
ServiceWorker.register({
    onUpdate(registration) {
        console.log("New version available! Ready to update?");
        if (registration && registration.waiting) {
            registration.waiting.postMessage({ type : "SKIP_WAITING" });
        }
        window.location.reload();
    },
});

import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import useList              from "Dashboard/Hooks/List";

// Components
import LogisticEdit         from "./LogisticEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";



/**
 * The Logistic List
 * @returns {React.ReactElement}
 */
function LogisticList() {
    const { fetch, load } = useList("logistic");

    const data = Store.useState("logistic");
    const { loading, canEdit, list, total, sort } = data;


    // The Current State
    const [ action, elemCode, startAction, endAction ] = Action.useActionCode();


    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };


    // Do the Render
    return <Main>
        <Header message="LOGISTICS_NAME" icon="logistic">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="LOGISTICS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="logisticCode"    message="GENERAL_NAME"          isTitle />
                    <TableHeader field="isActive"        message="GENERAL_IS_ACTIVE_FEM" maxWidth="90" align="center" />
                    <TableHeader field="subsidiaryCount" message="SUBSIDIARIES_NAME"     maxWidth="90" align="center" />
                    <TableHeader field="timeTables"      message="SCHEDULES_SINGULAR"    grow="3" noSorting />
                    <TableHeader field="feeValue"        message="LOGISTICS_FEE"         maxWidth="100" align="center" />
                    <TableHeader field="minKM"           message="LOGISTICS_MIN_KM"      maxWidth="90"  align="center" />
                    <TableHeader field="maxKM"           message="LOGISTICS_MAX_KM"      maxWidth="90"  align="center" />
                    <TableHeader field="position"        message="GENERAL_POSITION"      maxWidth="80"  align="center" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.logisticCode} elemID={elem.logisticCode}>
                        <TableCell message={elem.logisticCode}    />
                        <TableCell message={elem.isActiveText}    />
                        <TableCell message={elem.subsidiaryCount} />
                        <TableCell message={elem.timeTableText}   />
                        <TableCell message={elem.feeValueText}    />
                        <TableCell message={elem.minKMText}       />
                        <TableCell message={elem.maxKMText}       />
                        <TableCell message={elem.position}        />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT" message="LOGISTICS_EDIT_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <LogisticEdit
            open={action.isVCE}
            elemCode={elemCode}
            onSubmit={handleEdit}
            onClose={endAction}
        />
    </Main>;
}

export default LogisticList;

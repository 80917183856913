import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import DashboardCatalogPage from "Components/App/Dashboard/DashboardCatalogPage";
import CatalogContainer     from "Components/App/Catalog/CatalogContainer";
import ProfileContainer     from "Components/App/Profile/ProfileContainer";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";



/**
 * The Catalog Router
 * @returns {React.ReactElement}
 */
function CatalogRouter() {
    const { isCatalog } = Store.useState("auth");


    // Do the Render
    if (!isCatalog) {
        return <React.Fragment />;
    }
    return <Router type="CATALOG">
        <AdminRoute
            url="DASHBOARD"
            component={DashboardCatalogPage}
        />
        <AdminRoute
            url="CATALOG"
            component={CatalogContainer}
        />
        <AdminRoute
            url="PROFILE"
            component={ProfileContainer}
        />
    </Router>;
}

export default CatalogRouter;

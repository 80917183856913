import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import HyperLink            from "Dashboard/Components/Link/HyperLink";
import Image                from "Dashboard/Components/Common/Image";

// Images
import Logo                 from "Styles/Images/Logo.png";



// Styles
const Container = Styled.main`
    position: relative;
    width: 600px;

    @media (max-width: 650px) {
        display: block;
        width: 100%;
        min-height: 0;
        margin: 24px;
    }
`;

const Content = Styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    padding: 40px 80px;
    background-color: white;
    border-radius: var(--border-radius);
    color: rgb(94, 108, 132);
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
    overflow: hidden;

    @media (max-width: 650px) {
        padding: 40px;
    }
    @media (max-width: 450px) {
        padding: 24px;
    }
`;

const Title = Styled.h1`
    margin: 0 0 16px 0;
    a {
        display: block;
    }
`;

const Inside = Styled.section`
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
`;

const Terms = Styled.p`
    margin: 16px 0 -8px 0;
    padding: 0;
    text-align: center;
    font-size: 0.8em;
    color: rgba(21, 28, 41, 0.48);
`;




/**
 * The Auth Container
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AuthContainer(props) {
    const { children } = props;


    // Do the Render
    return <Container>
        <Title>
            <HyperLink variant="none" url="LOGIN">
                <Image
                    source={Logo}
                    message="TITLE"
                    width="160"
                />
            </HyperLink>
        </Title>
        <Content>
            <Inside>
                {children}
            </Inside>
            <Terms>
                {NLS.get("LOGIN_TERMS_PRE")}
                <HyperLink
                    href={process.env.REACT_APP_WEB + "terms-conditions"}
                    target="_blank"
                    message="LOGIN_TERMS_LINK"
                />
                {NLS.get("LOGIN_TERMS_POST")}
            </Terms>
        </Content>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AuthContainer.propTypes = {
    children : PropTypes.any,
};

export default AuthContainer;

import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Transaction Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TransactionFilter(props) {
    const { open, filters, onClose, onSubmit } = props;

    const {
        transactionTypes, paymentTypes, paymentMethods, banks,
        pharmacies, subsidiaries, subsidiariesPerPharmacy,
    } = Store.useState("transaction");


    // The Initial Data
    const initialData = {
        period            : 0,
        fromDate          : "",
        toDate            : "",
        transactionTypeID : "",
        paymentTypeID     : [],
        paymentMethodID   : [],
        bankID            : [],
        exclude           : 0,
        pharmacyID        : [],
        subsidiaryID      : [],
    };

    // The Filter State
    const {
        loading, data, handleChange, handlePeriod, handleSubmit,
    } = useFilter("transaction", open, initialData, filters, onSubmit);

    // Returns a select of subsidiaries based on the pharmacies
    const getSubsidiaries = Utils.useSelectList(loading, data.pharmacyID, subsidiaries, subsidiariesPerPharmacy);


    // Do the Render
    return <FilterDialog
        open={open}
        title="TRANSACTIONS_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        noAutoFocus
    >
        <Columns amount="3">
            <InputField
                type="select"
                name="period"
                label="PERIOD_NAME"
                value={data.period}
                onChange={handlePeriod}
                options={Period.getSelect()}
            />
            <InputField
                type="date"
                name="fromDate"
                label="GENERAL_FROM"
                value={data.fromDate}
                maxValue="2999-12-31"
                onChange={handleChange}
            />
            <InputField
                type="date"
                name="toDate"
                label="GENERAL_TO"
                value={data.toDate}
                maxValue="2999-12-31"
                onChange={handleChange}
            />
        </Columns>

        <InputField
            type="chooser"
            name="transactionTypeID"
            label="TRANSACTION_TYPES_SINGULAR"
            options={transactionTypes}
            value={data.transactionTypeID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="paymentTypeID"
            label="PAYMENT_TYPES_NAME"
            options={paymentTypes}
            value={data.paymentTypeID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="paymentMethodID"
            label="PAYMENT_METHODS_NAME"
            options={paymentMethods}
            value={data.paymentMethodID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="bankID"
            label="BANKS_NAME"
            options={banks}
            value={data.bankID}
            onChange={handleChange}
        />

        <InputField
            type="toggle"
            name="exclude"
            label="GENERAL_EXCLUDE_FILTER"
            value={data.exclude}
            onChange={handleChange}
            withBorder
        />
        <InputField
            type="chooser"
            name="pharmacyID"
            label="PHARMACIES_NAME"
            options={pharmacies}
            value={data.pharmacyID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="subsidiaryID"
            label="SUBSIDIARIES_NAME"
            options={getSubsidiaries()}
            value={data.subsidiaryID}
            onChange={handleChange}
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TransactionFilter.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    filters  : PropTypes.object,
};

export default TransactionFilter;

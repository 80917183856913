import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Medicine Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MedicineFilter(props) {
    const { open, filters, onClose, onSubmit } = props;

    const { laboratories, saleTypes, healthTypes } = Store.useState("medicine");


    // The Initial Data
    const initialData = {
        name         : "",
        monoDrug     : "",
        alfabetaID   : "",
        barCode      : "",
        gtinCode     : "",
        laboratoryID : [],
        saleTypeID   : [],
        healthTypeID : [],
        showInactive : 0,
    };

    // The Filter State
    const {
        loading, data, handleChange, handleSubmit,
    } = useFilter("medicine", open, initialData, filters, onSubmit);


    // Do the Render
    return <FilterDialog
        open={open}
        title="MEDICINES_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns>
            <InputField
                className="columns-double"
                name="name"
                label="GENERAL_NAME"
                value={data.name}
                onChange={handleChange}
            />
            <InputField
                name="monoDrug"
                label="MONO_DRUGS_SINGULAR"
                value={data.monoDrug}
                onChange={handleChange}
            />

            <InputField
                type="number"
                name="alfabetaID"
                label="GENERAL_CODE_ALFABETA"
                value={data.alfabetaID}
                onChange={handleChange}
            />
            <InputField
                type="number"
                name="barCode"
                label="GENERAL_BAR_CODE"
                value={data.barCode}
                onChange={handleChange}
            />
            <InputField
                type="number"
                name="gtinCode"
                label="MEDICINES_GTIN_CODE"
                value={data.gtinCode}
                onChange={handleChange}
            />
        </Columns>

        <InputField
            type="chooser"
            name="laboratoryID"
            label="LABORATORIES_NAME"
            options={laboratories}
            value={data.laboratoryID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="saleTypeID"
            label="SALE_TYPES_NAME"
            options={saleTypes}
            value={data.saleTypeID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="healthTypeID"
            label="HEALTH_TYPES_NAME"
            options={healthTypes}
            value={data.healthTypeID}
            onChange={handleChange}
        />
        <InputField
            type="toggle"
            name="showInactive"
            label="GENERAL_SHOW_INACTIVE"
            value={data.showInactive}
            onChange={handleChange}
            withBorder
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MedicineFilter.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    filters  : PropTypes.object,
};

export default MedicineFilter;

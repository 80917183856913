import React                from "react";
import PropTypes            from "prop-types";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Pharmacy Code Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PharmacyCode(props) {
    const { open, elem, onClose } = props;


    // Variables
    const link   = `${process.env.REACT_APP_BACK}web/tester.php?hash=${elem?.hash}`;
    const url    = `${process.env.REACT_APP_BACK}web/chat.js?${elem?.hash}`;
    const script = `<script async src="${url}" id="benvida"></script>`;


    // Do the Render
    return <ViewDialog
        open={open}
        icon="code"
        title="PHARMACIES_CODE_TITLE"
        onClose={onClose}
        withSpacing
    >
        <ViewField
            label="GENERAL_CODE"
            value={script}
            hasCopy
        />
        <ViewField
            label="PHARMACIES_CODE_TEST"
            value={link}
        />
    </ViewDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PharmacyCode.propTypes = {
    open    : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    elem    : PropTypes.object,
};

export default PharmacyCode;

import React                from "react";
import PropTypes            from "prop-types";
import Commons              from "Utils/Commons";

// Components
import DoughnutChart        from "../../Utils/Charts/DoughnutChart";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import ColorCircle          from "Dashboard/Components/Common/ColorCircle";



/**
 * The Operations Retirements
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function OperationsRetirements(props) {
    const { title, data } = props;


    // Do the Render
    return <DoughnutChart
        title={title}
        field="totalOrders"
        data={data}
    >
        <Table noSorting notFixed>
            <TableHead>
                <TableHeader field="name"        message="RETIREMENT_TYPES_SINGULAR" isFlex isTitle />
                <TableHeader field="totalOrders" message="ORDERS_SALES"              isFlex maxWidth="120" align="center" />
            </TableHead>
            <TableBody>
                {data.map((elem, index) => <TableRow key={elem.name}>
                    <TableCell>
                        <ColorCircle color={Commons.getColor(index)} />
                        {elem.name}
                    </TableCell>
                    <TableCell message={elem.totalOrders} />
                </TableRow>)}
            </TableBody>
        </Table>
    </DoughnutChart>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
OperationsRetirements.propTypes = {
    title : PropTypes.string.isRequired,
    data  : PropTypes.array.isRequired,
};

export default OperationsRetirements;

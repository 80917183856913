import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import UserFilter           from "./UserFilter";
import UserEdit             from "./UserEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The User List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function UserList(props) {
    const { type } = props;
    const { fetch, load, loadFilter } = useList("user", type);

    const { pharmacyID } = Navigate.useParams();

    const data = Store.useState("user");
    const { canEdit, list, total, filters, sort, loading } = data;

    const { deleteElem } = Store.useAction("user");
    const { loginAs } = Store.useAction("auth");


    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Action
    const handleAction = (action, elemID) => {
        if (action.isLogin) {
            const credentialID = Utils.getValue(list, "userID", elemID, "credentialID");
            loginAs(credentialID);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Variables
    const elemName      = Utils.getValue(list, "userID", elemID, "name");
    const forPharmacy   = type === "PHARMACY";
    const forSubsidiary = type === "SUBSIDIARY";


    // Do the Render
    return <Main>
        <Header message="USERS_NAME" icon="user">
            <ActionList data={data} onAction={handleAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="USERS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="fullName"     message="GENERAL_NAME"          isTitle />
                    <TableHeader field="email"        message="GENERAL_EMAIL"         />
                    <TableHeader field="access"       message="GENERAL_ACCESS"        maxWidth="120" />
                    <TableHeader field="pharmacyName" message="PHARMACIES_SINGULAR"   isHidden={forPharmacy || forSubsidiary} />
                    <TableHeader field="subsidiaries" message="SUBSIDIARIES_SINGULAR" />
                    <TableHeader field="status"       message="GENERAL_STATUS"        align="center" maxWidth="100" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.userID} elemID={elem.userID}>
                        <TableCell message={elem.fullName}       />
                        <TableCell message={elem.email}          />
                        <TableCell message={elem.accessName}     />
                        <TableCell message={elem.pharmacyName}   />
                        <TableCell message={elem.subsidiaryText} />
                        <TableCell message={elem.statusName}     textColor={elem.statusColor} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="USERS_EDIT_TITLE"   />
                    <TableAction action="LOGIN"  message="GENERAL_LOGIN_AS"   />
                    <TableAction action="DELETE" message="USERS_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <UserFilter
            open={action.isFilter}
            filters={filters}
            pharmacyID={pharmacyID}
            forSubsidiary={forSubsidiary}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <UserEdit
            open={action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="USERS_DELETE_TITLE"
            message="USERS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
UserList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default UserList;

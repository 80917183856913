import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import SizeTypeFilter       from "./SizeTypeFilter";
import SizeTypeEdit         from "./SizeTypeEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Size Type List
 * @returns {React.ReactElement}
 */
function SizeTypeList() {
    const { fetch, load, loadFilter } = useList("sizeType");

    const data = Store.useState("sizeType");
    const { loading, canEdit, list, total, filters, sort } = data;

    const { deleteElem } = Store.useAction("sizeType");


    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "sizeTypeID", elemID, "name");

    return <Main>
        <Header message="SIZE_TYPES_NAME" icon="size-type">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="SIZE_TYPES_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="alfabetaID"   message="GENERAL_CODE"          maxWidth="80" />
                    <TableHeader field="alfabetaName" message="GENERAL_NAME_ALFABETA" isTitle />
                    <TableHeader field="benvidaName"  message="GENERAL_NAME_BENVIDA"  isTitle />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.sizeTypeID} elemID={elem.sizeTypeID}>
                        <TableCell message={elem.alfabetaID}   />
                        <TableCell message={elem.alfabetaName} />
                        <TableCell message={elem.benvidaName}  />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="SIZE_TYPES_EDIT_TITLE"   />
                    <TableAction action="DELETE" message="SIZE_TYPES_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <SizeTypeFilter
            open={action.isFilter}
            filters={filters}
            onSubmit={handleFilter}
            onClose={startAction}
        />
        <SizeTypeEdit
            open={action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="SIZE_TYPES_DELETE_TITLE"
            message="SIZE_TYPES_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

export default SizeTypeList;

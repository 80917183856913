import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import ViewTable            from "Components/Utils/View/ViewTable";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import Html                 from "Dashboard/Components/Common/Html";



/**
 * The Order Transactions
 * @returns {React.ReactElement}
 */
function OrderTransactions() {
    const { isFinanceAdmin } = Store.useState("auth");
    const { transactions } = Store.useState("order");


    // Do the Render
    if (!isFinanceAdmin || !transactions.length) {
        return <React.Fragment />;
    }
    return <ViewTable message="TRANSACTIONS_NAME">
        <Table noSorting notFixed>
            <TableHead>
                <TableHeader field="settlementTime"      message="GENERAL_CREATED_AT_FEM"     isTitle minWidth="160" />
                <TableHeader field="transactionTypeName" message="TRANSACTION_TYPES_SINGULAR" />
                <TableHeader field="paymentTypeName"     message="PAYMENT_TYPES_SINGULAR"     />
                <TableHeader field="paymentMethodName"   message="PAYMENT_METHODS_SINGULAR"   />
                <TableHeader field="cardType"            message="CARD_TYPES_SINGULAR"        />
                <TableHeader field="bankFantasyName"     message="BANKS_SINGULAR"             />
                <TableHeader field="cardCategory"        message="CATEGORIES_SINGULAR"        />
                <TableHeader field="installments"        message="GENERAL_INSTALLMENTS"       align="center" maxWidth="60" />
                <TableHeader field="commission"          message="ORDERS_COMMISSION"          align="right" maxWidth="100" />
            </TableHead>
            <TableBody>
                {transactions.map((elem) => <TableRow key={elem.transactionID} elemID={elem.transactionID}>
                    <TableCell message={elem.settlementDate}      />
                    <TableCell message={elem.transactionTypeName} />
                    <TableCell message={elem.paymentTypeName}     />
                    <TableCell message={elem.paymentMethodName}   />
                    <TableCell message={elem.cardName}            />
                    <TableCell message={elem.bankName}            />
                    <TableCell message={elem.cardCategory}        />
                    <TableCell message={elem.installments}        />
                    <TableCell><Html>{elem.commissionText}</Html></TableCell>
                </TableRow>)}
            </TableBody>
        </Table>
    </ViewTable>;
}

export default OrderTransactions;

import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Email White List Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function EmailWhiteListEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { editEmail } = Store.useAction("emailWhiteList");


    // The Initial Data
    const initialData = {
        emailID     : 0,
        email       : "",
        description : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("emailWhiteList", initialData, editEmail, onSubmit);

    // Load the Data
    const { loading } = useDialog("emailWhiteList", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="white-email"
        title={elemID ? "EMAIL_WHITE_LIST_EDIT_TITLE" : "EMAIL_WHITE_LIST_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="email"
            label="EMAIL_WHITE_LIST_EMAIL"
            value={data.email}
            error={errors.email}
            onChange={handleChange}
            isRequired
        />
        <InputField
            name="description"
            label="EMAIL_WHITE_LIST_DESCRIPTION"
            value={data.description}
            error={errors.description}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
EmailWhiteListEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default EmailWhiteListEdit;

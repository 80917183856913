import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Commission Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CommissionEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { saleTypes, pharmacies, subsidiariesPharmacies } = Store.useState("commission");
    const { editElem } = Store.useAction("commission");


    // The Initial Data
    const initialData = {
        commissionID : 0,
        saleTypeID   : 0,
        pharmacyID   : 0,
        subsidiaryID : 0,
        withCoverage : 0,
        percent      : "",
        useSubTotal  : "",
        observations : "",
    };

    // The Form Commission
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("commission", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("commission", open, elemID, null, setElem);


    // Do the Render
    const isEdit = Boolean(elemID);

    return <EditDialog
        open={open}
        icon="commission"
        title={isEdit ? "COMMISSIONS_EDIT_TITLE" : "COMMISSIONS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="toggle"
            name="withCoverage"
            label="COMMISSIONS_WITH_COVERAGE"
            value={!!data.withCoverage}
            onChange={handleChange}
            withBorder
        />
        <InputField
            type="select"
            name="saleTypeID"
            label="SALE_TYPES_SINGULAR"
            options={saleTypes}
            value={data.saleTypeID}
            error={errors.saleTypeID}
            onChange={handleChange}
        />
        <InputField
            type="select"
            name="pharmacyID"
            label="PHARMACIES_SINGULAR"
            options={pharmacies}
            value={data.pharmacyID}
            error={errors.pharmacyID}
            onChange={handleChange}
        />
        <InputField
            type="select"
            name="subsidiaryID"
            label="SUBSIDIARIES_SINGULAR"
            options={data.pharmacyID ? subsidiariesPharmacies[data.pharmacyID] : []}
            value={data.subsidiaryID}
            error={errors.subsidiaryID}
            onChange={handleChange}
        />

        <InputField
            type="number"
            name="percent"
            label="GENERAL_PERCENT"
            value={data.percent}
            error={errors.percent}
            onChange={handleChange}
            maxValue={100}
            minValue={0}
            step={0.01}
        />
        <InputField
            type="toggle"
            name="useSubTotal"
            label="COMMISSIONS_USE_SUB_TOTAL"
            value={!!data.useSubTotal}
            onChange={handleChange}
            withBorder
        />

        <InputField
            type="textarea"
            name="observations"
            label="GENERAL_OBSERVATIONS"
            value={data.observations}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CommissionEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default CommissionEdit;

import React                from "react";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Empty = Styled.section`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 40px;
    height: var(--document-height);
`;

const Iconography = Styled(Icon)`
    padding: 0 24px;
    margin-top: -40px;
    font-size: 72px;
    border-radius: calc(2 * var(--border-radius));
    background-color: var(--lighter-gray);
    color: var(--darkest-gray);
    opacity: 0.8;
`;

const Title = Styled.h3`
    font-size: 16px;
    font-weight: normal;
`;



/**
 * The Document Empty
 * @returns {React.ReactElement}
 */
function DocumentEmpty() {

    // Do the Render
    return <Empty>
        <Iconography icon="documents" />
        <Title>{NLS.get("DOCUMENTS_SELECT_ONE")}</Title>
    </Empty>;
}

export default DocumentEmpty;

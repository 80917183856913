import React                from "react";
import Store                from "Dashboard/Core/Store";
import Commons              from "Utils/Commons";

// Components
import PolarAreaChart       from "../../Utils/Charts/PolarAreaChart";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import ColorCircle          from "Dashboard/Components/Common/ColorCircle";



/**
 * The Operations Rejects
 * @returns {React.ReactElement}
 */
function OperationsRejects() {
    const { rejectTotals } = Store.useState("dashboardOperations");


    // Do the Render
    return <PolarAreaChart
        title="DASHBOARD_REJECT_REASON_TITLE"
        field="total"
        data={rejectTotals}
    >
        <Table noSorting notFixed>
            <TableHead>
                <TableHeader field="name"  message="REJECT_REASONS_SINGULAR" isFlex isTitle />
                <TableHeader field="total" message="GENERAL_TOTAL"           isFlex maxWidth="120" align="center" />
            </TableHead>
            <TableBody>
                {rejectTotals.map((elem, index) => <TableRow key={elem.name}>
                    <TableCell>
                        <ColorCircle color={Commons.getColor(index)} />
                        {elem.name}
                    </TableCell>
                    <TableCell message={elem.total} />
                </TableRow>)}
            </TableBody>
        </Table>
    </PolarAreaChart>;
}

export default OperationsRejects;
